import "./DesignTwoCatering.css";
import CorporateEvents from "../img/catering/Corporate-Events.png";
import WeddingCatering from "../img/catering/Wedding-Catering.png";
import PrivateParties from "../img/catering/Private-Parties.png";
import InstitutionalCatering from "../img/catering/Institutional-Catering.png";
export default function DesignTwoCatering() {
  return (
    <div className="why-choose-us">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                Catering
              </h3>
              <h2 className="text-anime">
                Let's make your event extra special...!
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-12">
            <div className="wcu-img-box wow fadeInUp" data-wow-delay="0.50s">
              <div className="image-box">
                <figure>
                  <img src={CorporateEvents} alt="" />
                </figure>
              </div>

              <div className="image-body">
                <h3>Corporate Events</h3>
                <p>
                  Elevate your corporate gatherings with our impeccable catering
                  service.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-12">
            <div className="wcu-img-box wow fadeInUp" data-wow-delay="0.75s">
              <div className="image-box">
                <figure>
                  <img src={WeddingCatering} alt="" />
                </figure>
              </div>

              <div className="image-body">
                <h3>Wedding Catering</h3>
                <p>
                  Toast to a love story made even sweeter with our exquisite
                  wedding catering.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-12">
            <div className="wcu-img-box wow fadeInUp" data-wow-delay="1.0s">
              <div className="image-box">
                <figure>
                  <img src={PrivateParties} alt="" />
                </figure>
              </div>

              <div className="image-body">
                <h3>Private Parties</h3>
                <p>
                  Elevate your private party with our bespoke catering service
                  filled with delicious delights.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <div className="wcu-img-box wow fadeInUp" data-wow-delay="1.0s">
              <div className="image-box">
                <figure>
                  <img src={InstitutionalCatering} alt="" />
                </figure>
              </div>

              <div className="image-body">
                <h3>Institutional Catering</h3>
                <p>
                  Transforming dining into a culinary journey. Our
                  catering brings nourishment & satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
