import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";
import PostApiCall from "../../../components/OnlineOrdering/helper/PostAPI";
import { store } from "../../../pages/context/store";
import ChiliImage1 from "../../../assets/img/ChiliImages/1.svg";
import ChiliImage2 from "../../../assets/img/ChiliImages/2.svg";
import ChiliImage3 from "../../../assets/img/ChiliImages/3.svg";
import ChiliImage4 from "../../../assets/img/ChiliImages/4.svg";
import ChiliImage5 from "../../../assets/img/ChiliImages/5.svg";
import { RadioGroup } from "@mui/material";

function RecipeDetail() {
  const [spicelevelData] = useState([
    {
      label: "Mild",
      value: "1",
      img: ChiliImage1,
    },
    {
      label: "Medium",
      value: "2",
      img: ChiliImage2,
    },
    {
      label: "Medium Hot",
      value: "3",
      img: ChiliImage3,
    },
    {
      label: "Hot",
      value: "4",
      img: ChiliImage4,
    },
    {
      label: "Extra Hot",
      value: "5",
      img: ChiliImage5,
    },
  ]);
  const [checkedData, setCheckedData] = useState("");
  const {
    productDetailBanner,
    productDetailItem,
    productDetailDescription,
    selectedProperties,
    setSelectedProperties,
    addOnList,
    selectedAddons,
    setSelectedAddons,
    setShowDetails,
    itemSpiceLevel,
    selectedMappedProperty,
    setSelectedMappedProperty
  } = useContext(store);
  //add on group
  const { addOnGroup } = useContext(store);
  const [selectedCount, setSelectedCount] = useState(0);
  const { setcuisines, setallcuisines } =
    useContext(store);
  // const [setSelectSpiceModal] = useState(false);
  const { itemPrice, searchText } = useContext(store);
  const [selectedAddonCount, setSelectedAddonCount] = useState(0);
  const { currencyCode } = useContext(store);
  //add property
  const { property } = useContext(store);
  const { propertyList } = useContext(store);
  const { itemId } = useContext(store);
  const [spiceLevel, setSpiceLevel] = useState(null);
  const [selectedAddOnRadio, setSelectedAddOnRadio] = useState(null);
  const [selectedAddonAmount, setSelectedAddonAmount] = useState(null);
  // discount
  const [discountAmount, setdiscountAmount] = useState(null);
  const [addonCount, setAddonCount] = useState(0);
  const [disabledAddToCart, setDisabledAddToCart] = useState(false);
  // total
  const [totalAmount, settotalAmount] = useState(0.0);

  const { restaurantOpen, setCartData } = useContext(store);

  const { setCartTotal } = useContext(store);
  const { setCartItems } = useContext(store);
  const getCart = async () => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = login ? JSON.parse(login)[0] : ""

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails.order_id,
        id:
          localStorage.getItem("LoginDetailsWeb") == null
            ? null
            : logindetails.CustomerId,
        stateid: null,
      },
      "getCartWebsite"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status === 200 || result.status === 201) {
          if (obj.message.length == 0) {
            localStorage.removeItem("OrderData");
          } else {
            setCartData(obj.message);
            setdiscountAmount(obj.message[0].fld_cart_discount_amount);
            settotalAmount(obj.message[0].fld_cart_amount_no_shipping);
            setCartTotal(obj.message[0].fld_total_net_amount?.toFixed(2));
            setCartItems(obj.message[0].item_count);
            setShowDetails(false);
          }
          setDisabledAddToCart(false);
          setCartData(obj.message);

          // getMenuList();
        }
      })
    );
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedCount((prevCount) => prevCount + 1);
    } else {
      setSelectedCount((prevCount) => prevCount - 1);
    }
  };
  const handleAddonCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedAddonCount((prevCount) => prevCount + 1);
    } else {
      setSelectedAddonCount((prevCount) => prevCount - 1);
    }
  };
  // const getMenuList = () => {
  //   var Order = localStorage.getItem("OrderData");
  //   var Orderdetails = JSON.parse(Order);
  //   PostApiCall.postRequest(
  //     {
  //       categoryid: null,
  //       orderid:
  //         localStorage.getItem("OrderData") == null ? 0 : Orderdetails.order_id,
  //     },
  //     "getFoodMenuOrder"
  //   ).then((results) =>
  //     results?.json().then((obj1) => {
  //       if (results.status === 200 || results.status === 201) {
  //         setcuisines(obj1.message);
  //         setallcuisines(obj1.message);
  //         setSelectSpiceModal(false);
  //         Notiflix.Loading.Remove();
  //       }
  //     })
  //   );
  // };
  const getMenuData = (message) => {
    const removeFav = message;
    searchText != null ?
      setcuisines(removeFav.filter((product) =>
        product.search_field
          .toLowerCase()
          .includes(searchText?.toLowerCase())
      )) : setcuisines(removeFav);
    // setcuisines(message);
    setallcuisines(message);
    // setSelectSpiceModal(false);
  }
  return (
    <>
      <section className="offer-banner">
        <Container>
          <Row>
            <Col lg={6} className="mb-3 position-relative">
              <img
                alt=""
                src={productDetailBanner}
                className="img-fluid w-100 rounded"
              />
              {/* <div className="share-item">
                <FaRegShareSquare className="wishlist-share cursor-pointer text-white" />
              </div> */}
            </Col>
            <Col lg={6} className="">
              <div className="overflow-y-auto add-ons-section overflow-y-scroll add-ons-modal">
                <div className="sticky-top bg-white">
                  <h3 className="text-start pb-2">{productDetailItem}</h3>
                  <p>{productDetailDescription}</p>
                </div>
                <div className="pb-6 ps-2 pe-3">
                  {property == null || property.length == 0 || property[0]?.FLD_Max_Properties == 0 ? (
                    ""
                  ) : (
                    <div className="d-flex justify-content-between align-items-center pb-2 mb-3 text-danger">
                      <div>
                        <p className="fw-bold mb-0">Choose your Protein</p>
                        <p className="mb-0">
                          Select any{" "}
                          {property === null
                            ? ""
                            : property[0]?.FLD_Max_Properties}{" "}
                          option
                        </p>
                      </div>
                    </div>
                  )}
                  {property == null || property[0]?.FLD_Max_Properties == 0
                    ? ""
                    : property.map((data, i) => {
                      return (
                        <>
                          <div className="card shadow-sm p-2 mb-3">
                            {/* <div className="d-flex justify-content-between align-items-center border-bottom pb-2">
                              <div>
                                <p className="fw-bold mb-0">
                                  {data.FLD_Property_Name}
                                </p>
                              </div>
                              <div>
                                 <span className="required-label">{data.fld_required}</span>
                                </div>
                            </div> */}
                            <div>
                              {propertyList == null || data.FLD_Max_Properties == 0
                                ? ""
                                : propertyList
                                  .filter(
                                    (item) =>
                                      item.FLD_Property_Name ==
                                      data.FLD_Property_Name
                                  )
                                  .map((propertyList) => {
                                    return (
                                      <div>
                                        <div className="d-flex justify-content-between align-items-center py-2">
                                          <div>
                                            <p className="mb-0">
                                              {
                                                propertyList.FLD_Property_value
                                              }
                                            </p>
                                          </div>
                                          <div>
                                            <span className="me-2">
                                              {currencyCode}{" "}
                                              {Number(
                                                propertyList.FLD_Price
                                              ).toFixed(2)}
                                            </span>

                                            <Form.Check
                                              inline
                                              id={
                                                propertyList.FLD_ID +
                                                propertyList.FLD_Max_Properties
                                              }
                                              type={
                                                data.FLD_Max_Properties > 0
                                                  ? "checkbox"
                                                  : "radio"
                                              }
                                              className="me-0"
                                              disabled={
                                                selectedCount >=
                                                data.FLD_Max_Properties &&
                                                !document.getElementById(
                                                  propertyList.FLD_ID +
                                                  propertyList.FLD_Max_Properties
                                                ).checked
                                              }
                                              value={propertyList.FLD_ID}
                                              onChange={(e) => {
                                                let newProperty =
                                                  selectedProperties;
                                                handleCheckboxChange(e);
                                                if (e.target.checked) {
                                                  newProperty.push(
                                                    e.target.value
                                                  );
                                                  setSelectedProperties(
                                                    newProperty
                                                  );
                                                  setSelectedMappedProperty(propertyList.property_id)

                                                  settotalAmount(
                                                    (
                                                      Number(totalAmount) +
                                                      Number(
                                                        propertyList.FLD_Price
                                                      )
                                                    ).toFixed(2)
                                                  );
                                                } else {
                                                  if (
                                                    newProperty.includes(
                                                      e.target.value
                                                    )
                                                  ) {
                                                    let indexProperty =
                                                      newProperty.indexOf(
                                                        e.target.value
                                                      );
                                                    newProperty.splice(
                                                      indexProperty,
                                                      1
                                                    );
                                                    setSelectedProperties(
                                                      newProperty.splice(
                                                        indexProperty,
                                                        1
                                                      )
                                                    );
                                                    setSelectedAddonCount(0)
                                                    setSelectedAddons([])
                                                    setAddonCount(0)
                                                    if (totalAmount > 0.0) {
                                                      settotalAmount(
                                                        (
                                                          Number(
                                                            totalAmount
                                                          ) -
                                                          Number(
                                                            propertyList.FLD_Price
                                                          )
                                                        ).toFixed(2)
                                                      );
                                                    }
                                                  }
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {addOnGroup == null ? (
                    ""
                  ) : (
                    <div className="d-flex justify-content-between align-items-center pb-2 mb-3 text-danger">
                      <div>
                        <p className="fw-bold mb-0">Choose your Extras</p>
                        {/* <p className="mb-0">
                          Select any{" "}
                          {addOnGroup === null
                            ? ""
                            : addOnGroup[0]?.FLD_Max_Addon}{" "}
                          option
                        </p> */}
                      </div>
                    </div>
                  )}
                  {addOnGroup == null
                    ? ""
                    : addOnGroup.map((data, i) => {
                      return (
                        <>
                          <div className="card shadow-sm p-2 mb-3">
                            <div className="d-flex justify-content-between align-items-center border-bottom pb-2">
                              <div>
                                <p className="fw-bold mb-0">
                                  {data.FLD_Addon_Group}
                                </p>
                              </div>
                              {/* <div>
                          <span className="required-label">required</span>
                        </div> */}
                            </div>
                            <div>

                              {addOnList == null
                                ? ""
                                : addOnList
                                  .filter((item) => {
                                    if ((item.FLD_Property_id == 0 || item.FLD_Property_id == null) && item.FLD_Addon_Group == data.FLD_Addon_Group) {
                                      return item
                                    }
                                    if (item.FLD_Addon_Group == data.FLD_Addon_Group &&
                                      selectedMappedProperty == item.FLD_Property_id) {
                                      return item
                                    }

                                  })
                                  .map((addOnList) => {
                                    return (
                                      <div className="d-flex justify-content-between align-items-center py-2">
                                        <div>
                                          <p className="mb-0">
                                            {" "}
                                            {addOnList.FLD_Addon_Name}
                                          </p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <span className="me-2">
                                            {currencyCode}
                                            {Number(
                                              addOnList.FLD_Amount
                                            ).toFixed(2)}
                                          </span>

                                          {addOnList.FLD_Max_count > 1 ?
                                            addonCount == 0 ?
                                              <>
                                                <Button className="btn btn-danger add-to-cart-btn"
                                                  disabled={selectedAddonCount >=
                                                    data.FLD_Max_Addon}
                                                  onClick={() => {
                                                    let newAddon = selectedAddons;
                                                    setAddonCount(1)
                                                    setSelectedAddonCount(selectedAddonCount + 1)
                                                    newAddon.push(addOnList.FLD_ID + "#1");
                                                    setSelectedAddons(newAddon)
                                                    settotalAmount(
                                                      (
                                                        Number(totalAmount) +
                                                        Number(
                                                          addOnList.FLD_Amount
                                                        )
                                                      ).toFixed(2)
                                                    );
                                                  }}
                                                >Add</Button>
                                              </>
                                              :

                                              <div className="mb-0 small fw-bold quantity-control">
                                                <button
                                                  type="button"
                                                  className="quantity-btn btn btn-primary"
                                                  onClick={() => {
                                                    let newAddon = selectedAddons;
                                                    var index =
                                                      newAddon.indexOf(
                                                        addOnList.FLD_ID + "#" + addonCount
                                                      );
                                                    newAddon.splice(index, 1);

                                                    setSelectedAddons(newAddon)

                                                    setAddonCount(addonCount - 1)

                                                    if (addonCount == 1) {
                                                      setSelectedAddonCount(selectedAddonCount - 1)

                                                    } else {
                                                      newAddon.push(addOnList.FLD_ID + "#" + (parseInt(addonCount) - 1));
                                                    }
                                                    settotalAmount((Number(totalAmount) - Number(addOnList.FLD_Amount)).toFixed(2));
                                                  }}
                                                >
                                                  <FaMinus />
                                                </button>
                                                <div className="px-1">
                                                  {addonCount}
                                                </div>
                                                <button
                                                  type="button"
                                                  className="quantity-btn btn btn-primary"
                                                  disabled={addonCount >=
                                                    data.FLD_Max_Addon}
                                                  onClick={() => {
                                                    let newAddon = selectedAddons;
                                                    var index =
                                                      newAddon.indexOf(
                                                        addOnList.FLD_ID + "#" + addonCount
                                                      );
                                                    newAddon.splice(index, 1);
                                                    newAddon.push(addOnList.FLD_ID + "#" + (parseInt(addonCount) + 1));
                                                    setAddonCount(addonCount + 1)
                                                    setSelectedAddons(newAddon)
                                                    settotalAmount(
                                                      (
                                                        Number(totalAmount) +
                                                        Number(
                                                          addOnList.FLD_Amount
                                                        )
                                                      ).toFixed(2)
                                                    );

                                                  }}
                                                >
                                                  <FaPlus
                                                  />
                                                </button>
                                              </div>
                                            :
                                            data?.FLD_Max_Addon == 1 ?
                                              <RadioGroup>
                                                <Form.Check
                                                  inline
                                                  name=""
                                                  label=""
                                                  checked={addOnList.FLD_ID == selectedAddOnRadio}
                                                  value={addOnList.FLD_ID}
                                                  id={
                                                    addOnList.FLD_ID +
                                                    addOnList.FLD_Addon_Group
                                                  }
                                                  type="radio"
                                                  // disabled={
                                                  //   selectedAddonCount >=
                                                  //   data.FLD_Max_Addon &&
                                                  //   !document.getElementById(
                                                  //     addOnList.FLD_ID +
                                                  //     addOnList.FLD_Addon_Group
                                                  //   )?.checked
                                                  // }
                                                  className="me-0"
                                                  onChange={(e) => {
                                                    settotalAmount(Number(totalAmount))
                                                    let newAddon = [];
                                                    handleAddonCheckboxChange(e);
                                                    if (e.target.checked) {
                                                      newAddon.push(e.target.value + "#1");
                                                      setSelectedAddonAmount(
                                                        (Number(addOnList.FLD_Amount)).toFixed(2)
                                                      );
                                                    }
                                                    setSelectedAddons(newAddon);
                                                    setSelectedAddOnRadio(addOnList.FLD_ID)
                                                  }}
                                                />
                                              </RadioGroup>
                                              :
                                              <Form.Check
                                                inline
                                                name=""
                                                label=""
                                                value={addOnList.FLD_ID}
                                                id={
                                                  addOnList.FLD_ID +
                                                  addOnList.FLD_Addon_Group
                                                }
                                                type="checkbox"
                                                disabled={
                                                  selectedAddonCount >=
                                                  data.FLD_Max_Addon &&
                                                  !document.getElementById(
                                                    addOnList.FLD_ID +
                                                    addOnList.FLD_Addon_Group
                                                  )?.checked
                                                }
                                                className="me-0"
                                                onChange={(e) => {
                                                  let newAddon = selectedAddons;
                                                  handleAddonCheckboxChange(e);
                                                  if (e.target.checked) {
                                                    newAddon.push(e.target.value + "#1");
                                                    settotalAmount(
                                                      (
                                                        Number(totalAmount) +
                                                        Number(
                                                          addOnList.FLD_Amount
                                                        )
                                                      ).toFixed(2)
                                                    );
                                                  } else {
                                                    if (
                                                      newAddon?.includes(
                                                        e.target.value + "#1"
                                                      )
                                                    ) {
                                                      var index =
                                                        newAddon.indexOf(
                                                          e.target.value + "#1"
                                                        );
                                                      newAddon.splice(index, 1);
                                                      if (totalAmount > 0.0) {
                                                        settotalAmount(
                                                          (
                                                            Number(totalAmount) -
                                                            Number(
                                                              addOnList.FLD_Amount
                                                            )
                                                          ).toFixed(2)
                                                        );
                                                      }
                                                    }
                                                  }
                                                  setSelectedAddons(newAddon);
                                                }}
                                              />
                                          }
                                        </div>
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {itemSpiceLevel == "Yes" ? (
                    <>
                      <p className="fw-bold mb-0 text-theme ps-1">Choose your Spice Level</p>
                      <div
                        className="sp-lvl d-flex align-content-center"
                        style={{ padding: "15px" }}>
                        {spicelevelData.map((data, index) => {
                          return (
                            <div
                              className="d-flex data-spl-lebel spice-level-btn ps-1 align-content-center cursor-pointer"
                              style={{ padding: "10px" }}>
                              <button
                                className={
                                  checkedData == data.value ? "Active" : "InActive"
                                }
                                value={data.value}
                                onClick={(e) => {
                                  setCheckedData(data.value);
                                  setSpiceLevel(data.value);
                                }}>
                                <img
                                  alt=""
                                  src={data.img}
                                  className="spice-level-image-size"></img>
                                <label className="checkbox-spicelevel cursor-pointer" value={data.value}>
                                  {data.label}
                                </label>
                              </button>
                              {/* <input
                          type="radio"
                          className="onlineShowItems form-check-input radioBgdivr radioSize-add-on fs-5"
                          checked={checkedData == data.value ? true : false}
                          value={data.value}
                          onChange={(e) => {
                            setCheckedData(data.value);
                            setSpiceLevel(e.target.value);
                          }}
                        /> */}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          {restaurantOpen == "true" &&
            <Row>
              <Col
                lg={12}
                className="d-flex justify-content-center py-3 mt-3 bg-white position-fixed bottom-0 end-0 start-0 z-5">
                <Button
                  variant="danger"
                  className="w-auto text-center mx-auto"
                  size="lg"
                  disabled={disabledAddToCart}
                  onClick={() => {
                    if (property?.length > 0) {
                      if (selectedProperties?.length > 0) {
                        setDisabledAddToCart(true)
                        var login = localStorage.getItem("LoginDetailsWeb");
                        var logindetails = login ? JSON.parse(login)[0] : ""
                        PostApiCall.postRequest(
                          {
                            orderdate: moment().format("lll"),
                            itemdetails: `[{"Item":${itemId},"Quantity":${1},"SpiceLevel":${spiceLevel},"specialInstruction":"","addons":${selectedAddons == "" ? null : `"${selectedAddons}"`},"property":"${selectedProperties + "#1"}","copyconfig":"No"}]`,
                            status: "INCART",
                            customerid:
                              localStorage.getItem("LoginDetailsWeb") == null
                                ? null
                                : logindetails?.CustomerId,
                            createdon: moment().format("lll"),
                            updatedon: moment().format("lll"),
                            orderid:
                              localStorage.getItem("OrderData") == null
                                ? 0
                                : JSON.parse(localStorage.getItem("OrderData"))
                                  .order_id,
                            updatedby:
                              localStorage.getItem("LoginDetailsWeb") == null
                                ? null
                                : logindetails.CustomerId,

                            stateid: null,
                          },
                          "addToCart"
                        ).then((result) =>
                          result?.json().then((obj) => {
                            if (result.status === 200 || result.status === 201) {
                              localStorage.setItem(
                                "OrderData",
                                JSON.stringify(obj?.message[0])
                              );
                              setSelectedAddons([]);
                              setSelectedProperties([]);
                            } else {
                              Notiflix.Notify.Failure(obj.message);
                            }
                            getMenuData(obj.message);
                            setShowDetails(false);
                            getCart();
                          })
                        );
                      } else {
                        Notiflix.Notify.Failure("Please select protein")
                        return;
                      }
                    } else {
                      setDisabledAddToCart(true)
                      // var login = localStorage.getItem("LoginDetailsWeb");
                      // var logindetails = login ? JSON.parse(login)[0] : ""
                      PostApiCall.postRequest(
                        {
                          orderdate: moment().format("lll"),
                          itemdetails: `[{"Item":${itemId},"Quantity":${1},"SpiceLevel":${spiceLevel},"specialInstruction":"","addons":${selectedAddons == "" ? null : `"${selectedAddons}"`},"property":${null},"copyconfig":"No"}]`,
                          status: "INCART",
                          customerid:
                            localStorage.getItem("LoginDetailsWeb") == null
                              ? null
                              : logindetails?.CustomerId,
                          createdon: moment().format("lll"),
                          updatedon: moment().format("lll"),
                          orderid:
                            localStorage.getItem("OrderData") == null
                              ? 0
                              : JSON.parse(localStorage.getItem("OrderData"))
                                .order_id,
                          updatedby:
                            localStorage.getItem("LoginDetailsWeb") == null
                              ? null
                              : logindetails.CustomerId,

                          stateid: null,
                        },
                        "addToCart"
                      ).then((result) =>
                        result?.json().then((obj) => {
                          if (result.status === 200 || result.status === 201) {
                            localStorage.setItem(
                              "OrderData",
                              JSON.stringify(obj?.message[0])
                            );
                            setSelectedAddons([]);
                            setSelectedProperties([]);
                          } else {
                            Notiflix.Notify.Failure(obj.message);
                          }
                          setShowDetails(false);
                          getMenuData(obj.message);
                          getCart();
                        })
                      );
                    }

                  }}>
                  <div>
                    Add to Cart{" "}
                    {currencyCode}{" "}
                    {Number(totalAmount) + Number(itemPrice) == 0.0
                      ? "0.00"
                      : (Number(totalAmount) + Number(itemPrice) + (selectedAddonAmount !== null ? Number(selectedAddonAmount) : 0)).toFixed(2)}
                  </div>
                </Button>
              </Col>
            </Row>
          }
        </Container>
      </section>
    </>
  );
}

export default RecipeDetail;
