import "./DesignTowContactUs.css";
import { useEffect, useState } from "react";
import IconEmail from "../img/contact/icon-email.svg";
import IconMap from "../img/contact/icon-map.svg";
import IconPhone from "../img/contact/icon-phone.svg";
import React, { useContext } from "react";
import { store } from "../../../pages/context/store";
import { useFormik } from "formik";
import PostApiCall from "../../../components/OnlineOrdering/helper/PostAPI";
import * as Yup from "yup";
import Notiflix from "notiflix";
import { IoSync } from "react-icons/io5";

export default function DesignTowContactUs() {
  const { clientDetails } = useContext(store);
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      comments: "",
      captcha: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required*"),
      phone: Yup.string()
        .matches(/^\d+$/, "Phone number is invalid")
        .required("This field is required*"),
      email: Yup.string()
        .email("Email is Invalid")
        .required("This field is required*"),
      captcha: Yup.string().test('captcha', 'Please enter the correct CAPTCHA', value => value === captcha_number)
        .required("Please enter the CAPTCHA"),
    }),
    onSubmit: (values) => {
      const { name, phone, email, comments, captcha } = values;
      if (captcha === captcha_number) {
        sendMail(name, phone, email, comments);
        formik.resetForm();
      } else {
        // Handle invalid captcha submission
        console.log('Invalid CAPTCHA');
      }
    }
  });

  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 900000)
        .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 900000)
      .toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }

  // send mail to the admin
  function sendMail(name, phone, email, comments) {
    Notiflix.Loading.Dots("Please Wait...");
    PostApiCall.postRequest(
      {
        id: null,
        contactperson: name,
        contactnumber: phone,
        email: email,
        message: comments,
      },
      "contact"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Mail sent");
        } else {
          Notiflix.Notify.Failure("Failed");
        }
      })
    );
  }
  return (
    <>
      <div className="page-header contact-us-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header-box">
                <h1 className="text-anime">Contact Us</h1>
                <nav>
                  <ol
                    className="breadcrumb wow fadeInUp"
                    data-wow-delay="0.50s">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Contact Us</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="send-msg-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="section-title">
                <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                  Contact Us
                </h3>
                <h2 className="text-anime">
                  Reach out, and let’s savor the taste of Delhi and Punjab
                  together!
                </h2>
              </div>

              <div className="send-msg-body">
                <p className="wow fadeInUp" data-wow-delay="0.75s">
                  Thank you for your interest in Delhi Wale Sardar Ji. Whether
                  you have questions, feedback, or inquiries about our catering
                  services, franchise opportunities, or anything else, we’re
                  here to assist you. Please feel free to reach out to us using
                  the contact form or through the contact details provided.
                  We look forward to hearing from you and serving you with the
                  finest flavors of Delhi and Punjab.
                </p>

                <div
                  className="contact-detail wow fadeInRight"
                  data-wow-delay="1.0s">
                  <h3>Contact Details</h3>

                  <ul className="contact-list-icon">
                    <li className="list-item">
                      <span className="list-icon-img">
                        {" "}
                        <img src={IconMap} alt="" />{" "}
                      </span>
                      <span className="list-icon-text">
                        <p className="mb-0">
                          <b>Royal Sardar Ji</b>
                          {/* <b>{clientDetails[0]?.fld_restaurantname}</b> */}
                        </p>
                        <span>
                          SG - 46 - 47, Ground Floor, Galleria Market,<br />
                          DLF Phase IV, Sector - 28,<br />
                          Gurugram, Haryana - 122-009, INDIA.
                        </span>
                        {/* {clientDetails[0]?.fld_address} */}
                      </span>
                    </li>
                    <li className="list-item">
                      <span className="list-icon-img">
                        {" "}
                        <img src={IconEmail} alt="" />{" "}
                      </span>
                      <span className="list-icon-text">
                        <a
                          href={`mailto:${clientDetails[0]?.fld_email}`}
                          className="">
                          {clientDetails[0]?.fld_email}
                        </a>
                      </span>
                    </li>
                    <li className="list-item">
                      <span className="list-icon-img">
                        {" "}
                        <img src={IconPhone} alt="" />{" "}
                      </span>
                      <span className="list-icon-text">
                        <a
                          href={`tel:${clientDetails[0]?.fld_phonenumber}`}
                          className="">
                          {clientDetails[0]?.fld_phonenumber}
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="contact-form wow fadeInUp" data-wow-delay="1.25s">
                <form
                  onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-12 mb-4">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        required=""
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-12 mb-4">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        id="phone"
                        placeholder="Phone"
                        required=""
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.phone}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group col-md-12 mb-4">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        required=""
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>

                  


                    <div className="form-group col-md-12 mb-4">
                      <textarea
                        className="form-control"
                        id="msg"
                        rows="4"
                        name="comments"
                        placeholder="Write a Message"
                        value={formik.values.comments}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}></textarea>
                    </div>
                    <div className="form-group col-12 mb-4 captchdisplay">
                      <p
                        className="px-2 py-2 mb-2 me-2 border-dotted captcha-copy-disable captchvaluesize d-flex justify-content-between align-items-center"
                      >
                        {captcha_number}
                        <span
                          onClick={() => {
                            setRotatecaptcha("iorotate");
                            Genratecaptcha();
                          }}
                          className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
                        >
                          <IoSync className="ioSyncbtn rotate" />
                        </span>
                      </p>
                      <input
                        type="captcha"
                        className="form-control captchvaluesize"
                        placeholder="Enter Captcha"
                        name="captcha"
                        required=""
                        value={formik.values.captcha}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />                      
                    </div>
                    <p className="pt-0 mt-0" style={{ color: "red" }}>
                    {formik.touched.captcha && formik.errors.captcha ? (
                        <div>{formik.errors.captcha}</div>
                      ) : null}
                    </p>
                    <div className="col-md-12 button-group">
                      <button type="submit" className="btn-default">
                        Submit Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="contact-section p-0">
        <div className="container-fluid"> */}
      {/* <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                  Contact Us
                </h3>
                <h2 className="text-anime">Get In Touch</h2>
              </div>
            </div>
          </div> */}

      {/* <div className="row align-items-center">
            <div className="col-12"> */}
      <div className="google-map w-100 wow fadeInUp" data-wow-delay="0.75s">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.404622871648!2d77.07913472528267!3d28.467358775755347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d190fc97db97d%3A0x16b4f8f38630149e!2sDelhi%20wale%20sardar%20ji!5e0!3m2!1sen!2sin!4v1710849816895!5m2!1sen!2sin"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      {/* </div> */}

      {/* <div className="col-lg-4 col-md-12 order-lg-2 order-1">
              <div className="contact-detail wow fadeInRight" data-wow-delay="1.0s">
                <h3>Contact Details</h3>

                <ul className="contact-list-icon">
                  <li className="list-item">
                    <span className="list-icon-img">
                      {" "}
                      <img src={IconMap} alt="" />{" "}
                    </span>
                    <span className="list-icon-text">
                      123 Main Street Citytown, Stateville 12345 United States{" "}
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="list-icon-img">
                      {" "}
                      <img src={IconEmail} alt="" />{" "}
                    </span>
                    <span className="list-icon-text">
                      <a href="#">info@domainname.com</a>
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="list-icon-img">
                      {" "}
                      <img src={IconPhone} alt="" />{" "}
                    </span>
                    <span className="list-icon-text">
                      <a href="#">(+0) 123 456 789</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div> */}
      {/* </div> */}
      {/* </div>
      </div> */}
    </>
  );
}
