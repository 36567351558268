import "./DesignTwoTestimonial.css";
// import testiImg from "../img/testimonial/testimonial-img.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function DesignTwoTestimonial() {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="testimonials-section">
      <div className="container">
        <div className="row align-items-center">
          {/* <div className="col-lg-6 col-md-7"> */}
          <div className="col-12">
            <div className="section-title">
              <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                Testimonials
              </h3>
              <h2 className="text-anime">Words from Our Satisfied Customers</h2>
            </div>

            <div
              className="testimonial-slider-wrapper wow fadeInUp"
              data-wow-delay="0.75s">
              <Slider {...settings} className="pb-4">
                {/* <div className="h-100">
                  <div className="testimonial-slide">
                    <div className="testimonial-body">
                      <p>
                        Malai soya chaap was amazing. Though I received it cold
                        it tasted really good
                      </p>
                      <div className="testimonial-footer">
                        <h3>Jyoti Sharma</h3>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="h-100">
                  <div className="testimonial-slide">
                    <div className="testimonial-body">
                      <p>
                        I ordered veg fried rice from it, the taste was
                        authentic and spicy, I loved the taste. Must try
                      </p>
                      <div className="testimonial-footer">
                        <h3>Zoya</h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-100">
                  <div className="testimonial-slide">
                    <div className="testimonial-body">
                      <p>
                        Delhi Wale Sardar ji is an amazing place for North
                        Indian foods, I ordered a chole chawl combo ,the taste
                        was authentic. Loved it.
                      </p>
                      <div className="testimonial-footer">
                        <h3>Ayesha</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-100">
                  <div className="testimonial-slide">
                    <div className="testimonial-body">
                      <p>
                        It was delivered properly with proper packaging, The
                        food was tasty and amazing, The Delhi wale Sardar ji is
                        an amazing outlet. Loved it.
                      </p>
                      <div className="testimonial-footer">
                        <h3>Henil</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-100">
                  <div className="testimonial-slide">
                    <div className="testimonial-body">
                      <p>
                        For authentic and tasty food I must recommend Delhi Wale
                        Sardar ji , I just ordered Shahi paneer it was amazingly
                        prepared and the taste was amazing.
                      </p>
                      <div className="testimonial-footer">
                        <h3>Bobby Makkar</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-100">
                  <div className="testimonial-slide">
                    <div className="testimonial-body">
                      <p>
                        Delhi Wale Sardar ji serves amazing food for foodie, I
                        just loved everything that we ordered . Food arrived hot
                        with clean packaging. Loved it
                      </p>
                      <div className="testimonial-footer">
                        <h3>Vaibhav Goyal</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          {/* <div className="col-lg-6 col-md-5">
            <div className="testimonial-img">
              <img src={testiImg} alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
