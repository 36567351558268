import "./DesignTowFranchise.css";
import IconEmail from "../img/contact/icon-email.svg";
import IconMap from "../img/contact/icon-map.svg";
import IconPhone from "../img/contact/icon-phone.svg";
export default function DesignTowFranchise() {
  return (
    <>
      <div className="page-header franchise-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header-box">
                <h1 className="text-anime">Franchise</h1>
                <nav>
                  <ol className="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Franchise</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="franchise-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                Franchise
                </h3>
                <h2 className="text-anime">Franchise</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
