import { Image } from "antd";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import PostApiCall from "../../../components/OnlineOrdering/helper/PostAPI";
import { store } from "../../context/store";
import "../ImageGalleryPage/ImageGalleryPage.css";
import { Helmet } from "react-helmet";
const ImageGalleryPage = () => {
  const [gallaryImages, setGallaryImages] = useState([]);
  const { clientDetails } = useContext(store);
  // useEffect(() => {
  //   PostApiCall.postRequest(
  //     {
  //       whereClause: `where fld_restaurant_id = ${obj1.message[0]?.fld_client_id}`,
  //       columns: "*",
  //     },
  //     "getRestaurantImages"
  //   ).then((results) =>
  //     results?.json().then((obj) => {
  //       if (results.status == 200 || results.status == 201) {
  //         setGallaryImages(obj.message);
  //         // Notiflix.Loading.Remove();
  //       }
  //     })
  //   );
  // }, []);

  useEffect(() => {
    PostApiCall.postRequest(
      {
        id: "",
      },
      "getClientData"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          PostApiCall.postRequest(
            {
              whereClause: `where fld_restaurant_id = ${obj1.message[0]?.fld_client_id}`,
              columns: "*",
            },
            "getRestaurantImages"
          ).then((results) =>
            results?.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                const shuffledImages = obj.message.sort(
                  () => Math.random() - 0.5
                );
                const randomImages = shuffledImages;
                setGallaryImages(randomImages);
                Notiflix.Loading.Remove();
              }
            })
          );
          Notiflix.Loading.Remove();
        }
      })
    );
  }, []);

  return (
    <>
    <Helmet>
        <title>Flavorful Frames: Where Taste Meets Memories</title>
        <meta
          name="description"
          content="Experience the magic of Flavorful Frames, where taste meets memories. Browse our website to find the perfect frames to add a touch of flavor to your life ."
        />
        <meta
          property="og:title"
          content="Flavorful Frames: Where Taste Meets Memories"
        />
        <meta
          property="og:description"
          content="Experience the magic of Flavorful Frames, where taste meets memories. Browse our website to find the perfect frames to add a touch of flavor to your life ."
        />
        <meta name="keywords" content="Gallery, Delhi Wale Sardar Ji, Gurugram" />
        <meta property="og:url" content="https://delhiwalesardarji.com/Gallery" />
        <link rel="canonical" href="https://delhiwalesardarji.com/Gallery" />
        <meta
          property="og:image"
          content="https://delhiwalesardarji.com/static/media/Gallery-banner.5c7b35c8248efab54a4f.jpg"
        />
        <meta
          name="twitter:title"
          content="Flavorful Frames: Where Taste Meets Memories"
        />
        <meta
          name="twitter:description"
          content="Experience the magic of Flavorful Frames, where taste meets memories. Browse our website to find the perfect frames to add a touch of flavor to your life .
"
        />
        <meta
          name="twitter:image"
          content="https://delhiwalesardarji.com/static/media/Gallery-banner.5c7b35c8248efab54a4f.jpg"
        />
      </Helmet>
      <div className="page-header gallery-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header-box">
                <h1 className="text-anime">Gallery</h1>
                <nav>
                  <ol className="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Gallery</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Gallary-images container my-4 my-lg-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="content">
              <div className="section-title mb-4">
                <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                  Delicious Moments Captured Here
                </h3>
                <h2 className="text-anime">Flavors in Frames</h2>
              </div>
            </div>
          </div>
          <Image.PreviewGroup>
            {gallaryImages?.map((data, index) => {
              return (
                <div className="col-4 mb-4">
                  <Image
                    id={index}
                    src={data.fld_image_url}
                    width="100%"></Image>
                </div>
              );
            })}
          </Image.PreviewGroup>
        </div>
      </div>
    </>
  );
};
export default ImageGalleryPage;
