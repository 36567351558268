import moment from "moment";
import React, { useContext } from "react";
import { store } from "../../pages/context/store";
import "./Footer.css";
import Phonenumber from "../../assets/img/footer/ii1.png";
import Location from "../../assets/img/footer/ii2.png";
import Timing from "../../assets/img/footer/timinig.png";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import SardarJiLogo from "../../assets/img/ComingSoon/delhi-wale-sardar-ji-logo.png";
import fssaiIcon from "../../assets/img/footer/fssai.png";
function Footer() {
  const {
    fssai,
    setFssai,
    restaurantName,
    cardAccepted,
    setcardAccepted,
    clientDetails,
  } = useContext(store);

  const { restaurantAddress, setRestaurantAddress } = useContext(store);
  const { restaurantTime, setRestaurantTime } = useContext(store);
  return (
    <>
      <div className="footer-section">
        {/* <img
          className="f-left wow fadeInLeft"
          data-wow-delay=".5s"
          src={Fleft}
          alt=""
        />
        <img
          className="f-right wow fadeInRight"
          data-wow-delay=".5s"
          src={Fright}
          alt=""
        /> */}
        <div className="container">
          <div className="row justify-content-center">
            {/* <div className="col-md-8">
              <div className="top-area">
                <div className="footer-img-box">
                  <img src={logo} alt="masala-of-india" />
                </div>
                <div className="footer-social-links">
                  <span className="label">Follow us :</span>
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/masalaofindiacuisine/">
                        <i className="fab fa-facebook-f">
                          <FaFacebookF className="social-icon" />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/masala_of_india/?hl=en">
                        <i className="fab fa-twitter">
                          <FaInstagram className="social-icon" />
                        </i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-md-12 mt-4">
              <div className="row text-center text-lg-start justify-content-center align-items-lg-end justify-content-lg-between">
                <div className="col-lg-6 col-12">
                  <img src={SardarJiLogo} className="img-fluid footer-logo" />
                  <p className="text-white">
                    {/* In the heart of Gurgaon, amidst the hustle and bustle of
                    city life, there exists a haven of flavors, tradition, and
                    heartfelt hospitality – Delhi Wale Sardar Ji. */}
                    The Home of Authentic Indian Cuisine
                  </p>
                  <div className="footer-social-links d-lg-flex">
                    <div>
                      <p className="text-white fs-5 mb-1">Follow Us</p>
                      <ul>
                        {clientDetails[0]?.fld_facebook && (
                          <li>
                            <a
                              href={clientDetails[0]?.fld_facebook}
                              target="_blank">
                              <FaFacebookF className="social-icon" />
                            </a>
                          </li>
                        )}
                        {clientDetails[0]?.fld_instagram && (
                          <li>
                            <a
                              href={clientDetails[0]?.fld_instagram}
                              target="_blank">
                              <FaInstagram className="social-icon" />
                            </a>
                          </li>
                        )}
                        {clientDetails[0]?.fld_youtube && (
                          <li>
                            <a
                              href={clientDetails[0]?.fld_youtube}
                              target="_blank">
                              <FaYoutube className="social-icon" />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="ms-lg-4">
                      <p className="text-white fs-5 mb-1">Call Us</p>
                      <a
                        href={`tel:${clientDetails[0]?.fld_phonenumber}`}
                        className="text-white fs-3">
                        {clientDetails[0]?.fld_phonenumber}
                      </a>
                    </div>
                    <div className="ms-lg-4 text-white">
                      <img src={fssaiIcon} className="img-fluid fssai-icon" />
                      <p>{clientDetails[0]?.fld_fssai_number}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-6">
                  <div className="info-box">
                    <div className="icon">
                      <img src={Phonenumber} alt="" />
                    </div>
                    <p className="text-white text-center">Phone Number</p>
                    <p className="text-white text-center">
                      <a
                        href={`tel:${clientDetails[0]?.fld_phonenumber}`}
                        className="text-white">
                        {clientDetails[0]?.fld_phonenumber}
                      </a>
                    </p>
                  </div>
                </div> */}
                {/* <div className="col-lg-3 col-6">
                  <div className="info-box">
                    <div className="icon">
                      <img src={Location} alt="" />
                    </div>
                    <p className="text-white text-center">
                      {clientDetails[0]?.fld_address}
                    </p>
                    <p className="text-white text-center">{restaurantAddress}</p> 
                  </div>
                </div> */}
                <div className="col-lg-3 mt-4 mt-lg-0">
                  <div className="info-box">
                    <div className="icon">
                      <img src={Timing} alt="" />
                    </div>
                    <p className="text-white text-center">Monday - Sunday</p>
                    <p className="text-white text-center">
                      11:45 am - 01:45 am
                      {/* {restaurantTime} */}
                    </p>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-6">
                  <div className="footer-social-links text-center">
                    <ul>
                      <li>
                        <a href={clientDetails[0]?.fld_facebook} target="_blank">
                          
                          <FaFacebookF className="social-icon" />
                          
                        </a>
                      </li>
                      <li>
                        <a href={clientDetails[0]?.fld_instagram} target="_blank">
                          
                          <FaInstagram className="social-icon" />
                          
                        </a>
                      </li>
                    </ul>
                    <p className="text-white text-center">
                      Let's Connect Socially
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row copyright-text">
            <div className="col-l2 mb-3 text-center">
              <ul className="footer-nav w-100 text-center text-lg-start justify-content-center">
                <li>
                  <a href="/terms-condition">Terms and Conditions</a>
                </li>
                <li>
                  <a href="/privacy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/cancellation-refund-policy">Cancellation and Refund Policy</a>
                </li>
                <li>
                  <a href="/shipping-delivery-terms">Shipping and Delivery Terms</a>
                </li>
                {/* <li>
                  <a href="">FAQ</a>
                </li> */}
                <li>
                  <a href="/disclaimer">Disclaimer</a>
                </li>
              </ul>
            </div>
            <div className="col-12 text-center">
              Copyright © 2024 Royal Sardar Ji.
              {/* {clientDetails[0]?.fld_restaurantname} */}
              <br className="d-block d-md-none" /> All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid footer-bottom-company-name mb-lg-0 pb-4 pb-md-0">
        {/* <div className="container"> */}
        <div className="row">
          <div className="col-12 col-lg-6 text-lg-start text-center text-white pt-2 pb-1 py-lg-3">
            Powered by Grub Digest - <br className="d-block d-md-none" /> A
            Product of
            <a
              className="text-white"
              href="https://globaltrendz.com/"
              target="blank">
              &nbsp; Global Trendz
            </a>
          </div>
          {/* <div className="col-12 col-md-6 text-md-end text-center text-white py-1 py-lg-3">
              <a
                className="text-white"
                href="https://grubdigest.com/"
                target="blank">
                {" "}
                Powered By Grub Digest
              </a>
            </div> */}
        </div>
        {/* </div> */}
      </div>
    </>
  );
}
export default Footer;
