import "./DesignTwoOffers.css";
// import offerimg from "../img/offers/restaurants.png";
import offone from "../img//offers/offer-1.jpg";
import ComBanner from "../img//offers/com-banner.jpg";
import offtwo from "../img//offers/offer-2.jpg";
// import offthree from "../img//offers/info-bar-3.jpg";
// import offfour from "../img//offers/info-bar-4.jpg";
import { useContext, useEffect } from "react";
import { store } from "../../context/store";
import { Link } from "react-router-dom";
export default function DesignTwoOffers() {
  const { scrollToSpecialOffer } = useContext(store);
  useEffect(() => {
    if (scrollToSpecialOffer) {
      const targetDiv = document.getElementById(scrollToSpecialOffer);
      const rect = targetDiv.getBoundingClientRect();
      const targetScrollTop = rect.top + window.pageYOffset - 100;
      window.scrollTo({
        top: targetScrollTop,
        behavior: "smooth",
      });
    }
  }, [scrollToSpecialOffer]);
  return (
    <div className="restaurant-inforamtion" id="special-offer-home">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="restaurant-info-box">
              <div className="info-box-content">
			  <img src={ComBanner} />
			  </div>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="restaurant-info-box">
              <div className="info-box-content">
                
                <img src={offone} />
                <div className="info-btn">
                  <Link
                    to="/order-online"
                    className="btn-default wow fadeInUp"
                    data-wow-delay="1.00s">
                    Order Online
                  </Link>
                 
                </div>
              </div>

              
            </div>
          </div>
          <div className="col-lg-6">
            <div className="restaurant-info-box">
              <div className="info-box-content">
                
                <img src={offtwo} />
                <div className="info-btn">
                  <Link
                    to="/order-online"
                    className="btn-default wow fadeInUp"
                    data-wow-delay="1.00s">
                    Order Online
                  </Link>
                  
                </div>
              </div>

              
            </div>
          </div> */}
        </div>

        {/* <div className="row">
				
				<div className="col-lg-3 col-6">
					<div className="counter-bar wow fadeInUp" data-wow-delay="0.50s">
						<div className="counter-bar-img">
							<img src={offone} alt="" />
						</div>
						<div className="counter-bar-body">
							<h3><span className="counter">56</span></h3>
							<p>Food Verities</p>
						</div>
					</div>
				</div>
				
	
				
				<div className="col-lg-3 col-6">
					<div className="counter-bar wow fadeInDown" data-wow-delay="0.75s">
						<div className="counter-bar-img">
							<img src={offtwo} alt="" />
						</div>
						<div className="counter-bar-body">
							<h3><span className="counter">7</span></h3>
							<p>Awards</p>
						</div>
					</div>
				</div>
				
	
				
				<div className="col-lg-3 col-6">
					<div className="counter-bar wow fadeInUp" data-wow-delay="1.25s">
						<div className="counter-bar-img">
							<img src={offthree} alt="" />
						</div>
						<div className="counter-bar-body">
							<h3><span className="counter">125</span>k</h3>
							<p>Happy Foodies</p>
						</div>
					</div>
				</div>
				
	
				
				<div className="col-lg-3 col-6">
					<div className="counter-bar wow fadeInDown" data-wow-delay="1.50s">
						<div className="counter-bar-img">
							<img src={offfour} alt="" />
						</div>
						<div className="counter-bar-body">
							<h3><span className="counter">3</span></h3>
							<p>Branches</p>
						</div>
					</div>
				</div>
				
			</div> */}
      </div>
    </div>
  );
}
