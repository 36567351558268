import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  Offcanvas, ProgressBar, Toast
} from "react-bootstrap";
import { FaFacebookF, FaInstagram, FaStar, FaYoutube } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { MdLocationPin } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import ReactStars from "react-rating-stars-component";
import { Link, useSearchParams } from "react-router-dom";
import SardarJiLogo from "../../../assets/img/ComingSoon/delhi-wale-sardar-ji-logo.png";
import grublogo from "../../../assets/img/logo-grubdigest.png";
import GetApiCall from "../../../components/OnlineOrdering/helper/GetApi";
import PostApiCall from "../../../components/OnlineOrdering/helper/PostAPI";
import { store } from "../../context/store";
import "./DesignTwoNavbar.css";

function DesignTwoNavbar() {
  const [scroll, setScroll] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [searchParams] = useSearchParams();
  const {
    setShowLoginModal,
    setClientDetails,
    clientDetails,
    setRestaurantOpen,
    setFssai,
    setRestaurantAddress,
    setRestaurantTime,
    setRestaurantName,
    setCurrencyCode,
    setCustomerValidation,
    setClientLogo,
    setKeyStrings,
    setbannerDetails,
    setScrollToSpecialOffer,
  } = useContext(store);
  const handleShowLoginModal = () => setShowLoginModal(true);
  const [showRating, setShowRating] = useState([false]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [loginDisabled] = useState(
    localStorage.getItem("LoginDetailsWeb") != null &&
      localStorage.getItem("SessionExpired") === null
      ? false
      : true
  );

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };
  const toggleShowRating = (index) => {
    const newShow = [...showRating];
    newShow[index] = !newShow[index];
    setShowRating(newShow);
  };
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }

    if (window.pageYOffset > 0) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 0);
      });
    }
    PostApiCall.postRequest(
      {
        id: "",
      },
      " "
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setClientData(obj1.message);
          setClientDetails(obj1.message);
          setRestaurantOpen(obj1.message[0]?.restaurant_open);
          setFssai(obj1.message[0].fld_fssai_number);
          setRestaurantAddress(
            obj1.message[0].fld_address +
            ", " +
            obj1.message[0].city_name +
            ", " +
            obj1.message[0].state_name
          );
          setRestaurantTime(obj1.message[0].res_timings);
          setRestaurantName(obj1.message[0].fld_restaurantname);
          setShowRating(Array(obj1.message.length).fill(false));
          setCurrencyCode(obj1.message[0].fld_currency);
          setCustomerValidation(obj1.message[0].fld_customer_validation_using);
          setClientLogo(obj1.message[0]?.fld_image);
          Notiflix.Loading.Remove();
        }
      })
    );
    GetApiCall.getRequest("getKeyStrings").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setKeyStrings(obj1.data);
          Notiflix.Loading.Remove();
        }
      })
    );
    GetApiCall.getRequest("getBannerDetails").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            setbannerDetails(obj1.message);
          }
          Notiflix.Loading.Remove();
        }
      })
    );
  }, []);

  return (
    <>
      <header className="main-header">
        <div className={scroll ? "header-sticky active" : "header-sticky"}>
          <Navbar key="md" expand="lg" className="py-2">
            <Container>
              <Navbar.Brand href="/" className="order-2 order-lg-1">
                <img
                  src={SardarJiLogo}
                  className="logo-size"
                  alt={clientData[0]?.fld_restaurantname}
                />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="offcanvasNavbar-expand-md"
                className="order-1 order-lg-2"
                onClick={toggleOffcanvas}>
                <RxHamburgerMenu />
              </Navbar.Toggle>
              <div className="order-online-location order-3">
                <MdLocationPin />
              </div>
              <Navbar.Offcanvas
                key="1"
                show={showOffcanvas}
                onHide={() => setShowOffcanvas(false)}
                id="offcanvasNavbar-expand-md"
                aria-labelledby="offcanvasNavbarLabel-expand-md"
                placement="start"
                className="phn-view-sidebar order-lg-4">
                <Offcanvas.Header closeButton key="5">
                  <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
                    <img
                      src={SardarJiLogo}
                      className="logo-size"
                      alt={clientData[0]?.fld_restaurantname}
                    />
                  </Offcanvas.Title>
                  {clientData != null
                    ? clientData.map((cd, index) => {
                      return (
                        <div
                          onClick={() => {
                            toggleShowRating(index);
                          }}
                          className="d-flex justify-content-start justify-content-lg-end mobile-review-box">
                          <div
                            className="btn-grub w-auto cursor-pointer"
                            onClick={() => {
                              toggleShowRating(index);
                            }}>
                            <p className="rest-rating-reviews mt-2">
                              <p className="mb-1">{cd.rating_count}</p>
                              <p className="mb-0">Reviews</p>
                            </p>
                            <ReactStars
                              count={5}
                              value={cd.FLD_Rating}
                              size={20}
                              edit={false}
                              isHalf={true}
                              activeColor="#ffd700"
                              classNames="d-flex cursor-pointer"
                            />
                          </div>
                          <Toast
                            // show={showRating}
                            show={showRating[index]}
                            onClose={() => toggleShowRating(index)}
                            className="rating-popup homepage">
                            <Toast.Header className="justify-content-between">
                              <div className="d-flex flex-column">
                                <div className="d-flex align-items-center gap-2">
                                  <ReactStars
                                    count={5}
                                    value={cd.FLD_Rating}
                                    size={20}
                                    onChange={(rating) => {
                                      var login =
                                        localStorage.getItem(
                                          "LoginDetailsWeb"
                                        );
                                      var logindetails = login
                                        ? JSON.parse(login)[0]
                                        : "";
                                      if (login === undefined) {
                                        handleShowLoginModal();
                                      } else {
                                        PostApiCall.postRequest(
                                          {
                                            rating: rating,
                                            userId:
                                              localStorage.getItem(
                                                "LoginDetailsWeb"
                                              ) == null
                                                ? null
                                                : logindetails.CustomerId,
                                          },
                                          "UpdateRestaurantRating"
                                        ).then((result) =>
                                          result?.json().then((obj) => {
                                            if (
                                              result.status == 200 ||
                                              result.status == 201
                                            ) {
                                              PostApiCall.postRequest(
                                                {
                                                  id: parseInt(
                                                    searchParams.get(
                                                      "clientid"
                                                    )
                                                  ),
                                                },
                                                "getClientData"
                                              ).then((results) =>
                                                results
                                                  ?.json()
                                                  .then((obj1) => {
                                                    if (
                                                      results.status ===
                                                      200 ||
                                                      results.status === 201
                                                    ) {
                                                      setClientData(
                                                        obj1.message
                                                      );
                                                      setClientDetails(
                                                        obj1.message
                                                      );
                                                      setShowRating(
                                                        Array(
                                                          obj1.message.length
                                                        ).fill(false)
                                                      );
                                                      Notiflix.Loading.Remove();
                                                    }
                                                  })
                                              );
                                              Notiflix.Notify.Success(
                                                "Thank you for rating."
                                              );
                                            } else {
                                              Notiflix.Notify.Failure(
                                                obj.message
                                              );
                                            }
                                          })
                                        );
                                      }
                                    }}
                                    edit={true}
                                    isHalf={false}
                                    activeColor="#ffd700"
                                  />
                                  <h6 className="fw-bold d-block mb-0">
                                    {cd.FLD_Rating} out of 5
                                  </h6>
                                </div>
                              </div>
                            </Toast.Header>
                            {cd.rating_breakup?.split("#").map((data) => {
                              return (
                                <Toast.Body className="bg-white">
                                  <div className="d-flex gap-2 align-items-center">
                                    <p className="mb-0 text-end text-dark">
                                      {data?.split(",")[0]} Star
                                    </p>
                                    <div>
                                      <ProgressBar
                                        variant="warning"
                                        now={data.split(",")[1]}
                                      />
                                    </div>
                                    <p className="mb-0 text-dark">
                                      {data.split(",")[1]}%
                                    </p>
                                  </div>
                                </Toast.Body>
                              );
                            })}
                          </Toast>
                        </div>
                      );
                    })
                    : ""}
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 gap-nav-list navbar-link-color">
                    <Nav.Link
                      href="/"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none">
                      Home
                    </Nav.Link>
                    <Nav.Link
                      href="/our-story"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none">
                      Our Story
                    </Nav.Link>
                    <Link
                      to="/#special-offers"
                      onClick={() => {
                        toggleOffcanvas();
                        setScrollToSpecialOffer("special-offer-home");
                      }}
                      className="d-block d-lg-none nav-link">
                      Special Offers
                    </Link>
                    {/* <Nav.Link
                      href="/franchise"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none">
                      Franchise
                    </Nav.Link> */}
                    <Nav.Link
                      href="/catering"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none">
                      Catering
                    </Nav.Link>
                    <Nav.Link
                      href="/gallery"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none">
                      Gallery
                    </Nav.Link>
                    <Nav.Link
                      href="/contact-us"
                      onClick={toggleOffcanvas}
                      className="d-block d-lg-none">
                      Contact Us
                    </Nav.Link>

                    <Nav.Link href="/" className="d-none d-lg-flex">
                      Home
                    </Nav.Link>
                    <Nav.Link href="/our-story" className="d-none d-lg-flex">
                      Our Story
                    </Nav.Link>
                    <Link
                      to="/#special-offers"
                      onClick={() => {
                        setScrollToSpecialOffer("special-offer-home");
                      }}
                      className="d-none d-lg-flex nav-link">
                      Special Offers
                    </Link>
                    {/* <Nav.Link
                      href="/franchise"
                      className="d-none d-lg-flex">
                      Franchise
                    </Nav.Link> */}
                    <Nav.Link href="/catering" className="d-none d-lg-flex">
                      Catering
                    </Nav.Link>
                    <Nav.Link href="/Gallery" className="d-none d-lg-flex">
                      Gallery
                    </Nav.Link>
                    <Nav.Link href="/contact-us" className="d-none d-lg-flex">
                      Contact Us
                    </Nav.Link>
                    {loginDisabled === false &&
                      window.location.pathname == "/order-online" ? (
                      <Nav.Link
                        href="/order-online"
                        className="nav-link"
                        onClick={() => {
                          localStorage.removeItem("LoginDetailsWeb");
                        }}>
                        Logout
                      </Nav.Link>
                    ) : (
                      ""
                    )}
                    <ul className="desk-sm-icon mb-0 d-none d-lg-flex">
                      {clientData[0]?.fld_facebook && (
                        <li>
                          <a
                            href={clientData[0]?.fld_facebook}
                            target="_blank"
                            rel="noreferrer">
                            <FaFacebookF />
                          </a>
                        </li>
                      )}
                      {clientData[0]?.fld_instagram && (
                        <li>
                          <a
                            href={clientData[0]?.fld_instagram}
                            target="_blank"
                            rel="noreferrer">
                            <FaInstagram />
                          </a>
                        </li>
                      )}
                      {clientDetails[0]?.fld_youtube && (
                        <li>
                          <a
                            href={clientDetails[0]?.fld_youtube}
                            target="_blank"
                            rel="noreferrer">
                            <FaYoutube />
                          </a>
                        </li>
                      )}
                    </ul>
                    {clientData != null
                      ? clientData.map((cd, index) => {
                        return (
                          <div
                            onClick={() => {
                              toggleShowRating(index);
                            }}
                            className="justify-content-start justify-content-lg-end d-none d-lg-flex me-2">
                            <div
                              className="btn-grub w-auto cursor-pointer"
                              onClick={() => {
                                toggleShowRating(index);
                              }}>
                              <FaStar />
                            </div>
                            <Toast
                              // show={showRating}
                              show={showRating[index]}
                              onClose={() => toggleShowRating(index)}
                              className="rating-popup homepage">
                              <Toast.Header className="justify-content-between">
                                <div className="d-flex flex-column">
                                  <div className="d-flex align-items-center gap-2">
                                    <ReactStars
                                      count={5}
                                      value={cd.FLD_Rating}
                                      size={20}
                                      onChange={(rating) => {
                                        var login =
                                          localStorage.getItem(
                                            "LoginDetailsWeb"
                                          );
                                        var logindetails = login
                                          ? JSON.parse(login)[0]
                                          : "";
                                        if (login === undefined) {
                                          handleShowLoginModal();
                                        } else {
                                          PostApiCall.postRequest(
                                            {
                                              rating: rating,
                                              userId:
                                                localStorage.getItem(
                                                  "LoginDetailsWeb"
                                                ) == null
                                                  ? null
                                                  : logindetails.CustomerId,
                                            },
                                            "UpdateRestaurantRating"
                                          ).then((result) =>
                                            result?.json().then((obj) => {
                                              if (
                                                result.status == 200 ||
                                                result.status == 201
                                              ) {
                                                PostApiCall.postRequest(
                                                  {
                                                    id: parseInt(
                                                      searchParams.get(
                                                        "clientid"
                                                      )
                                                    ),
                                                  },
                                                  "getClientData"
                                                ).then((results) =>
                                                  results
                                                    ?.json()
                                                    .then((obj1) => {
                                                      if (
                                                        results.status ===
                                                        200 ||
                                                        results.status === 201
                                                      ) {
                                                        setClientData(
                                                          obj1.message
                                                        );
                                                        setClientDetails(
                                                          obj1.message
                                                        );
                                                        setShowRating(
                                                          Array(
                                                            obj1.message
                                                              .length
                                                          ).fill(false)
                                                        );
                                                        Notiflix.Loading.Remove();
                                                      }
                                                    })
                                                );
                                                Notiflix.Notify.Success(
                                                  "Thank you for rating us."
                                                );
                                              } else {
                                                Notiflix.Notify.Failure(
                                                  obj.message
                                                );
                                              }
                                            })
                                          );
                                        }
                                      }}
                                      edit={true}
                                      isHalf={false}
                                      activeColor="#ffd700"
                                    />
                                    <h6 className="fw-bold d-block mb-0">
                                      {cd.FLD_Rating} out of 5
                                    </h6>
                                  </div>
                                </div>
                              </Toast.Header>
                              {cd.rating_breakup?.split("#").map((data) => {
                                return (
                                  <Toast.Body className="bg-white">
                                    <div className="d-flex gap-2 align-items-center">
                                      <p className="mb-0 text-end text-dark">
                                        {data?.split(",")[0]} Star
                                      </p>
                                      <div>
                                        <ProgressBar
                                          variant="warning"
                                          now={data.split(",")[1]}
                                        />
                                      </div>
                                      <p className="mb-0 text-dark">
                                        {data.split(",")[1]}%
                                      </p>
                                    </div>
                                  </Toast.Body>
                                );
                              })}
                            </Toast>
                            {/*  <a
                              target="_blank"
                              href="#"
                              className="btn-grub"
                              rel="noreferrer">
                              <FaShareAlt className="btn-icon" />
                            </a>
                            <a
                              style={{
                                display:
                                  cd.fld_whatsappnumber != null
                                    ? "flex"
                                    : "none",
                              }}
                              target="_blank"
                              href={
                                cd.fld_phonenumber != null
                                  ? cd.fld_phonenumber
                                  : ""
                              }
                              className="btn-whatsappcall"
                              rel="noreferrer">
                              <FaWhatsapp className="btn-icon" />
                            </a>
                            <a
                              style={{
                                display:
                                  cd.fld_facebook != null ? "flex" : "none",
                              }}
                              target="_blank"
                              rel="noreferrer"
                              href={
                                cd.fld_facebook != null ? cd.fld_facebook : ""
                              }
                              className="btn-facebook">
                              <FaFacebookF className="btn-icon" />
                            </a>
                            <a
                              style={{
                                display:
                                  cd.fld_instagram != null ? "flex" : "none",
                              }}
                              target="_blank"
                              rel="noreferrer"
                              href={
                                cd.fld_instagram != null ? cd.fld_instagram : ""
                              }
                              className="btn-instagram">
                              <FaInstagram className="btn-icon" />
                            </a>
                            <a
                              style={{
                                display:
                                  cd.fld_youtube != null ? "flex" : "none",
                              }}
                              target="_blank"
                              rel="noreferrer"
                              href={
                                cd.fld_youtube != null ? cd.fld_youtube : ""
                              }
                              className="btn-youtube">
                              <FaYoutube className="btn-icon" />
                            </a>
                            <a
                              style={{
                                display: cd.fld_halal != null ? "flex" : "none",
                              }}
                              target="_blank"
                              rel="noreferrer"
                              href={cd.fld_halal != null ? cd.fld_halal : ""}>
                              <img
                                alt="halal"
                                src={HalalIcon}
                                className="halal-icon"
                              />
                             </a>  */}
                          </div>
                        );
                      })
                      : ""}
                    {window.location.pathname != "/order-online" && (
                      <div className="text-lg-end d-none d-lg-flex">
                        <a
                          href="/order-online"
                          className="btn-default onliner-order-desktop-btn">
                          Menu
                        </a>
                      </div>
                    )}
                  </Nav>

                  <div className="d-block d-lg-none mb-3 py-3">
                    <a href="/order-online" className="btn-default w-100">
                      Menu
                    </a>
                  </div>

                  <div className="mb-2 mt-1 mb-md-0 d-block d-lg-none">
                    <p className="mb-1 mobile-nav-heading">Contact us</p>
                    <a href={`tel:${clientDetails[0]?.fld_phonenumber}`} className="mobile-call-icon">
                      {" "}
                      <IoCall />
                      {clientDetails[0]?.fld_phonenumber}
                    </a>
                  </div>
                  <div className="mb-2 mt-3 mb-md-0 d-block d-lg-none">
                    <p className="mb-0 mobile-nav-heading">
                      Let's Connect Socially
                    </p>
                    <ul className="mobile-sm-icon">
                      <li>
                        <a
                          href={clientData[0]?.fld_facebook}
                          target="_blank"
                          rel="noreferrer">
                          {/* <i className="fab fa-facebook-f"> */}
                          <FaFacebookF />
                          {/* </i> */}
                        </a>
                      </li>
                      <li>
                        <a
                          href={clientData[0]?.fld_instagram}
                          target="_blank"
                          rel="noreferrer">
                          {/* <i className="fab fa-twitter"> */}
                          <FaInstagram />
                          {/* </i> */}
                        </a>
                      </li>
                      <li>
                        <a
                          href={clientDetails[0]?.fld_youtube}
                          target="_blank"
                          rel="noreferrer">
                          <FaYoutube />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Offcanvas.Body>
                <div className="sticky-bottom w-100 pb-4 d-flex flex-column justify-content-center align-items-center d-lg-none p-3">
                  <p className="mb-1">Powered by</p>
                  <img src={grublogo} alt="" className="img-fluid w-25" />
                </div>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
      </header>
      <div className="order-online-btn w-100 p-2 bg-white fixed-bottom d-block d-lg-none">
        <a href="/order-online" className="w-100 text-center mb-0 btn-default">
          Menu
        </a>
      </div>
    </>
  );
}

export default DesignTwoNavbar;
