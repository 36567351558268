import "./DesignTwoHome.css";
import DesignTowContactUs from "./DesignTowContactUs/DesignTowContactUs";
import DesignTwoNavbar from "./NavBar/DesignTwoNavbar";
import Footer from "../../components/Footer/Footer";
import DesignTowFranchise from "./DesignTowFranchise/DesignTowFranchise";
import DesignTowCateringInner from "./DesignTowCateringInner/DesignTowCateringInner";
import { Helmet } from "react-helmet";
export default function DesignTowCateringPage() {
  return (
    <div className="design-two">
       <Helmet>
        <title>Flavorful Frames: Where Taste Meets Memories</title>
        <meta
          name="description"
          content="Experience the magic of Flavorful Frames, where taste meets memories. Browse our website to find the perfect frames to add a touch of flavor to your life ."
        />
        <meta
          property="og:title"
          content="Flavorful Frames: Where Taste Meets Memories"
        />
        <meta name="keywords" content="Catering, food catering near me, catering services near me, best catering near me, Delhi Wale Sardar Ji, Gurugram" />
        <meta
          property="og:description"
          content="Experience the magic of Flavorful Frames, where taste meets memories. Browse our website to find the perfect frames to add a touch of flavor to your life ."
        />
        <meta property="og:url" content="https://delhiwalesardarji.com/catering" />
        <link rel="canonical" href="https://delhiwalesardarji.com/catering" />
        <meta
          property="og:image"
          content="https://delhiwalesardarji.com/static/media/Corporate-Events.35746114951c17c10502.jpg"
        />
        <meta
          name="twitter:title"
          content="Flavorful Frames: Where Taste Meets Memories"
        />
        <meta
          name="twitter:description"
          content="Experience the magic of Flavorful Frames, where taste meets memories. Browse our website to find the perfect frames to add a touch of flavor to your life .
"
        />
        <meta
          name="twitter:image"
          content="https://delhiwalesardarji.com/static/media/Corporate-Events.35746114951c17c10502.jpg"
        />
      </Helmet>
      <DesignTwoNavbar />
      <DesignTowCateringInner />
      <Footer />
    </div>
  );
}
