import "./DesignTowOurStoryPageSection.css";
import abtone from "../img/about/about-us-1.jpg";
import abttwo from "../img/about/about-inner.jpeg";
import abticonone from "../img/about/Online-Ordering.png";
import abticontwo from "../img/about/Bespoke-Catering.png";
import DesignTwoCatering from "../DesignTwoCatering/DesignTwoCatering";
import GallaryDesignTwo from "../DesignTwoImageGallery/GallaryDesignTwo";
export default function DesignTowOurStoryPageSection() {
  return (
    <>
      <div className="page-header our-story-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header-box">
                <h1 className="text-anime">Our Story</h1>
                <nav>
                  <ol
                    className="breadcrumb wow fadeInUp"
                    data-wow-delay="0.50s">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Our Story</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-about py-4 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="section-title">
                <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                  Our Story
                </h3>
                <h2 className="text-anime">
                  A Culinary delight where every dish tells a story
                </h2>
              </div>
            </div>
          </div>
          <div className="row flex-lg-row-reverse">
            <div className="col-lg-6 col-md-12">
              <div className="home-about-left">
                <div className="home-about-img w-100">
                  <figure className="reveal image-anime w-100">
                    <img src={abttwo} alt="" className="" />
                  </figure>
                </div>

                <div className="home-about-since">
                  <h3>Since</h3>
                  <h2>2018</h2>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="home-about-right">
                <div
                  className="home-about-content wow fadeInUp"
                  data-wow-delay="0.75s">
                  <p>
                    In the heart of Gurgaon, amidst the hustle and bustle of
                    city life, there exists a haven of flavors, tradition, and
                    heartfelt hospitality – Delhi Wale Sardar Ji. Founded in
                    2018 by the visionary Jassjeet Singh Chadha, Delhi Wale
                    Sardar Ji isn't just a restaurant; it's a labor of love, a
                    culinary sanctuary where every dish tells a story and every
                    guest is family.
                  </p>
                  <p>
                    As the aroma of spices fills the air and the sizzle of
                    tandoors beckons, one can't help but feel the warmth and
                    passion that infuse every corner of Delhi Wale Sardar Ji.
                    For Jassjeet, cooking isn't just a profession; it's a
                    calling, a way to connect with his roots, and to share the
                    flavors of his heritage with the world.
                  </p>
                  <p>
                    From the humble beginnings of their first outlet to the
                    established setup they've become in 2024, Delhi Wale Sardar
                    Ji has stayed true to its roots, embracing the values of
                    precision and taste with every dish they serve. Their
                    commitment to authenticity is evident in every bite, as they
                    meticulously prepare their own masalas, ensuring that each
                    flavor is perfectly balanced and every dish is a symphony of
                    taste.
                  </p>
                  <p>
                    But Delhi Wale Sardar Ji is more than just a restaurant;
                    it's a place where memories are made, where laughter echoes
                    off the walls, and where strangers become friends over
                    shared plates of goodness. It's a place where the simple act
                    of breaking bread becomes a celebration of life, and where
                    every meal is an opportunity to connect with the flavors of
                    tradition.
                  </p>
                  <p>
                    As they stand on the cusp of a new chapter, with plans to
                    share their legacy through franchising, the heart and soul
                    of Delhi Wale Sardar Ji remain unchanged. For Jassjeet and
                    his team, it's not just about serving food; it's about
                    serving love, one plate at a time.
                  </p>
                </div>

                {/* <div className="about-icon-box">
                  <div className="icon-box wow fadeInUp" data-wow-delay="1.00s">
                    <div className="icon-box-img">
                      <img src={abticonone} alt="" className="img-fluid" />
                    </div>
                    <div className="icon-box-content">
                      <h3>Online Ordering</h3>
                      <p>
                        Order your favorite food online - Enjoy the ease of a
                        few clicks and savor every flavor from the comfort of
                        your home.
                      </p>
                    </div>
                  </div>

                  <div className="icon-box wow fadeInUp" data-wow-delay="1.25s">
                    <div className="icon-box-img">
                      <img src={abticontwo} alt="" className="img-fluid" />
                    </div>
                    <div className="icon-box-content">
                      <h3>Bespoke Catering</h3>
                      <p>
                        Good food is the foundation of genuine happiness. Let us
                        cater to your event, and we'll serve up smiles with
                        every bite.
                      </p>
                    </div>
                  </div>
                </div> */}

                {/* <a href="#" className="btn-default wow fadeInUp" data-wow-delay="1.50s">Order Online</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DesignTwoCatering /> */}
      {/* <GallaryDesignTwo /> */}
    </>
  );
}
