import "./DesignTwoOurStory.css";
import abtone from "../img/about/about-us-1.jpg";
import abttwo from "../img/about/about-us-2.jpg";
import abticonone from "../img/about/Online-Ordering.png";
import abticontwo from "../img/about/Bespoke-Catering.png";
export default function DesignTwoOurStory() {
  return (
    <div className="home-about" id="home-about">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="home-about-left">
              <div className="home-about-img">
                <figure className="reveal image-anime">
                  <img src={abtone} alt="" />
                </figure>

                <figure className="reveal image-anime">
                  <img src={abttwo} alt="" />
                </figure>
              </div>

              <div className="home-about-since">
                <h3>Since</h3>
                <h2>2018</h2>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="home-about-right">
              <div className="section-title">
                <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                  Our Story
                </h3>
                <h2 className="text-anime">
                  A Culinary delight where every dish tells a story
                </h2>
              </div>

              <div
                className="home-about-content wow fadeInUp"
                data-wow-delay="0.75s">
                <p>
                  In the heart of Gurgaon, amidst the hustle and bustle of city
                  life, there exists a haven of flavors, tradition, and
                  heartfelt hospitality – Delhi Wale Sardar Ji. Founded in 2018
                  by the visionary Jassjeet Singh Chadha, Delhi Wale Sardar Ji
                  isn't just a restaurant; it's a labor of love, a culinary
                  sanctuary where every dish tells a story and every guest is
                  family.
                </p>
              </div>

              <div className="about-icon-box">
                <div className="icon-box wow fadeInUp" data-wow-delay="1.00s">
                  <div className="icon-box-img">
                    <img src={abticonone} alt="" className="img-fluid" />
                  </div>
                  <div className="icon-box-content">
                    <h3>Online Ordering</h3>
                    <p>
                      Order your favorite food online - Enjoy the ease of a few
                      clicks and savor every flavor from the comfort of your
                      home.
                    </p>
                  </div>
                </div>

                <div className="icon-box wow fadeInUp" data-wow-delay="1.25s">
                  <div className="icon-box-img">
                    <img src={abticontwo} alt="" className="img-fluid" />
                  </div>
                  <div className="icon-box-content">
                    <h3>Bespoke Catering</h3>
                    <p>
                      Good food is the foundation of genuine happiness. Let us
                      cater to your event, and we'll serve up smiles with every
                      bite.
                    </p>
                  </div>
                </div>
              </div>

              {/* <a href="#" className="btn-default wow fadeInUp" data-wow-delay="1.50s">Order Online</a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
