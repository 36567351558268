import "./DesignTowCateringInner.css";
import CorporateEvents from "../img/catering/Corporate-Events.jpg";
import InstitutionalCatering from "../img/catering/Institutional-Catering.jpg";
import WeddingCatering from "../img/catering/wedding-catering.jpg";
import PrivateParty from "../img/catering/private-party.jpg";
export default function DesignTowCateringInner() {
  return (
    <>
      <div className="page-header catering-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header-box">
                <h1 className="text-anime">Catering</h1>
                <nav>
                  <ol
                    className="breadcrumb wow fadeInUp"
                    data-wow-delay="0.50s">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Catering</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="franchise-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                  Catering
                </h3>
                <h2 className="text-anime">
                  Let’s make your event extra special...!
                </h2>
                <p>
                  The commitment to spreading joy through food extends beyond
                  their restaurant walls. Their catering services bring the
                  flavors of tradition and the warmth of heartfelt hospitality
                  to one’s special events, whether it's weddings, corporate
                  gatherings, or family celebrations. With their meticulously
                  prepared dishes, crafted with love and precision, they ensure
                  that every bite leaves a lasting impression on one’s guests.
                </p>
              </div>
            </div>
          </div>
          <div className="row catering-inner-card">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="catering-inner-img">
                <img src={CorporateEvents} />
              </div>
            </div>
            <div className="col-lg-6">
              <h3>Corporate Events</h3>
              <p>
                We specialize
                in providing exceptional catering for corporate events, offering
                a diverse menu crafted with the finest ingredients and tailored
                to meet your specific preferences. Our professional team ensures
                impeccable service, flexibility to accommodate any changes, and
                meticulous attention to detail, making your event a memorable
                success.
              </p>
              <ul>
                <li>Breakfast Meetings</li>
                <li>Luncheons</li>
                <li>Cocktail Receptions</li>
                <li>Corporate Dinners</li>
                <li>Office Parties</li>
                <li>Seminars and Conferences</li>
                <li>Team Building Events</li>
              </ul>
              <p>
                Contact us today to elevate your corporate gatherings with
                exquisite cuisine and outstanding service.
              </p>
            </div>
          </div>
          <div className="row flex-lg-row-reverse mt-4 mt-lg-5 catering-inner-card">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="catering-inner-img">
                <img src={WeddingCatering} />
              </div>
            </div>
            <div className="col-lg-6">
              <h3>Wedding Catering</h3>
              <p>
                Delhi Wale Sardar Ji Catering Services brings
                expertise to your special day With our wedding catering
                services. We promise to add an extra touch of magic to your
                celebrations. Our team of skilled chefs crafts exquisite menus
                that blend traditional flavors with contemporary twists,
                ensuring a culinary experience that delights the senses.
              </p>
              <p>
                Here are some key points to cover in wedding catering content:
              </p>
              <ul>
                <li>Menu Selection</li>
                <li>Tasting Tips</li>
                <li>Service Styles</li>
                <li>Specialty Catering Options</li>
                <li>Beverage Packages</li>
                <li>Customization and Personalization</li>
                <li>Budget Management</li>
                <li>Food Presentation</li>
              </ul>
            </div>
          </div>
          <div className="row catering-inner-card mt-4 mt-lg-5">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="catering-inner-img">
                <img src={PrivateParty} />
              </div>
            </div>
            <div className="col-lg-6">
              <h3>Private Parties</h3>
              <p>
                We blend the rich culinary traditions of Delhi
                with the warmth of Punjabi hospitality to make your private
                events truly unforgettable. Whether you’re planning an intimate
                gathering or a lavish celebration, we cater to all your needs
                with a delightful array of both vegetarian and non-vegetarian
                cuisines.
              </p>
              <p>We make your private parties memorable:</p>
              <ul>
                <li>Birthday Parties</li>
                <li>Anniversary Celebrations</li>
                <li>Engagement Parties</li>
                <li>Graduation Parties</li>
                <li>Housewarming Parties</li>
                <li>Holiday Gatherings</li>
                <li>Retirement Parties</li>
                <li>Baby Showers</li>
                <li>Promotion Celebrations</li>
                <li>Reunions</li>
              </ul>
            </div>
          </div>
          <div className="row flex-lg-row-reverse mt-4 mt-lg-5 catering-inner-card">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="catering-inner-img">
                <img src={InstitutionalCatering} />
              </div>
            </div>
            <div className="col-lg-6">
              <h3>Institutional Catering</h3>
              <p>
                We bring the vibrant flavors of Delhi
                and Punjab to institutions, ensuring that students, faculty, and
                staff enjoy delicious and nutritious meals every day. Our
                institution catering service is designed to provide wholesome
                and balanced meals, promoting health and well-being within
                educational and corporate settings. We understand that every
                institution is unique, which is why we offer customized catering
                solutions tailored to specific needs and preferences. Whether
                it’s dietary requirements, budget constraints, or special
                events, we work closely with clients to create personalized
                menus and services.
              </p>
              <p>We cater to different institutions:</p>
              <ul>
                <li>School Catering</li>
                <li>University Catering</li>
                <li>Corporate Catering</li>
                <li>Hospital and Healthcare Catering
                </li>
                <li>Government and Institutional Catering</li>
                <li>Religious Institution Catering</li>
                <li>Non-Profit Organization Catering</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
