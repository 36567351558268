import React from 'react'
import { Col, Row } from 'react-bootstrap';

function RestaurantStatus() {
  return (
    <Col lg={12} className="px-0">
      <div className="restaurant-workhours close">
        <h6 className="mb-0">Sorry! We are CLOSED</h6>
      </div>
    </Col>
  );
}

export default RestaurantStatus