import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import "./App.css";
// import Footer from "./pages/Masala-of-india/Footer/Footer";
// import Navbar from "./components/Navbar/Navbar";
// import CommonHome from "./pages/Masala-of-india/CommonHome/CommonHome";
import CustomerDashboard from "./components/OnlineOrdering/CustomerDashboard/CustomerDashboard";
import Home from "./components/OnlineOrdering/Home/Home";
import PaymentSuccess from "./components/OnlineOrdering/Payment/PaymentSuccess";
import RecipeDetail from "./components/OnlineOrdering/RecipeDetail/RecipeDetail";
// import Privacy from "../src/pages/Masala-of-india/StaticPages/Privacy";
import PageNotFound from "./Error/PageNotFound";
// import TermsCondition from "./pages/Masala-of-india/StaticPages/TermsCondition";
// import Disclaimer from "./pages/Masala-of-india/StaticPages/Disclaimer";
// import ComingSoon from "./pages/ComingSoon/ComingSoon";
import { useContext, useEffect } from "react";
import PostApiCall from "./components/OnlineOrdering/helper/PostAPI";
import { store } from "./pages/context/store";
import CancellationRefundPolicyPage from "./pages/Design-Two/CancellationRefundPolicyPage";
import DesignTowCateringPage from "./pages/Design-Two/DesignTowCateringPage";
import DesignTowContactPage from "./pages/Design-Two/DesignTowContactPage";
import DesignTowFranchisePage from "./pages/Design-Two/DesignTowFranchisePage";
import DesignTowGalleryPage from "./pages/Design-Two/DesignTowGalleryPage";
import DesignTowOurStoryPage from "./pages/Design-Two/DesignTowOurStoryPage";
import DesignTowTermsConditionPage from "./pages/Design-Two/DesignTowTermsConditionPage";
import DesignTwoHome from "./pages/Design-Two/DesignTwoHome";
import DisclaimerPage from "./pages/Design-Two/DisclaimerPage";
import PrivacyPage from "./pages/Design-Two/PrivacyPage";
import ShippingDeliveryTermsPage from "./pages/Design-Two/ShippingDeliveryTermsPage";

function App() {
  const { setClientDetails, setRestaurantOpen, setFssai,
    setRestaurantAddress, setRestaurantTime, setRestaurantName, setCurrencyCode,
    setCustomerValidation, setClientLogo, setClientCredentials } = useContext(store)
  useEffect(() => {
    PostApiCall.postRequest(
      {
        id: "",
      },
      "getClientData"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setClientDetails(obj1.message);
          setClientCredentials(obj1.message1);
          setRestaurantOpen(obj1.message[0]?.restaurant_open);
          setFssai(obj1.message[0].fld_fssai_number);
          setRestaurantAddress(
            obj1.message[0].fld_address +
            ", " +
            obj1.message[0].city_name +
            ", " +
            obj1.message[0].state_name
          );
          setRestaurantTime(obj1.message[0].res_timings);
          setRestaurantName(obj1.message[0].fld_restaurantname);
          setCurrencyCode(obj1.message[0].fld_currency);
          setCustomerValidation(obj1.message[0].fld_customer_validation_using);
          setClientLogo(obj1.message[0]?.fld_image);
        }
      })
    );
  }, [])
  return (
    <div className="container-fluid main-route-box p-0 m-0">
      {/* {window.location.pathname.split("/")[1] != "" ? <Navbar /> : <></>} */}
      <Routes>
        {/* <Route exact path="/" element={<CommonHome />}></Route> */}
        <Route exact path="/" element={<DesignTwoHome />}></Route>
        {/* <Route exact path="/" element={<ComingSoon />}></Route> */}
        <Route exact path="/recipe-detail" element={<RecipeDetail />}></Route>
        <Route exact path="/dashboard" element={<CustomerDashboard />}></Route>
        <Route exact path="/payment-successful" element={<PaymentSuccess />}></Route>
        <Route exact path="/order-online" element={<Home />}></Route>
        <Route exact path="/privacy" element={<PrivacyPage />}></Route>
        <Route exact path="/terms-condition" element={<DesignTowTermsConditionPage />}></Route>
        <Route exact path="/cancellation-refund-policy" element={<CancellationRefundPolicyPage />}></Route>
        <Route exact path="/shipping-delivery-terms" element={<ShippingDeliveryTermsPage />}></Route>
        <Route exact path="/disclaimer" element={<DisclaimerPage />}></Route>
        <Route exact path="/contact-us" element={<DesignTowContactPage />}></Route>
        <Route exact path="/franchise" element={<DesignTowFranchisePage />}></Route>
        <Route exact path="/catering" element={<DesignTowCateringPage />}></Route>
        <Route exact path="/gallery" element={<DesignTowGalleryPage />}></Route>
        <Route exact path="/our-story" element={<DesignTowOurStoryPage />}></Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* <div className="d-lg-block d-md-block d-sm-none d-none">
        <Footer />
      </div> */}
    </div>
  );
}

export default App;
