import "./DesignTwoHome.css";
// import DesignTowContactUs from "./DesignTowContactUs/DesignTowContactUs";
import DesignTwoNavbar from "./NavBar/DesignTwoNavbar";
import Footer from "../../components/Footer/Footer";
// import DesignTowFranchise from "./DesignTowFranchise/DesignTowFranchise";
import { useContext } from "react";
import { store } from "../context/store";
import { Helmet } from "react-helmet";
export default function DesignTowTermsConditionPage() {
  const { keyStrings } = useContext(store);
  return (
    <>
     <Helmet>
        <title>
        Delhi Wale Sardar Ji: Terms and Conditions Explained
        </title>
        <meta
          name="description"
          content="Find out everything you need to know about our website's Terms and Conditions. Stay informed and make informed decisions."
        />
        <meta
          property="og:title"
          content="Delhi Wale Sardar Ji: Terms and Conditions Explained"
        />
        <meta
          property="og:description"
          content="Find out everything you need to know about our website's Terms and Conditions. Stay informed and make informed decisions."
        />
        <meta name="keywords" content="Terms and Conditions, delivery, takeout, order food online, menu, food delivery, online ordering system, delivery service in Gurugram" />

        <meta
          property="og:url"
          content="https://delhiwalesardarji.com/terms-condition"
        />
        <link
          rel="canonical"
          href="https://delhiwalesardarji.com/terms-condition"
        />
        <meta property="og:image" content="https://delhiwalesardarji.com/static/media/page-header-bg.11cf1697dcba0a0e7061.jpg" />
        <meta
          name="twitter:title"
          content="Delhi Wale Sardar Ji: Terms and Conditions Explained"
        />
        <meta
          name="twitter:description"
          content="Find out everything you need to know about our website's Terms and Conditions. Stay informed and make informed decisions.
"
        />
        <meta name="twitter:image" content="https://delhiwalesardarji.com/static/media/page-header-bg.11cf1697dcba0a0e7061.jpg" />
      </Helmet>
    <div className="design-two">
      
      <DesignTwoNavbar />
      <div className="page-header terms-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header-box">
                <h1 className="text-anime">Terms and Conditions</h1>
                {/* <nav>
                  <ol className="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Terms and Conditions</li>
                  </ol>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="section-title">
                <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                Terms and Conditions
                </h3>
                <h2 className="text-anime">Terms and Conditions</h2>
              </div> */}
              {/* <p
                dangerouslySetInnerHTML={{
                  __html:
                    keyStrings?.filter(
                      (item) => item.FLD_Key_Name == "TERMS_AND_CONDITIONS"
                    )[0] != null
                      ? keyStrings?.filter(
                        (item) =>
                          item.FLD_Key_Name == "TERMS_AND_CONDITIONS"
                      )[0].FLD_Key_Value
                      : "",
                }}></p> */}
              {/* <p>Duis nec semper ligula. Nullam nec justo vel metus gravida consequat. Suspendisse potenti. Quisque fermentum, nisl vitae auctor commodo, justo metus tincidunt elit,</p> */}
              {/* <ul className="terms-content">
                <li>
                  <span>1.</span> Acceptance of Terms By accessing or using the
                  services provided by Delhi Wale SardarJi Restaurant, you agree
                  to be bound by these Terms and Conditions. If you do not agree
                  with any part of these terms, you may not access the services.{" "}
                </li>{" "}
                <li>
                  <span>2.</span> Service Description Delhi Wale SardarJi
                  Restaurant provides dining services, including food and
                  beverage orders, catering, and event hosting, either through
                  our physical location or through our online platforms.
                </li>{" "}
                <li>
                  <span>3.</span> User Responsibilities You agree to use the
                  services provided by Delhi Wale SardarJi Restaurant for lawful
                  purposes only. You shall not engage in any conduct that
                  violates any applicable laws or regulations.
                </li>{" "}
                <li>
                  <span> 4.</span> Orders and Payments By placing an order with
                  Delhi Wale SardarJi Restaurant, you agree to pay all charges
                  associated with the order, including the cost of food,
                  beverages, taxes, and any applicable delivery fees.{" "}
                </li>{" "}
                <li>
                  <span>5.</span> Reservation of Rights Delhi Wale SardarJi
                  Restaurant reserves the right to refuse service to anyone for
                  any reason at any time. We also reserve the right to modify or
                  discontinue any aspect of the services without notice at any
                  time.
                </li>
                <li>
                  {" "}
                  <span>6.</span> Intellectual Property All content included on
                  the Delhi Wale Sardarji Restaurant website, including but not
                  limited to text, graphics, logos, images, and software, is the
                  property of Delhi Wale Sardarji Restaurant and is protected by
                  copyright and other laws.
                </li>
                <li>
                  {" "}
                  <span>7.</span> Limitation of Liability In no event shall
                  Delhi Wale Sardarji Restaurant, its chefs, cooks, dishwashers,
                  server, Manager or affiliates be liable for any direct,
                  indirect, incidental, special, or consequential damages
                  arising out of or in any way connected with the use of our
                  services.
                </li>
                <li>
                  {" "}
                  <span>8.</span> Indemnification You agree to indemnify and
                  hold harmless Delhi Wale Sardarji Restaurant, its chefs,
                  cooks, dishwashers, server, Manager or affiliates from and
                  against any and all claims, damages, obligations, losses,
                  liabilities, costs, or debt, and expenses arising from your
                  use of the services or your violation of these Terms and
                  Conditions.
                </li>
                <li>
                  {" "}
                  <span>9.</span> Governing Law These Terms and Conditions shall
                  be governed by and construed in accordance with the laws of
                  India, without regard to its conflict of law provisions. The
                  place of jurisdiction shall be exclusively in Delhi.
                </li>
                <li>
                  {" "}
                  <span>10.</span> Changes to Terms and Conditions Delhi Wale
                  SardarJi Restaurant reserves the right to update or modify
                  these Terms and Conditions at any time without prior notice.
                  Your continued use of the services after any changes to these
                  terms shall constitute your acceptance of such changes. By
                  accessing or using the services provided by Delhi Wale
                  SardarJi Restaurant, you acknowledge that you have read,
                  understood, and agree to be bound by these Terms and
                  Conditions. If you do not agree to these terms, please do not
                  access or use our services. For any questions or concerns
                  regarding these Terms and Conditions, please contact us at +91
                  98188 98363.
                </li>
              </ul> */}
              <b>1. Acceptance of Terms</b>
              <p>
                By accessing or using the services provided by Delhi Wale
                SardarJi Restaurant, you agree to be bound by these Terms and
                Conditions. If you do not agree with any part of these terms,
                you may not access the services.
              </p>
              <b>2. Service Description</b>
              <p>
                Delhi Wale SardarJi Restaurant provides dining services,
                including food and beverage orders, catering, and event hosting,
                either through our physical location or through our online
                platforms.
              </p>
              <b>3. User Responsibilities</b>
              <p>
                You agree to use the services provided by Delhi Wale SardarJi
                Restaurant for lawful purposes only. You shall not engage in any
                conduct that violates any applicable laws or regulations.
              </p>
              <b>4. Orders and Payments</b>
              <p>
                By placing an order with Delhi Wale SardarJi Restaurant, you
                agree to pay all charges associated with the order, including
                the cost of food, beverages, taxes, and any applicable delivery
                fees.
              </p>
              <b>5. Reservation of Rights</b>
              <p>
                Delhi Wale SardarJi Restaurant reserves the right to refuse
                service to anyone for any reason at any time. We also reserve
                the right to modify or discontinue any aspect of the services
                without notice at any time.
              </p>
              <b>6. Intellectual Property</b>
              <p>
                All content included on the Delhi Wale Sardarji Restaurant
                website, including but not limited to text, graphics, logos,
                images, and software, is the property of Delhi Wale Sardarji
                Restaurant and is protected by copyright and other laws.
              </p>
              <b>7. Limitation of Liability</b>
              <p>
                In no event shall Delhi Wale Sardarji Restaurant, its chefs,
                cooks, dishwashers, server, Manager or affiliates be liable for
                any direct, indirect, incidental, special, or consequential
                damages arising out of or in any way connected with the use of
                our services.
              </p>
              <b>8. Indemnification</b>
              <p>
                You agree to indemnify and hold harmless Delhi Wale Sardarji
                Restaurant, its chefs, cooks, dishwashers, server, Manager or
                affiliates from and against any and all claims, damages,
                obligations, losses, liabilities, costs, or debt, and expenses
                arising from your use of the services or your violation of these
                Terms and Conditions.
              </p>
              <b>9. Governing Law</b>
              <p>
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of India, without regard to its
                conflict of law provisions. The place of jurisdiction shall be
                exclusively in Delhi.
              </p>
              <b>10. Changes to Terms and Conditions</b>
              <p>
                Delhi Wale SardarJi Restaurant reserves the right to update or
                modify these Terms and Conditions at any time without prior
                notice. Your continued use of the services after any changes to
                these terms shall constitute your acceptance of such changes.
              </p>
              <p>
                By accessing or using the services provided by Delhi Wale
                SardarJi Restaurant, you acknowledge that you have read,
                understood, and agree to be bound by these Terms and Conditions.
                If you do not agree to these terms, please do not access or use
                our services.
              </p>
              <p>
                For any questions or concerns regarding these Terms and
                Conditions, please contact us at <b>+91 98188 98363</b>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
}
