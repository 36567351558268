import "./DesignTwoHome.css";
import DesignTowContactUs from "./DesignTowContactUs/DesignTowContactUs";
import DesignTwoNavbar from "./NavBar/DesignTwoNavbar";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
export default function DesignTowContactPage() {
  return (
    <div className="design-two">
       <Helmet>
        <title>Get in Touch with Delhi Wale Sardarji: Contact Us Today</title>
        <meta
          name="description"
          content="Contact the prestigious Delhi Wale Sardar Ji effortlessly through our contact us page for all your queries and partnerships."
        />
        <meta
          property="og:title"
          content="Get in Touch with Delhi Wale Sardarji: Contact Us Today"
        />
        <meta
          property="og:description"
          content="Contact the prestigious Delhi Wale Sardar Ji effortlessly through our contact us page for all your queries and partnerships."
        />
        <meta name="keywords" content="Contact Us, Delhi Wale Sardar Ji, Gurugram" />
        <meta property="og:url" content="https://delhiwalesardarji.com/contact-us" />
        <link rel="canonical" href="https://delhiwalesardarji.com/contact-us" />
        <meta
          property="og:image"
          content=""
        />
        <meta
          name="twitter:title"
          content="Get in Touch with Delhi Wale Sardarji: Contact Us Today"
        />
        <meta
          name="twitter:description"
          content="Contact the prestigious Delhi Wale Sardar Ji effortlessly through our contact us page for all your queries and partnerships.
"
        />
        <meta
          name="twitter:image"
          content=""
        />
      </Helmet>
      <DesignTwoNavbar />
      <DesignTowContactUs />
      <Footer />
    </div>
  );
}
