import "./DesignTwoHome.css";
import DesignTowContactUs from "./DesignTowContactUs/DesignTowContactUs";
import DesignTwoNavbar from "./NavBar/DesignTwoNavbar";
import Footer from "../../components/Footer/Footer";
import DesignTowFranchise from "./DesignTowFranchise/DesignTowFranchise";
import DesignTowCateringInner from "./DesignTowCateringInner/DesignTowCateringInner";
import DesignTowOurStoryPageSection from "./DesignTowOurStoryPageSection/DesignTowOurStoryPageSection";
import { Helmet } from "react-helmet";
export default function DesignTowOurStoryPage() {
  return (
    <div className="design-two">
      <Helmet>
        <title>
          Discover Our Story: Exploring the Flavors of Delhi Wale Sardarji
        </title>
        <meta
          name="description"
          content="Discover the story of Delhi Wale Sardar Ji, your go-to destination for authentic Punjabi cuisine. Experience flavors that bring Punjab closer to you."
        />
        <meta
          property="og:title"
          content="Discover Our Story: Exploring the Flavors of Delhi Wale Sardarji"
        />
        <meta
          property="og:description"
          content="Discover the story of Delhi Wale Sardar Ji, your go-to destination for authentic Punjabi cuisine. Experience flavors that bring Punjab closer to you."
        />
        <meta name="keywords" content="Our Story, Delhi Wale Sardar Ji, Gurugram" />

        <meta
          property="og:url"
          content="https://delhiwalesardarji.com/our-story"
        />
        <link rel="canonical" href="https://delhiwalesardarji.com/our-story" />
        <meta property="og:image" content="https://delhiwalesardarji.com/static/media/about-inner.9d6415d1efc284aa30a7.jpeg" />
        <meta
          name="twitter:title"
          content="Discover Our Story: Exploring the Flavors of Delhi Wale Sardarji"
        />
        <meta
          name="twitter:description"
          content="Discover the story of Delhi Wale Sardar Ji, your go-to destination for authentic Punjabi cuisine. Experience flavors that bring Punjab closer to you."
        />
        <meta name="twitter:image" content="https://delhiwalesardarji.com/static/media/about-inner.9d6415d1efc284aa30a7.jpeg" />
      </Helmet>
      <DesignTwoNavbar />
      <DesignTowOurStoryPageSection />
      <Footer />
    </div>
  );
}
