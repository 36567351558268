import "animate.css";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Offcanvas,
  ProgressBar,
  Row,
  Toast
} from "react-bootstrap";

import Cookies from "js-cookie";
import { sha512 } from "js-sha512";
import moment from "moment";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FaMinus, FaPepperHot, FaPlus, FaSearch } from "react-icons/fa";
import { GiPlainCircle } from "react-icons/gi";
import { LuVegan } from "react-icons/lu";
import { RiEBike2Line } from "react-icons/ri";
import { TbInfoSquare, TbPaperBag } from "react-icons/tb";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import Config from "../../../components/OnlineOrdering/Configuration/Config";
import CopyConfig from "../../../components/OnlineOrdering/Configuration/CopyConfig";
import CartItems from "../../../components/OnlineOrdering/RecipeDetail/CartItems";
import FoodDetails from "../../../components/OnlineOrdering/RecipeDetail/RecipeDetail";
import { store } from "../../../pages/context/store";
import Footer from "../../Footer/Footer";
import PostApiCall from "../helper/PostAPI";
import QuickSelection from "../QuickSelection/QuickSelection";
import "./Home.css";

import { Drawer, Select, Skeleton, Space } from "antd";
import axios from "axios";
import { Helmet } from "react-helmet";
import { BsFilterSquareFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import ChiliImage1 from "../../../assets/img/ChiliImages/1.svg";
import ChiliImage2 from "../../../assets/img/ChiliImages/2.svg";
import ChiliImage3 from "../../../assets/img/ChiliImages/3.svg";
import ChiliImage4 from "../../../assets/img/ChiliImages/4.svg";
import ChiliImage5 from "../../../assets/img/ChiliImages/5.svg";
import DesignTwoNavbar from "../../../pages/Design-Two/NavBar/DesignTwoNavbar";
import useGeoLocation from "../CustomHooks/useGeoLocation";
import GetApiCall from "../helper/GetApi";

const Home = () => {
  let count = 0;
  const scrollRef = useRef(null);
  const [scroll, setScroll] = useState(false);
  const [offerList, setOfferList] = useState([]);
  const {
    setSelectedAddons,
    restaurantOpen,
    showDetails,
    setShowDetails,
    setCurrentItemConfig,
    showCartItemsDetails,
    setShowCartItemsDetails,
    setItemsToDelete,
    allcuisines,
    setallcuisines,
    menuListItems,
    setmenuListItems,
    addToCartDisbabled,
    setAddToCartDisabled,
    clientLogo,
    setActiveTab,
    scrollTodiv,
    setScrollTodiv,
    bannerDetails,
    setCurrentLocation
  } = useContext(store);
  // const [showFullDescription, setFullDescription] = useState(false);
  const { currencyCode } = useContext(store);
  const { setCartTotal } = useContext(store);
  const { setCartItems } = useContext(store);
  // const { keyStrings, setKeyStrings } = useContext(store);
  const { cuisines, setcuisines } = useContext(store);
  const { itemId, setItemId } = useContext(store);
  const { setItemPrice } = useContext(store);
  const [categoryId, setCategoryId] = useState(null);
  const [selectSpiceModal, setselectSpiceModal] = useState(false);
  const { setitemSpiceLevel } = useContext(store);
  const [spiceLevel, setSpiceLevel] = useState(null);
  const [itemConfigDetails, setItemConfigDetails] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const { cartData, setCartData } = useContext(store);
  const { setSelectedProperties } = useContext(store);
  // discount
  const [discountAmount, setdiscountAmount] = useState(null);
  // total
  const [totalAmount, settotalAmount] = useState(0.0);
  //add on group
  const { addOnGroup, setAddOnGroup } = useContext(store);
  const { setAddOnList } = useContext(store);
  //add property
  const { property, setProperty } = useContext(store);
  const [loader, setLoader] = useState(false);

  // Modal for spice level
  const [checkedData, setCheckedData] = useState("");
  const { specialInstruction, setSpecialInstruction } = useContext(store);
  const [AddOnData, setAddOnData] = useState([]);
  const [customizable, setCustomizable] = useState(null);
  const [showSpiceLevel, setShowSpiceLevel] = useState(false);
  const [consent, setConsent] = useState(false);
  const [filterTypes, setfilterTypes] = useState([]);
  const handleCloseSpiceLevel = () => setShowSpiceLevel(false);

  const [spicelevelData] = useState([
    {
      label: "Mild",
      value: "1",
      img: ChiliImage1,
    },
    {
      label: "Medium",
      value: "2",
      img: ChiliImage2,
    },
    {
      label: "Medium Hot",
      value: "3",
      img: ChiliImage3,
    },
    {
      label: "Hot",
      value: "4",
      img: ChiliImage4,
    },
    {
      label: "Extra Hot",
      value: "5",
      img: ChiliImage5,
    },
  ]);

  const {
    setProductDetailBanner,
    setProductDetailIem,
    setProductDetailCuisines,
    setProductDetailDescription,
    setSelectedMappedProperty,
    setmapPostitionMarkerLat,
    setmapPostitionMarkerLang
  } = useContext(store);
  const { setPropertyList } = useContext(store);
  // add on price update
  const { updateCart, setupdateCart } = useContext(store);
  const { setQuantityAdd } = useContext(store);
  const { showCopyConfig, setShowCopyConfig } = useContext(store);

  // modal for config selection
  const { setSelectedItemId } = useContext(store);
  const { setSelectedCartLineId } = useContext(store);
  const [itemData, setitemData] = useState();
  const { searchText, setSearchText } = useContext(store);
  const [visible, setVisible] = useState({
    start: window.innerWidth < 992 && window.innerWidth > 568 ? 8 : 6,
    // start: 6,
    end: 12,
    clicked: "more",
  });
  const [productVisible, setProductVisible] = useState({
    start: 20,
    end: 12,
    clicked: "more",
  });
  const [showRating, setShowRating] = useState([]);
  const toggleShowRating = (index) => {
    const newShow = [...showRating];
    newShow[index] = !newShow[index];
    setShowRating(newShow);
  };

  const { showLoginModal, setShowLoginModal } = useContext(store);
  const handleCloseLoginModal = () => setShowLoginModal(false);
  const handleShowLoginModal = () => setShowLoginModal(true);

  const [toggleOtp, setToggleOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(false);

  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerContact, setCustomerContact] = useState("");
  const [otp, setOtp] = useState(0);
  const [selectedItemName, setSelectedItemName] = useState("");
  const { setCustomerData } = useContext(store);
  const { setLoginDisabled, loginError, setLoginError } = useContext(store);
  const [filterOffcanvas, setFilterOffcanvas] = useState(false)
  useEffect(() => {
    if (window.pageYOffset > 300) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 100);
      });
    }
    PostApiCall.postRequest(
      {
        status: "Active",
      },
      "getOfferList"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setOfferList(obj.message);
        }
      })
    );
    getFilterTypeMaster();
  }, []);
  const windowScrollY = (id) => {
    setScrollTodiv(id);
  };
  useEffect(() => {
    if (scrollTodiv) {
      const targetDiv = document.getElementById(scrollTodiv);

      if (targetDiv) {
        const rect = targetDiv.getBoundingClientRect();
        const targetScrollTop = rect.top + window.pageYOffset - 100;

        const scrollStep = (targetScrollTop - window.pageYOffset) / 20; // Divide the scroll into 20 steps
        let currentScrollTop = window.pageYOffset;

        const smoothScroll = () => {
          if (
            (scrollStep > 0 && currentScrollTop < targetScrollTop) ||
            (scrollStep < 0 && currentScrollTop > targetScrollTop)
          ) {
            currentScrollTop += scrollStep;
            window.scrollTo({
              top: currentScrollTop,
              behavior: "auto", // Disable smooth scrolling for this operation
            });
            requestAnimationFrame(smoothScroll);
          } else {
            window.scrollTo({
              top: targetScrollTop,
              behavior: "smooth", // Re-enable smooth scrolling for final step
            });
            setScrollTodiv(null);
          }
        };

        smoothScroll();
      }
    }
  }, [scrollTodiv]);
  const getMenuData = (message) => {
    const removeFav = message;
    searchText != null
      ? setcuisines(
        removeFav.filter((product) =>
          product.search_field
            .toLowerCase()
            .includes(searchText?.toLowerCase())
        )
      )
      : setcuisines(removeFav);
    setallcuisines(message);
    setselectSpiceModal(false);
    setShowRating(Array(message.length).fill(false));
  };
  const getCart = async () => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = login ? JSON.parse(login)[0] : null;

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails.order_id,
        id:
          localStorage.getItem("LoginDetailsWeb") == null
            ? null
            : logindetails?.CustomerId,
        stateid: null,
      },
      "getCartWebsite"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          if (obj.message.length == 0) {
            localStorage.removeItem("OrderData");
            setCartData(obj.message);
            setdiscountAmount(0);
            settotalAmount(0);
            setCartTotal(0.0);
            setCartItems(0);
            setAddToCartDisabled(false);
          } else {
            setCartData(obj.message);
            setdiscountAmount(
              obj.message[0].fld_cart_discount_amount != null
                ? obj.message[0].fld_cart_discount_amount
                : 0
            );
            settotalAmount(
              obj.message[0].fld_cart_amount_no_shipping != null
                ? obj.message[0].fld_cart_amount_no_shipping
                : 0
            );
            setCartTotal(
              obj.message[0].fld_total_net_amount != null
                ? obj.message[0].fld_total_net_amount.toFixed(2)
                : 0
            );
            setCartItems(
              obj.message[0].item_count != null ? obj.message[0].item_count : 0
            );
            setAddToCartDisabled(false);
          }
          setCartData(obj.message);

          // getMenuList();
        }
      })
    );
  };

  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
        columns: "*",
      },
      "getCategoryDetails"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setmenuListItems(
            obj1.message.filter((dat) => dat.fld_enabled_flag === "Yes")
          );
          Notiflix.Loading.Remove();
        }
      })
    );
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
    getMenuList();
  }, []);

  const getDetails = (Cuisine) => {
    var addondata = null;
    var propertydata = null;
    setItemPrice(Number(Cuisine.FLD_Pricing_new).toFixed(2));
    setProductDetailBanner(Cuisine.FLD_Image);
    setProductDetailIem(Cuisine.FLD_Name);
    setProductDetailCuisines("Serves" + Cuisine.FLD_Service_Info);
    setProductDetailDescription(Cuisine.FLD_Description);
    setSelectedCartLineId(Cuisine.fld_cart_dtl_id);
    setItemId(Cuisine.FLD_Item_ID);
    PostApiCall.postRequest(
      {
        WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
        columns: ` distinct FLD_Addon_Group,FLD_Max_Addon,FLD_Max_Properties    `,
      },
      "getItemAddOnMaster"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status === 200 || result.status === 201) {
          if (obj.data.length > 0) {
            setAddOnGroup(obj.data);
            addondata = obj.data;
          } else {
            setAddOnGroup(null);
            addondata = null;
          }
          if (addOnGroup != null) {
            PostApiCall.postRequest(
              {
                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                columns: ` *  `,
              },
              "getItemAddOnMaster"
            ).then((result) =>
              result?.json().then((obj) => {
                if (result.status === 200 || result.status === 201) {
                  if (obj.data.length > 0) {
                    setAddOnList(obj.data);
                  } else {
                    if (Cuisine.total_item_in_cart > 0) {
                      setCurrentItemConfig(Cuisine.ConfigDetails);
                      // setShowCopyConfig(true);
                    } else {
                    }
                    setAddOnList(null);
                  }
                }
              })
            );
          } else {
          }
        }
      })
    );

    // property
    PostApiCall.postRequest(
      {
        WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
        columns: ` distinct FLD_Property_Name,FLD_Max_Addon,FLD_Max_Properties    `,
      },
      "GetItemProperty"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status === 200 || result.status === 201) {
          if (obj.data.length > 0) {
            setProperty(obj.data);
            propertydata = obj.data;
          } else {
            setProperty(null);
            propertydata = null;
          }

          if (propertydata != null) {
            PostApiCall.postRequest(
              {
                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                columns: ` *  `,
              },
              "GetItemProperty"
            ).then((result) =>
              result?.json().then((obj) => {
                if (result.status === 200 || result.status === 201) {
                  if (obj.data.length > 0) {
                    if (Cuisine.total_item_in_cart > 0) {
                      setCurrentItemConfig(Cuisine.ConfigDetails);
                      // setShowCopyConfig(true);
                    } else {
                    }
                    setPropertyList(obj.data);
                  } else {
                    setPropertyList(null);
                  }
                }
              })
            );

            setCategoryId(Cuisine.FLD_Cat_Id);
            setselectSpiceModal(true);
            setitemSpiceLevel(Cuisine.FLD_Spice_Level);
          } else {
            if (Cuisine.total_item_in_cart > 0) {
              setCurrentItemConfig(Cuisine.ConfigDetails);
              //setShowCopyConfig(true);
            }
          }
        }
      })
    );
  };

  const getMenuList = () => {
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        categoryid: categoryId,
        orderid:
          localStorage.getItem("OrderData") == null ? 0 : Orderdetails.order_id,
      },
      "getFoodMenuOrder"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setcuisines(obj1.message);
          setallcuisines(obj1.message);
          setselectSpiceModal(false);
          setShowRating(Array(obj1.message.length).fill(false));
          Notiflix.Loading.Remove();
        }
      })
    );
  };
  const handleCloseDetails = () => setShowDetails(false);
  const handleShowDetails = () => {
    setSelectedProperties([]);
    setSelectedMappedProperty(null);
    setShowDetails(true);
  };

  const handleCloseCartItemsDetails = () => {
    getCart();
    setShowCartItemsDetails(false);
  };
  const handleShowCartItemsDetails = () => setShowCartItemsDetails(true);
  const [active, setActive] = useState("All");
  const [showLoginScreen, setShowLoginScreen] = useState(true);
  const [showSignupScreen, setShowSignupScreen] = useState(false);
  const [guestLogin, setGuestLogin] = useState(false);

  function HideLoginScreen() {
    setShowLoginScreen(false);
    setShowSignupScreen(true);
    setForgotPassword(false);
  }

  function HideSignupScreen() {
    setShowLoginScreen(true);
    setShowSignupScreen(false);
  }
  const RemoveCoupon = () => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = login ? JSON.parse(login)[0] : null;
    PostApiCall.postRequest(
      {
        status: "unapply",
        couponid: cartData.length != 0 ? cartData[0].fld_coupon_id : "",
        orderid:
          localStorage.getItem("OrderData") == null
            ? 0
            : JSON.parse(localStorage.getItem("OrderData")).order_id,
        customerid:
          localStorage.getItem("LoginDetailsWeb") == null
            ? 1
            : logindetails?.CustomerId,
      },
      "ApplyOffer"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status === 200 || result.status === 201) {
          Notiflix.Notify.Success("Coupon removed.");
        } else {
          Notiflix.Notify.Failure(obj.message);
        }

        getCart();
      })
    );
  };

  const getFilterTypeMaster = () => {
    GetApiCall.getRequest("getFilterTypeMaster").then((result) =>
      result?.json().then((obj) => {
        if (result.status === 200 || result.status === 201) {
          setfilterTypes(obj.data)
        } else {
          Notiflix.Notify.Failure(obj.message);
        }

        getCart();
      })
    );
  }
  const location = useGeoLocation();
  useEffect(() => {
    showCurrentLocation()
  }, [location])
  function showCurrentLocation() {
    if (location.loaded && !location.error) {
      // setLat(location.coordinates.lat);
      // setLang(location.coordinates.lng);
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          location.coordinates.lat +
          "," +
          location.coordinates.lng +
          "&key=AIzaSyA4UpIpBjkSzH8T280zKffdTVdnt170Ke4"
        )
        .then((res) => {
          setCurrentLocation(res.data.results.filter(obj => obj.types.includes("sublocality"))[0]?.formatted_address)
          setmapPostitionMarkerLat(res.data.results[0].geometry.location.lat);
          setmapPostitionMarkerLang(res.data.results[0].geometry.location.lng);
        });
    } else {
      console.log("error")
      // alert(location.error.message);
    }
  }
  return (
    <div className="order-online-page">
      <Helmet>
        <title>
          Order Online from Delhi Wale Sardar Ji: Flavorful Delights at
          Your Doorstep
        </title>
        <meta
          name="description"
          content="Delhi's beloved Sardar Ji brings authentic Punjabi flavors to your doorstep. Order now for a taste of tradition and warmth!"
        />
        <meta
          property="og:title"
          content="Order Online from Delhi Wale Sardar Ji: Flavorful Delights at Your Doorstep"
        />
        <meta
          property="og:description"
          content="Delhi's beloved Sardar Ji brings authentic Punjabi flavors to your doorstep. Order now for a taste of tradition and warmth!"
        />
        <meta name="keywords" content="Online ordering, delivery, takeout, order food online, menu, food delivery, online ordering system, delivery service in Gurugram" />

        <meta
          property="og:url"
          content="https://delhiwalesardarji.com/order-online"
        />
        <link
          rel="canonical"
          href="https://delhiwalesardarji.com/order-online"
        />
        <meta property="og:image" content="https://delhiwalesardarji.com/static/media/delhi-wale-sardar-ji-logo.359e9418960628f05999.png" />
        <meta
          name="twitter:title"
          content="Order Online from Delhi Wale Sardar Ji: Flavorful Delights at Your Doorstep"
        />
        <meta
          name="twitter:description"
          content="Delhi's beloved Sardar Ji brings authentic Punjabi flavors to your doorstep. Order now for a taste of tradition and warmth!
"
        />
        <meta name="twitter:image" content="https://delhiwalesardarji.com/static/media/delhi-wale-sardar-ji-logo.359e9418960628f05999.png" />
      </Helmet>
      {/* <OrderTypeModal /> */}
      <div className="design-two">
        <DesignTwoNavbar />
      </div>
      {/* <section className="offer-banner mt-5">
        <Container fluid>
          <Row>
            <h2
              className="text-center pb-2"
              dangerouslySetInnerHTML={{
                __html:
                  keyStrings?.filter(
                    (item) => item.FLD_Key_Name == "HOME_PAGE_WELCOME_TO"
                  )[0] != null
                    ? keyStrings?.filter(
                      (item) => item.FLD_Key_Name == "HOME_PAGE_WELCOME_TO"
                    )[0].FLD_Key_Value +
                    " " +
                    restaurantName
                    : "<div></div>",
              }}
            ></h2>
            <p
              className="text-center mb-0"
              dangerouslySetInnerHTML={{
                __html:
                  keyStrings?.filter(
                    (item) => item.FLD_Key_Name == "HOME_PAGE_SUB_HEADING"
                  )[0] != null
                    ? keyStrings?.filter(
                      (item) => item.FLD_Key_Name == "HOME_PAGE_SUB_HEADING"
                    )[0].FLD_Key_Value
                    : "<div></div>",
              }}
            ></p>
            <p
              className="text-center pb-3"
              dangerouslySetInnerHTML={{
                __html:
                  keyStrings?.filter(
                    (item) =>
                      item.FLD_Key_Name == "HOME_PAGE_SUB_HEADING_LINE_2"
                  )[0] != null
                    ? keyStrings?.filter(
                      (item) =>
                        item.FLD_Key_Name == "HOME_PAGE_SUB_HEADING_LINE_2"
                    )[0].FLD_Key_Value
                    : "<div></div>",
              }}
            ></p>
          </Row>

          {bannerDetails != "undefined" && bannerDetails?.length != 0 ? (
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={offerBanners}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
            >
              {bannerDetails?.length != 0 ? (
                bannerDetails?.map((data) => {
                  return (
                    <div className="text-center mx-lg-1 mx-1">
                      <div className="offer-image-inner-banner">
                        <img alt={data.fld_alt} src={data.fld_banner_image} />
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </Carousel>
          ) : (
            ""
          )}
        </Container>
      </section> */}
      <QuickSelection />

      <section className={scroll ? "sticky-search-bar shadow-sm mt-4 py-2" : "mt-2"}>
        <Container fluid>
          <Card className={scroll ? "" : "shadow-search"}>
            <Card.Body className="py-0 pe-0">
              <Row className="align-items-center w-100 me-1">
                <Col
                  lg={6}
                  className="pb-3 pt-lg-3 pe-0 d-flex justify-content-between align-content-center gap-2">
                  <div className="flex-div position-relative">
                    {/* <Form> */}
                    {/* <FloatingLabel
                      controlId="floatingName"
                      label="Search for a cuisine or a dish"
                      className="mb-lg-0 mb-0 search-box"> */}
                    <Form.Control
                      type="text"
                      value={searchText}
                      onFocus={windowScrollY}
                      onChange={(e) => {
                        const removeFav = allcuisines;
                        setcuisines(
                          removeFav.filter((product) =>
                            product.search_field
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          )
                        );
                        setSearchText(e.target.value);
                      }}
                      placeholder="Search for a dish"
                    />
                    <FaSearch className="search-icon" />
                    {/* </FloatingLabel> */}
                    {/* </Form> */}
                  </div>
                </Col>
                <Col lg={6} className="pe-0">
                  <Row className="food-type-filter-row">
                    <button
                      className={`item-category-filter all ${active == "All" && "active"
                        }`}
                      onClick={() => {
                        setActive("All");
                        setcuisines(allcuisines);
                        setSearchText("");
                        if (window.innerWidth < 568) {
                          setFilterOffcanvas(true)
                        }
                      }}>
                      {/* All Items */}
                      <BsFilterSquareFill />
                    </button>
                    {filterTypes?.map((data) => {
                      return (
                        <>
                          <button
                            key={data.fld_id}
                            type="button"
                            className={`item-category-filter veg ${active == data.fld_filter_type ? 'active' : ''}`}
                            onClick={() => {
                              setActive(data.fld_filter_type);
                              const removeFav = allcuisines;
                              setcuisines(
                                removeFav.filter(
                                  (product) => product.FLD_Food_Type == data.fld_filter_type ||
                                    product.best_seller == data.fld_filter_type ||
                                    product.contains_egg == data.fld_filter_type
                                )
                              );
                              setSearchText("");
                            }}>
                            <img src={data.fld_image} className="recipe-type-image" />
                            {/* <GiPlainCircle /> */}
                            {data.fld_label}
                          </button>
                        </>
                      )
                    })}
                    <Space className="order-online-sort">
                      <Select
                        defaultValue="Sort by"
                        onChange={(value) => {
                          const sortedItems = allcuisines;

                          if (value === "Price, High to Low") {
                            sortedItems.sort(
                              (a, b) =>
                                b.FLD_Pricing_new -
                                a.FLD_Pricing_new
                            );
                          } else if (value === "Price, Low to High") {
                            sortedItems.sort(
                              (a, b) =>
                                a.FLD_Pricing_new -
                                b.FLD_Pricing_new
                            );
                          }
                          setcuisines(sortedItems)
                        }}
                        options={[
                          {
                            value: "Price, High to Low",
                            label: "Price, High to Low",
                          },
                          {
                            value: "Price, Low to High",
                            label: "Price, Low to High",
                          }
                        ]}
                      />
                    </Space>
                    {/* <button
                      type="button"
                      className={`item-category-filter veg ${active == "Vegetarian" && "active"
                        }`}
                      onClick={() => {
                        setActive("Vegetarian");
                        const removeFav = allcuisines;
                        setcuisines(
                          removeFav.filter(
                            (product) => product.FLD_Food_Type === "Vegetarian"
                          )
                        );
                        setSearchText("");
                      }}>
                      <img src={Vegetarian} className="recipe-type" />
                      <GiPlainCircle />
                      Veg
                    </button>
                    <button
                      type="button"
                      className={`item-category-filter nonveg ${active == "NonVegetarian" && "active"
                        }`}
                      onClick={() => {
                        setActive("NonVegetarian");
                        const removeFav = allcuisines;
                        setcuisines(
                          removeFav.filter(
                            (product) =>
                              product.FLD_Food_Type === "NonVegetarian"
                          )
                        );
                        setSearchText("");
                      }}>
                      <img src={NonVegetarian} className="recipe-type" />
                      <GiPlainCircle />
                      Non Veg
                    </button> */}
                    <button
                      style={{ display: count > 0 ? "block" : "none" }}
                      className={`item-category-filter vegan ${active == "Vegan" && "active"
                        }`}
                      onClick={() => {
                        setActive("Vegan");
                        const removeFav = allcuisines;
                        setcuisines(
                          removeFav.filter((product) => {
                            if (product.FLD_Food_Type === "Vegan") {
                              count++;
                              return product;
                            } else {
                              return false;
                            }
                          })
                        );
                        setSearchText("");
                      }}>
                      {/* <img src={Vegan} className="recipe-type" /> */}
                      <LuVegan />
                      Vegan
                    </button>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
      <section className="mt-md-4">
        <Container fluid>
          {/* {keyStrings != null ? (
            <Row>
              <h2
                className="text-center pt-3 category-title oder-online-category-title"
                dangerouslySetInnerHTML={{
                  __html:
                    keyStrings?.filter(
                      (item) => item.FLD_Key_Name == "HOME_PAGE_CATEGORY_TEXT"
                    )[0] != null
                      ? keyStrings?.filter(
                        (item) =>
                          item.FLD_Key_Name == "HOME_PAGE_CATEGORY_TEXT"
                      )[0].FLD_Key_Value
                      : "",
                }}></h2>
            </Row>
          ) : (
            <div></div>
          )} */}

          <div
            className="mx-0 oder-online-category"
            data-category-columns={
              menuListItems.length % 2 == 0
                ? menuListItems.length / 2
                : (menuListItems.length + 1) / 2
            }>
            {menuListItems
              // .slice(0, visible?.start)
              .map((MenuListItem) => (
                <div
                  className="oder-online-category-card"
                  key={MenuListItem.fld_category_id}>
                  <div className="text-center mx-lg-2 mx-0 cursor-pointer">
                    <div className="item-image category-box">
                      <img
                        onClick={(e) => {
                          // const removeFav = allcuisines;
                          // setcuisines(
                          //   removeFav.filter((product) =>
                          //     product.Category_name.includes(
                          //       e.target.getAttribute("alt")
                          //     )
                          //   )
                          // );
                          setActiveTab(MenuListItem.fld_category_id);
                          windowScrollY(MenuListItem.fld_category_id);
                          setSearchText(e.target.getAttribute("alt"));
                          setActive("");
                        }}
                        className="img-fluid"
                        src={MenuListItem.fld_image}
                        alt={MenuListItem.fld_name}
                      />
                    </div>
                    <p className="item-name">{MenuListItem.fld_name}</p>
                  </div>
                </div>
              ))}
          </div>
        </Container>
      </section>
      <section>
        <Container fluid>
          <Row>
            {menuListItems?.map((data) => {
              const filteredCuisines = cuisines.filter(
                (dat) => dat.FLD_Cat_Id === data.fld_category_id
              );
              return (
                <>
                  {filteredCuisines.length > 0 && (
                    <Col
                      lg={12}
                      id={data.fld_category_id}
                      ref={scrollRef}
                      className="mb-4">
                      <Card className="shadow-search">
                        <Card.Body className="pt-0">
                          <div className="sticky-top sticky-top-category-heading d-flex align-items-center">
                            <h2>{data.fld_name}</h2>
                          </div>
                          <Row>
                            {cuisines.length > 0 ? (
                              cuisines
                                .filter((item) => {
                                  if (data.fld_category_id == item.FLD_Cat_Id) {
                                    return item;
                                  }
                                })
                                // .slice(0, productVisible?.start)
                                .map((Cuisine, index) => (
                                  <Col
                                    xl={4}
                                    md={6}
                                    className="item-card-wrapper"
                                    key={Cuisine.FLD_Item_ID}>
                                    <div className="item-card">
                                      <div className="recipe-img-wrapper recipe-img-wrapper-tab-view">
                                        <Link
                                          onClick={(e) => {
                                            var addondata = null;
                                            var propertydata = null;
                                            setItemPrice(
                                              Number(
                                                Cuisine.FLD_Pricing_new
                                              ).toFixed(2)
                                            );
                                            setProductDetailBanner(
                                              Cuisine.FLD_Image
                                            );
                                            setProductDetailIem(
                                              Cuisine.FLD_Name
                                            );
                                            setProductDetailCuisines(
                                              "Serves" +
                                              Cuisine.FLD_Service_Info
                                            );
                                            setProductDetailDescription(
                                              Cuisine.FLD_Description
                                            );
                                            setSelectedCartLineId(
                                              Cuisine.fld_cart_dtl_id
                                            );
                                            setselectSpiceModal(true);
                                            setitemSpiceLevel(
                                              Cuisine.FLD_Spice_Level
                                            );
                                            setItemId(Cuisine.FLD_Item_ID);
                                            PostApiCall.postRequest(
                                              {
                                                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                columns: ` distinct FLD_Addon_Group,FLD_Max_Addon,FLD_Max_Properties    `,
                                              },
                                              "getItemAddOnMaster"
                                            ).then((result) =>
                                              result?.json().then((obj) => {
                                                if (
                                                  result.status === 200 ||
                                                  result.status === 201
                                                ) {
                                                  handleShowDetails();
                                                  if (obj.data.length > 0) {
                                                    setAddOnGroup(obj.data);
                                                    addondata = obj.data;
                                                  } else {
                                                    setAddOnGroup(null);
                                                    addondata = null;
                                                  }
                                                  if (addOnGroup != null) {
                                                    PostApiCall.postRequest(
                                                      {
                                                        WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                        columns: ` *  `,
                                                      },
                                                      "getItemAddOnMaster"
                                                    ).then((result) =>
                                                      result
                                                        ?.json()
                                                        .then((obj) => {
                                                          if (
                                                            result.status ===
                                                            200 ||
                                                            result.status ===
                                                            201
                                                          ) {
                                                            if (
                                                              obj.data.length >
                                                              0
                                                            ) {
                                                              setAddOnList(
                                                                obj.data
                                                              );
                                                            }
                                                          }
                                                        })
                                                    );
                                                  } else {
                                                  }
                                                }
                                              })
                                            );

                                            // property
                                            PostApiCall.postRequest(
                                              {
                                                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                columns: ` distinct FLD_Property_Name,FLD_Max_Addon,FLD_Max_Properties    `,
                                              },
                                              "GetItemProperty"
                                            ).then((result) =>
                                              result?.json().then((obj) => {
                                                // //console.log(obj.data);
                                                if (
                                                  result.status === 200 ||
                                                  result.status === 201
                                                ) {
                                                  handleShowDetails();
                                                  if (obj.data.length > 0) {
                                                    setProperty(obj.data);
                                                    propertydata = obj.data;
                                                  } else {
                                                    setProperty(null);
                                                    propertydata = null;
                                                  }

                                                  if (propertydata != null) {
                                                    PostApiCall.postRequest(
                                                      {
                                                        WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                        columns: ` *  `,
                                                      },
                                                      "GetItemProperty"
                                                    ).then((result) =>
                                                      result
                                                        ?.json()
                                                        .then((obj) => {
                                                          if (
                                                            result.status ===
                                                            200 ||
                                                            result.status ===
                                                            201
                                                          ) {
                                                            if (
                                                              obj.data.length >
                                                              0
                                                            ) {
                                                              if (
                                                                Cuisine.total_item_in_cart >
                                                                0
                                                              ) {
                                                                setCurrentItemConfig(
                                                                  Cuisine.ConfigDetails
                                                                );
                                                              }

                                                              setPropertyList(
                                                                obj.data
                                                              );
                                                            }
                                                          }
                                                        })
                                                    );

                                                    setCategoryId(
                                                      Cuisine.FLD_Cat_Id
                                                    );
                                                  } else {
                                                    if (
                                                      Cuisine.total_item_in_cart >
                                                      0
                                                    ) {
                                                      setCurrentItemConfig(
                                                        Cuisine.ConfigDetails
                                                      );
                                                      // setShowCopyConfig(true);
                                                      handleShowDetails();
                                                    }
                                                  }
                                                }
                                              })
                                            );
                                          }}>
                                          <img
                                            className="recipe-img order-lg-1 order-2"
                                            src={Cuisine.FLD_Image}
                                            alt={Cuisine.FLD_Name}
                                          />
                                        </Link>
                                      </div>
                                      <div className="w-100 px-lg-3 ps-3 order-lg-2 order-1">
                                        <div>
                                          <div>
                                            <p className="recipe-best">
                                              {Cuisine.food_type && (
                                                <img
                                                  src={Cuisine.food_type}
                                                  className="recipe-type"
                                                  alt="nonveg"
                                                />
                                              )}
                                              <FaPepperHot
                                                className={
                                                  Cuisine.FLD_Spice_Level
                                                }
                                                style={{
                                                  display:
                                                    Cuisine.FLD_Spice_Level ==
                                                      "Yes"
                                                      ? "flex"
                                                      : "none",
                                                }}
                                              />
                                              <span
                                                style={{
                                                  display:
                                                    Cuisine.contains_egg != null
                                                      ? "flex"
                                                      : "none",
                                                }}
                                                className="egg-recipe">
                                                {Cuisine.contains_egg}
                                              </span>
                                              <span
                                                style={{
                                                  display:
                                                    Cuisine.best_seller != null
                                                      ? "flex"
                                                      : "none",
                                                }}
                                                className="best-seller">
                                                {Cuisine.best_seller}
                                              </span>
                                              <span
                                                style={{
                                                  display:
                                                    Cuisine.customisable != null
                                                      ? "flex"
                                                      : "none",
                                                }}
                                                className="customisable">
                                                {Cuisine.customisable}
                                              </span>
                                              <img
                                                style={{
                                                  display:
                                                    Cuisine.chefs_choice != null
                                                      ? "flex"
                                                      : "none",
                                                }}
                                                src={Cuisine.chefs_choice}
                                                className="recipe-type"
                                                alt="Chef's Special"
                                              />
                                            </p>
                                            <p className="recipe-name">
                                              <Link
                                                onClick={() => {
                                                  setitemSpiceLevel(
                                                    Cuisine.FLD_Spice_Level
                                                  );
                                                  getDetails(Cuisine);
                                                  handleShowDetails();
                                                }}>
                                                {Cuisine.FLD_Name}
                                              </Link>
                                            </p>
                                            <p className="recipe-category">
                                              <span>
                                                {Cuisine.Category_name}
                                              </span>
                                              {Cuisine.FLD_Service_Info && (
                                                <span>
                                                  &nbsp;|&nbsp; Serves (
                                                  {Cuisine.FLD_Service_Info})
                                                </span>
                                              )}
                                            </p>
                                            <p className="recipe-description d-none d-lg-block">
                                              {Cuisine.FLD_Description}
                                            </p>
                                            <div className="d-flex d-lg-none justify-content-between align-items-center">
                                              <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  toggleShowRating(index);
                                                }}>
                                                <ReactStars
                                                  count={5}
                                                  value={Cuisine.FLD_Rating}
                                                  size={20}
                                                  edit={false}
                                                  isHalf={true}
                                                  activeColor="#ffd700"
                                                />

                                                <Toast
                                                  // show={showRating}
                                                  show={showRating[index]}
                                                  onClose={() =>
                                                    toggleShowRating(index)
                                                  }
                                                  className="rating-popup">
                                                  <Toast.Header className="justify-content-between">
                                                    <div className="d-flex flex-column">
                                                      <div className="d-flex align-items-center gap-2">
                                                        <ReactStars
                                                          count={5}
                                                          value={
                                                            Cuisine.FLD_Rating
                                                          }
                                                          size={20}
                                                          onChange={(
                                                            rating
                                                          ) => {
                                                            var login =
                                                              localStorage.getItem(
                                                                "LoginDetailsWeb"
                                                              );
                                                            var logindetails =
                                                              login
                                                                ? JSON.parse(
                                                                  login
                                                                )[0]
                                                                : null;
                                                            if (
                                                              login === null
                                                            ) {
                                                              handleShowLoginModal();
                                                            } else {
                                                              PostApiCall.postRequest(
                                                                {
                                                                  rating:
                                                                    rating,
                                                                  variantId:
                                                                    Cuisine.FLD_Item_ID,
                                                                  userId:
                                                                    localStorage.getItem(
                                                                      "LoginDetailsWeb"
                                                                    ) == null
                                                                      ? null
                                                                      : logindetails.CustomerId,
                                                                },
                                                                "UpdateItemRating"
                                                              ).then((result) =>
                                                                result
                                                                  ?.json()
                                                                  .then(
                                                                    (obj) => {
                                                                      if (
                                                                        result.status ===
                                                                        200 ||
                                                                        result.status ===
                                                                        201
                                                                      ) {
                                                                        Notiflix.Notify.Success(
                                                                          "Thank you for rating."
                                                                        );
                                                                        getMenuList();
                                                                      } else {
                                                                        Notiflix.Notify.failure(
                                                                          obj.message
                                                                        );
                                                                      }

                                                                      getCart();
                                                                    }
                                                                  )
                                                              );
                                                            }
                                                          }}
                                                          edit={true}
                                                          isHalf={false}
                                                          activeColor="#ffd700"
                                                        />
                                                        <h6 className="fw-bold d-block mb-0">
                                                          {Cuisine.FLD_Rating}{" "}
                                                          out of 5
                                                        </h6>
                                                      </div>
                                                      <div>
                                                        <p className="d-block mb-0 small">
                                                          {Cuisine.rating_count}{" "}
                                                          global ratings
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </Toast.Header>
                                                  {Cuisine.rating_breakup
                                                    ?.split("#")
                                                    .map((data) => {
                                                      return (
                                                        <Toast.Body className="bg-white">
                                                          <div className="d-flex gap-2 align-items-center">
                                                            <p className="mb-0 text-end">
                                                              {
                                                                data?.split(
                                                                  ","
                                                                )[0]
                                                              }{" "}
                                                              Star
                                                            </p>
                                                            <div>
                                                              <ProgressBar
                                                                variant="warning"
                                                                now={
                                                                  data.split(
                                                                    ","
                                                                  )[1]
                                                                }
                                                              />
                                                            </div>
                                                            <p className="mb-0">
                                                              {
                                                                data.split(
                                                                  ","
                                                                )[1]
                                                              }
                                                              %
                                                            </p>
                                                          </div>
                                                        </Toast.Body>
                                                      );
                                                    })}
                                                </Toast>
                                              </div>
                                              <div className="d-flex gap-2 align-items-center">
                                                {/* <FaRegHeart className="wishlist-share cursor-pointer" /> */}
                                                {/* <FaRegShareSquare className="wishlist-share cursor-pointer" /> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="recipe-description d-block d-lg-none pt-2">
                                      {Cuisine.FLD_Description}
                                    </p>
                                    <Row className="justify-content-between align-items-end">
                                      <Col lg="5" className="pe-0 d-none d-lg-block">
                                        <div className="py-lg-1 px-1 d-flex justify-content-between align-items-center">
                                          <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                              toggleShowRating(index);
                                            }}>
                                            <ReactStars
                                              count={5}
                                              value={Cuisine.FLD_Rating}
                                              size={20}
                                              edit={false}
                                              isHalf={true}
                                              activeColor="#ffd700"
                                            />

                                            <Toast
                                              // show={showRating}
                                              show={showRating[index]}
                                              onClose={() =>
                                                toggleShowRating(index)
                                              }
                                              className="rating-popup">
                                              <Toast.Header className="justify-content-between">
                                                <div className="d-flex flex-column">
                                                  <div className="d-flex align-items-center gap-2">
                                                    <ReactStars
                                                      count={5}
                                                      value={Cuisine.FLD_Rating}
                                                      size={20}
                                                      onChange={(rating) => {
                                                        var login =
                                                          localStorage.getItem(
                                                            "LoginDetailsWeb"
                                                          );
                                                        var logindetails = login
                                                          ? JSON.parse(login)[0]
                                                          : null;
                                                        if (login === null) {
                                                          handleShowLoginModal();
                                                        } else {
                                                          PostApiCall.postRequest(
                                                            {
                                                              rating: rating,
                                                              variantId:
                                                                Cuisine.FLD_Item_ID,
                                                              userId:
                                                                localStorage.getItem(
                                                                  "LoginDetailsWeb"
                                                                ) == null
                                                                  ? null
                                                                  : logindetails.CustomerId,
                                                            },
                                                            "UpdateItemRating"
                                                          ).then((result) =>
                                                            result
                                                              ?.json()
                                                              .then((obj) => {
                                                                if (
                                                                  result.status ===
                                                                  200 ||
                                                                  result.status ===
                                                                  201
                                                                ) {
                                                                  Notiflix.Notify.Success(
                                                                    "Thank you for rating."
                                                                  );
                                                                  getMenuList();
                                                                } else {
                                                                  Notiflix.Notify.failure(
                                                                    obj.message
                                                                  );
                                                                }

                                                                getCart();
                                                              })
                                                          );
                                                        }
                                                      }}
                                                      edit={true}
                                                      isHalf={false}
                                                      activeColor="#ffd700"
                                                    />
                                                    <h6 className="fw-bold d-block mb-0">
                                                      {Cuisine.FLD_Rating} out
                                                      of 5
                                                    </h6>
                                                  </div>
                                                  <div>
                                                    <p className="d-block mb-0 small">
                                                      {Cuisine.rating_count}{" "}
                                                      global ratings
                                                    </p>
                                                  </div>
                                                </div>
                                              </Toast.Header>
                                              {Cuisine.rating_breakup
                                                ?.split("#")
                                                .map((data) => {
                                                  return (
                                                    <Toast.Body className="bg-white">
                                                      <div className="d-flex gap-2 align-items-center">
                                                        <p className="mb-0 text-end">
                                                          {data?.split(",")[0]}{" "}
                                                          Star
                                                        </p>
                                                        <div>
                                                          <ProgressBar
                                                            variant="warning"
                                                            now={
                                                              data.split(",")[1]
                                                            }
                                                          />
                                                        </div>
                                                        <p className="mb-0">
                                                          {data.split(",")[1]}%
                                                        </p>
                                                      </div>
                                                    </Toast.Body>
                                                  );
                                                })}
                                            </Toast>
                                          </div>
                                          <div className="d-flex gap-2 align-items-center">
                                            {/* <FaRegHeart className="wishlist-share cursor-pointer" /> */}
                                            {/* <FaRegShareSquare className="wishlist-share cursor-pointer" /> */}
                                          </div>
                                        </div>
                                      </Col>

                                      <Col lg="7">
                                        <div className="d-flex justify-content-between align-items-center gap-3 py-1 item-card-bottom">
                                          <div className="d-flex gap-2">
                                            <span
                                              style={{
                                                display:
                                                  Cuisine.delivery_type ===
                                                    "TAKEAWAY" ||
                                                    Cuisine.delivery_type ===
                                                    "BOTH"
                                                    ? "flex"
                                                    : "none",
                                              }}>
                                              <RiEBike2Line
                                                className="wishlist-share"
                                                title="Delivery" />
                                            </span>
                                            <span
                                              style={{
                                                display:
                                                  Cuisine.delivery_type ===
                                                    "DELIVERY" ||
                                                    Cuisine.delivery_type ===
                                                    "BOTH"
                                                    ? "flex"
                                                    : "none",
                                              }}>
                                              <TbPaperBag
                                                className="wishlist-share"
                                                title="TakeAway" />
                                            </span>
                                            <div
                                              // href="#"
                                              onClick={() => {
                                                setitemSpiceLevel(
                                                  Cuisine.FLD_Spice_Level
                                                );
                                                getDetails(Cuisine);
                                                handleShowDetails();
                                              }}
                                              style={{
                                                display:
                                                  Cuisine.delivery_type ===
                                                    "DELIVERY" ||
                                                    Cuisine.delivery_type ===
                                                    "BOTH"
                                                    ? "flex"
                                                    : "none",
                                              }}>
                                              <TbInfoSquare
                                                className="wishlist-share cursor-pointer"
                                                title="Read More"
                                              />
                                            </div>
                                          </div>
                                          <div className="d-flex gap-3">
                                            <p className="recipe-price">
                                              {Cuisine.FLD_Pricing_new != 0 && (
                                                <>
                                                  <span className="me-1">
                                                    {currencyCode}{" "}
                                                  </span>
                                                  <span>
                                                    {Number(
                                                      Cuisine.FLD_Pricing_new
                                                    ).toFixed(2)}
                                                  </span>
                                                </>
                                              )}
                                            </p>
                                            {Cuisine.total_item_in_cart == 0 &&
                                              restaurantOpen != 'false' ? (
                                              <button
                                                className="btn btn-danger add-to-cart-btn"
                                                disabled={addToCartDisbabled}
                                                onClick={() => {
                                                  // start spice level modal
                                                  if (
                                                    Cuisine.FLD_Spice_Level ==
                                                    "Yes"
                                                  ) {
                                                    //   // handleShowSpiceLevel(true)
                                                    setCustomizable(
                                                      Cuisine.customisable
                                                    );
                                                    setSelectedItemName(
                                                      Cuisine.FLD_Name
                                                    );
                                                    setitemSpiceLevel(
                                                      Cuisine.FLD_Spice_Level
                                                    );
                                                    getDetails(Cuisine);
                                                    handleShowDetails();
                                                    setItemId(
                                                      Cuisine.FLD_Item_ID
                                                    );
                                                    //}
                                                    // // end spice level modal
                                                    // else
                                                  }
                                                  if (
                                                    Cuisine.property_count >
                                                    0 ||
                                                    Cuisine.addon_count > 0 ||
                                                    Cuisine.customisable !=
                                                    null ||
                                                    Cuisine.FLD_Spice_Level ==
                                                    "Yes"
                                                  ) {
                                                    var addondata = null;
                                                    var propertydata = null;
                                                    setProductDetailBanner(
                                                      Cuisine.FLD_Image
                                                    );
                                                    setItemPrice(
                                                      Number(
                                                        Cuisine.FLD_Pricing_new
                                                      ).toFixed(2)
                                                    );
                                                    setProductDetailIem(
                                                      Cuisine.FLD_Name
                                                    );
                                                    setProductDetailCuisines(
                                                      "Serves" +
                                                      Cuisine.FLD_Service_Info
                                                    );
                                                    setProductDetailDescription(
                                                      Cuisine.FLD_Description
                                                    );
                                                    setSelectedCartLineId(
                                                      Cuisine.fld_cart_dtl_id
                                                    );
                                                    setItemId(
                                                      Cuisine.FLD_Item_ID
                                                    );
                                                    PostApiCall.postRequest(
                                                      {
                                                        WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                        columns: ` distinct FLD_Addon_Group,FLD_Max_Addon,FLD_Max_Properties    `,
                                                      },
                                                      "getItemAddOnMaster"
                                                    ).then((result) =>
                                                      result
                                                        ?.json()
                                                        .then((obj) => {
                                                          if (
                                                            result.status ===
                                                            200 ||
                                                            result.status ===
                                                            201
                                                          ) {
                                                            if (
                                                              obj.data.length >
                                                              0
                                                            ) {
                                                              setAddOnGroup(
                                                                obj.data
                                                              );
                                                              addondata =
                                                                obj.data;
                                                            } else {
                                                              setAddOnGroup(
                                                                null
                                                              );
                                                              addondata = null;
                                                            }
                                                            if (
                                                              addOnGroup != null
                                                            ) {
                                                              PostApiCall.postRequest(
                                                                {
                                                                  WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                                  columns: ` *  `,
                                                                },
                                                                "getItemAddOnMaster"
                                                              ).then((result) =>
                                                                result
                                                                  ?.json()
                                                                  .then(
                                                                    (obj) => {
                                                                      if (
                                                                        result.status ===
                                                                        200 ||
                                                                        result.status ===
                                                                        201
                                                                      ) {
                                                                        if (
                                                                          obj
                                                                            .data
                                                                            .length >
                                                                          0
                                                                        ) {
                                                                          setAddOnList(
                                                                            obj.data
                                                                          );
                                                                        } else {
                                                                          setAddOnList(
                                                                            null
                                                                          );
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                              );
                                                            } else {
                                                            }
                                                          }
                                                        })
                                                    );

                                                    // property
                                                    PostApiCall.postRequest(
                                                      {
                                                        WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                        columns: ` distinct FLD_Property_Name,FLD_Max_Addon,FLD_Max_Properties    `,
                                                      },
                                                      "GetItemProperty"
                                                    ).then((result) =>
                                                      result
                                                        ?.json()
                                                        .then((obj) => {
                                                          if (
                                                            result.status ===
                                                            200 ||
                                                            result.status ===
                                                            201
                                                          ) {
                                                            if (
                                                              obj.data.length >
                                                              0
                                                            ) {
                                                              setProperty(
                                                                obj.data
                                                              );
                                                              propertydata =
                                                                obj.data;
                                                            } else {
                                                              setProperty(null);
                                                              propertydata =
                                                                null;
                                                            }

                                                            if (
                                                              propertydata !=
                                                              null
                                                            ) {
                                                              PostApiCall.postRequest(
                                                                {
                                                                  WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                                  columns: ` *  `,
                                                                },
                                                                "GetItemProperty"
                                                              ).then((result) =>
                                                                result
                                                                  ?.json()
                                                                  .then(
                                                                    (obj) => {
                                                                      if (
                                                                        obj.data
                                                                          .length >
                                                                        0
                                                                      ) {
                                                                        if (
                                                                          Cuisine.total_item_in_cart >
                                                                          0
                                                                        ) {
                                                                          setCurrentItemConfig(
                                                                            Cuisine.ConfigDetails
                                                                          );

                                                                          // if (Cuisine.ConfigDetails != null) {
                                                                          //   setShowCopyConfig(
                                                                          //     true
                                                                          //   );
                                                                          // }
                                                                        } else {
                                                                          handleShowDetails();
                                                                          setitemSpiceLevel(
                                                                            Cuisine.FLD_Spice_Level
                                                                          );
                                                                          // if (Cuisine.ConfigDetails != null) {
                                                                          //   setShowCopyConfig(
                                                                          //     true
                                                                          //   );
                                                                          // }
                                                                        }
                                                                        setPropertyList(
                                                                          obj.data
                                                                        );
                                                                      } else {
                                                                        setPropertyList(
                                                                          null
                                                                        );
                                                                      }
                                                                    }
                                                                  )
                                                              );

                                                              setCategoryId(
                                                                Cuisine.FLD_Cat_Id
                                                              );
                                                              setselectSpiceModal(
                                                                true
                                                              );
                                                              setitemSpiceLevel(
                                                                Cuisine.FLD_Spice_Level
                                                              );
                                                            } else {
                                                              if (
                                                                Cuisine.total_item_in_cart >
                                                                0
                                                              ) {
                                                                setCurrentItemConfig(
                                                                  Cuisine.ConfigDetails
                                                                );
                                                                // if (Cuisine.ConfigDetails != null) {
                                                                //   setShowCopyConfig(true);
                                                                // }
                                                                //handleShowDetails();
                                                              }
                                                            }
                                                          }
                                                        })
                                                    );
                                                    if (
                                                      Cuisine.property_count >
                                                      0 ||
                                                      Cuisine.addon_count !=
                                                      null
                                                    ) {
                                                      if (
                                                        Cuisine.total_item_in_cart >
                                                        0
                                                      ) {
                                                        setCurrentItemConfig(
                                                          Cuisine.ConfigDetails
                                                        );
                                                        // setShowCopyConfig(true);
                                                      } else {
                                                        handleShowDetails();
                                                      }
                                                    } else {
                                                      setAddToCartDisabled(
                                                        true
                                                      );
                                                      setSelectedAddons([]);
                                                      setSelectedProperties([]);
                                                      var login =
                                                        localStorage.getItem(
                                                          "LoginDetailsWeb"
                                                        );
                                                      var logindetails = login
                                                        ? JSON.parse(login)[0]
                                                        : null;
                                                      PostApiCall.postRequest(
                                                        {
                                                          orderdate:
                                                            moment().format(
                                                              "lll"
                                                            ),
                                                          itemdetails: `[{"Item":${Cuisine.FLD_Item_ID
                                                            },"Quantity":${1},"SpiceLevel":"","specialInstruction":"","addons":${null},"property":${null},"copyconfig":"No"}]`,
                                                          status: "INCART",
                                                          customerid:
                                                            localStorage.getItem(
                                                              "LoginDetailsWeb"
                                                            ) == null
                                                              ? null
                                                              : logindetails.CustomerId,
                                                          createdon:
                                                            moment().format(
                                                              "lll"
                                                            ),
                                                          updatedon:
                                                            moment().format(
                                                              "lll"
                                                            ),
                                                          orderid:
                                                            localStorage.getItem(
                                                              "OrderData"
                                                            ) == null
                                                              ? 0
                                                              : JSON.parse(
                                                                localStorage.getItem(
                                                                  "OrderData"
                                                                )
                                                              ).order_id,
                                                          updatedby:
                                                            localStorage.getItem(
                                                              "LoginDetailsWeb"
                                                            ) == null
                                                              ? null
                                                              : logindetails.CustomerId,

                                                          stateid: null,
                                                        },
                                                        "addToCart"
                                                      ).then((result) =>
                                                        result
                                                          ?.json()
                                                          .then((obj) => {
                                                            if (
                                                              result.status ===
                                                              200 ||
                                                              result.status ===
                                                              201
                                                            ) {
                                                              localStorage.setItem(
                                                                "OrderData",
                                                                JSON.stringify(
                                                                  obj.message[0]
                                                                )
                                                              );
                                                              setSelectedAddons(
                                                                []
                                                              );
                                                              setSelectedProperties(
                                                                []
                                                              );
                                                            } else {
                                                              Notiflix.Notify.failure(
                                                                obj.message
                                                              );
                                                            }
                                                            getMenuData(
                                                              obj.message
                                                            );
                                                            getCart();
                                                          })
                                                      );
                                                    }
                                                  } else {
                                                    setAddToCartDisabled(true);
                                                    setSelectedAddons([]);
                                                    setSelectedProperties([]);
                                                    var login =
                                                      localStorage.getItem(
                                                        "LoginDetailsWeb"
                                                      );
                                                    var logindetails = login
                                                      ? JSON.parse(login)[0]
                                                      : null;
                                                    PostApiCall.postRequest(
                                                      {
                                                        orderdate:
                                                          moment().format(
                                                            "lll"
                                                          ),
                                                        itemdetails: `[{"Item":${Cuisine.FLD_Item_ID
                                                          },"Quantity":${1},"SpiceLevel":"","specialInstruction":"","addons":${null},"property":${null},"copyconfig":"No"}]`,
                                                        status: "INCART",
                                                        customerid:
                                                          localStorage.getItem(
                                                            "LoginDetailsWeb"
                                                          ) == null
                                                            ? null
                                                            : logindetails.CustomerId,
                                                        createdon:
                                                          moment().format(
                                                            "lll"
                                                          ),
                                                        updatedon:
                                                          moment().format(
                                                            "lll"
                                                          ),
                                                        orderid:
                                                          localStorage.getItem(
                                                            "OrderData"
                                                          ) == null
                                                            ? 0
                                                            : JSON.parse(
                                                              localStorage.getItem(
                                                                "OrderData"
                                                              )
                                                            ).order_id,
                                                        updatedby:
                                                          localStorage.getItem(
                                                            "LoginDetailsWeb"
                                                          ) == null
                                                            ? null
                                                            : logindetails.CustomerId,

                                                        stateid: null,
                                                      },
                                                      "addToCart"
                                                    ).then((result) =>
                                                      result
                                                        ?.json()
                                                        .then((obj) => {
                                                          if (
                                                            result.status ===
                                                            200 ||
                                                            result.status ===
                                                            201
                                                          ) {
                                                            localStorage.setItem(
                                                              "OrderData",
                                                              JSON.stringify(
                                                                obj.message[0]
                                                              )
                                                            );
                                                            setSelectedAddons(
                                                              []
                                                            );
                                                            setSelectedProperties(
                                                              []
                                                            );
                                                          } else {
                                                            Notiflix.Notify.Failure(
                                                              obj.message
                                                            );
                                                          }
                                                          getMenuData(
                                                            obj.message
                                                          );
                                                          getCart();
                                                        })
                                                    );
                                                  }
                                                }}>
                                                Add
                                              </button>
                                            ) : (
                                              <>
                                                {restaurantOpen != 'false' ? (
                                                  <div className="mb-0 small fw-bold quantity-control">
                                                    <button
                                                      type="button"
                                                      disabled={
                                                        addToCartDisbabled
                                                      }
                                                      className="quantity-btn btn btn-primary"
                                                      onClick={() => {
                                                        setAddToCartDisabled(
                                                          true
                                                        );
                                                        if (
                                                          parseInt(
                                                            Cuisine.distinct_item_count
                                                          ) > parseInt("1")
                                                        ) {
                                                          var login =
                                                            localStorage.getItem(
                                                              "LoginDetailsWeb"
                                                            );
                                                          var logindetails =
                                                            login
                                                              ? JSON.parse(
                                                                login
                                                              )[0]
                                                              : null;
                                                          var Order =
                                                            localStorage.getItem(
                                                              "OrderData"
                                                            );
                                                          var Orderdetails =
                                                            JSON.parse(Order);
                                                          PostApiCall.postRequest(
                                                            {
                                                              orderid:
                                                                localStorage.getItem(
                                                                  "OrderData"
                                                                ) == null
                                                                  ? null
                                                                  : Orderdetails.order_id,
                                                              id:
                                                                localStorage.getItem(
                                                                  "LoginDetailsWeb"
                                                                ) == null
                                                                  ? null
                                                                  : logindetails.CustomerId,
                                                              stateid: null,
                                                              itemid:
                                                                Cuisine.FLD_Item_ID,
                                                            },
                                                            "getCartWebsite"
                                                          ).then((result) =>
                                                            result
                                                              ?.json()
                                                              .then((obj) => {
                                                                if (
                                                                  result.status ===
                                                                  200 ||
                                                                  result.status ===
                                                                  201
                                                                ) {
                                                                  setItemsToDelete(
                                                                    obj.message
                                                                  );
                                                                  setSelectedItemId(
                                                                    Cuisine.FLD_Item_ID
                                                                  );
                                                                  setSelectedItemName(
                                                                    Cuisine.FLD_Name
                                                                  );
                                                                  setAddToCartDisabled(
                                                                    false
                                                                  );
                                                                  handleShowCartItemsDetails();
                                                                }
                                                              })
                                                          );
                                                        } else {
                                                          setAddToCartDisabled(
                                                            true
                                                          );
                                                          setItemId(
                                                            Cuisine.FLD_Item_ID
                                                          );
                                                          setSelectedCartLineId(
                                                            Cuisine.fld_cart_dtl_id
                                                          );
                                                          setCurrentItemConfig(
                                                            Cuisine.ConfigDetails
                                                          );
                                                          var login =
                                                            localStorage.getItem(
                                                              "LoginDetailsWeb"
                                                            );
                                                          var logindetails =
                                                            JSON.parse(
                                                              login
                                                            ) === null
                                                              ? null
                                                              : [0];
                                                          var Order =
                                                            localStorage.getItem(
                                                              "OrderData"
                                                            );
                                                          var Orderdetails =
                                                            JSON.parse(Order);
                                                          if (
                                                            Cuisine.item_count >=
                                                            1
                                                          ) {
                                                            PostApiCall.postRequest(
                                                              {
                                                                orderdate:
                                                                  moment().format(
                                                                    "lll"
                                                                  ),
                                                                itemdetails: `[{"Item":${Cuisine.FLD_Item_ID
                                                                  },"Quantity":${-1},"SpiceLevel":"","specialInstruction":"","addons":${null},"property":${null},"copyconfig":"No","cartlineid" :"${Cuisine.fld_cart_dtl_id
                                                                  }"}]`,
                                                                status:
                                                                  "INCART",
                                                                customerid:
                                                                  localStorage.getItem(
                                                                    "LoginDetailsWeb"
                                                                  ) == null
                                                                    ? 1
                                                                    : logindetails.CustomerId,
                                                                createdon:
                                                                  moment().format(
                                                                    "lll"
                                                                  ),
                                                                updatedon:
                                                                  moment().format(
                                                                    "lll"
                                                                  ),
                                                                orderid:
                                                                  localStorage.getItem(
                                                                    "OrderData"
                                                                  ) == null
                                                                    ? 0
                                                                    : JSON.parse(
                                                                      localStorage.getItem(
                                                                        "OrderData"
                                                                      )
                                                                    )
                                                                      .order_id,
                                                                updatedby:
                                                                  localStorage.getItem(
                                                                    "LoginDetailsWeb"
                                                                  ) == null
                                                                    ? null
                                                                    : logindetails.CustomerId,
                                                              },
                                                              "addToCart"
                                                            ).then((result) =>
                                                              result
                                                                .json()
                                                                .then(
                                                                  (obj3) => {
                                                                    if (
                                                                      result.status ===
                                                                      200 ||
                                                                      result.status ===
                                                                      201
                                                                    ) {
                                                                      //setupdateCart(!updateCart);
                                                                      setSelectedAddons(
                                                                        []
                                                                      );
                                                                      setSelectedProperties(
                                                                        []
                                                                      );
                                                                      if (
                                                                        localStorage.getItem(
                                                                          "OrderData"
                                                                        ) ==
                                                                        null
                                                                      ) {
                                                                        localStorage.setItem(
                                                                          "OrderData",
                                                                          JSON.stringify(
                                                                            obj3
                                                                              .message[0]
                                                                          )
                                                                        );
                                                                      }
                                                                    } else {
                                                                      Notiflix.Notify.Failure(
                                                                        obj3.message
                                                                      );
                                                                    }

                                                                    // getmenulist(record.FLD_Cat_Id);
                                                                    getMenuData(
                                                                      obj3.message
                                                                    );
                                                                    getCart();
                                                                    if (
                                                                      cartData[0]
                                                                        ?.fld_coupon_code !=
                                                                      null
                                                                    ) {
                                                                      const appliedcoupon =
                                                                        offerList.filter(
                                                                          (
                                                                            coupon
                                                                          ) =>
                                                                            cartData[0]
                                                                              ?.fld_coupon_code ==
                                                                            coupon.fld_code
                                                                        );
                                                                      if (
                                                                        cartData[0]
                                                                          ?.fld_cart_amount !=
                                                                        null &&
                                                                        cartData[0]
                                                                          ?.fld_cart_amount <
                                                                        appliedcoupon[0]
                                                                          ?.fld_minapplyamnt
                                                                      ) {
                                                                        RemoveCoupon();
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                            );

                                                            // }
                                                            setAddToCartDisabled(
                                                              true
                                                            );
                                                            if (
                                                              Cuisine.item_count ==
                                                              1
                                                            ) {
                                                              if (
                                                                Cuisine.item_count ==
                                                                1
                                                              ) {
                                                                PostApiCall.postRequest(
                                                                  {
                                                                    cartID:
                                                                      JSON.parse(
                                                                        localStorage.getItem(
                                                                          "OrderData"
                                                                        )
                                                                      )
                                                                        .order_id,
                                                                    customerid:
                                                                      localStorage.getItem(
                                                                        "LoginDetailsWeb"
                                                                      ) == null
                                                                        ? 1
                                                                        : logindetails.CustomerId,
                                                                    cartlineid:
                                                                      Cuisine.fld_cart_dtl_id,
                                                                  },
                                                                  "deleteCartItem"
                                                                ).then(
                                                                  (result) =>
                                                                    result
                                                                      .json()
                                                                      .then(
                                                                        (
                                                                          obj3
                                                                        ) => {
                                                                          if (
                                                                            result.status ===
                                                                            200 ||
                                                                            result.status ===
                                                                            201
                                                                          ) {
                                                                            //setupdateCart(!updateCart);

                                                                            if (
                                                                              Cuisine.length ==
                                                                              0
                                                                            ) {
                                                                              localStorage.removeItem(
                                                                                "OrderData"
                                                                              );
                                                                            }
                                                                            //getmenulist(record.FLD_Cat_Id);
                                                                            getCart();
                                                                          } else {
                                                                            Notiflix.Notify.failure(
                                                                              obj3.message
                                                                            );
                                                                          }
                                                                        }
                                                                      )
                                                                );
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }}>
                                                      <FaMinus />
                                                    </button>
                                                    <div className="px-1">
                                                      {Cuisine.total_item_in_cart
                                                        ?.toString()
                                                        .padStart(2, 0)}
                                                    </div>
                                                    <button
                                                      type="button"
                                                      disabled={
                                                        addToCartDisbabled
                                                      }
                                                      className="quantity-btn btn btn-primary"
                                                      onClick={() => {
                                                        setItemId(
                                                          Cuisine.FLD_Item_ID
                                                        );
                                                        setItemPrice(
                                                          Number(
                                                            Cuisine.FLD_Pricing_new
                                                          ).toFixed(2)
                                                        );
                                                        setCurrentItemConfig(
                                                          Cuisine.ConfigDetails
                                                        );
                                                        setSelectedCartLineId(
                                                          Cuisine.fld_cart_dtl_id
                                                        );
                                                        if (
                                                          Cuisine.ConfigDetails !=
                                                          "#"
                                                        ) {
                                                          setShowCopyConfig(
                                                            true
                                                          );
                                                        } else {
                                                          var addondata = null;
                                                          var propertydata =
                                                            null;
                                                          setProductDetailBanner(
                                                            Cuisine.FLD_Image
                                                          );
                                                          setProductDetailIem(
                                                            Cuisine.FLD_Name
                                                          );
                                                          setProductDetailCuisines(
                                                            "Serves" +
                                                            Cuisine.FLD_Service_Info
                                                          );
                                                          setProductDetailDescription(
                                                            Cuisine.FLD_Description
                                                          );
                                                          setSelectedCartLineId(
                                                            Cuisine.fld_cart_dtl_id
                                                          );

                                                          // property
                                                          PostApiCall.postRequest(
                                                            {
                                                              WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                              columns: ` distinct FLD_Property_Name,FLD_Max_Addon,FLD_Max_Properties    `,
                                                            },
                                                            "GetItemProperty"
                                                          ).then((result) =>
                                                            result
                                                              ?.json()
                                                              .then((obj) => {
                                                                if (
                                                                  result.status ===
                                                                  200 ||
                                                                  result.status ===
                                                                  201
                                                                ) {
                                                                  if (
                                                                    obj.data
                                                                      .length >
                                                                    0
                                                                  ) {
                                                                    setProperty(
                                                                      obj.data
                                                                    );
                                                                    propertydata =
                                                                      obj.data;
                                                                  } else {
                                                                    setProperty(
                                                                      null
                                                                    );
                                                                    propertydata =
                                                                      null;
                                                                  }

                                                                  if (
                                                                    propertydata !=
                                                                    null
                                                                  ) {
                                                                    PostApiCall.postRequest(
                                                                      {
                                                                        WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                                        columns: `*`,
                                                                      },
                                                                      "GetItemProperty"
                                                                    ).then(
                                                                      (
                                                                        result
                                                                      ) =>
                                                                        result
                                                                          ?.json()
                                                                          .then(
                                                                            (
                                                                              obj
                                                                            ) => {
                                                                              if (
                                                                                obj
                                                                                  .data
                                                                                  .length >
                                                                                0
                                                                              ) {
                                                                                if (
                                                                                  Cuisine.total_item_in_cart >
                                                                                  0
                                                                                ) {
                                                                                  setCurrentItemConfig(
                                                                                    Cuisine.ConfigDetails
                                                                                  );
                                                                                  setShowCopyConfig(
                                                                                    true
                                                                                  );
                                                                                } else {
                                                                                  handleShowDetails();
                                                                                }
                                                                                setPropertyList(
                                                                                  obj.data
                                                                                );
                                                                              } else {
                                                                                setPropertyList(
                                                                                  null
                                                                                );
                                                                              }
                                                                            }
                                                                          )
                                                                    );

                                                                    setCategoryId(
                                                                      Cuisine.FLD_Cat_Id
                                                                    );
                                                                    setselectSpiceModal(
                                                                      true
                                                                    );
                                                                    setitemSpiceLevel(
                                                                      Cuisine.FLD_Spice_Level
                                                                    );
                                                                  } else {
                                                                    if (
                                                                      Cuisine.total_item_in_cart >
                                                                      0
                                                                    ) {
                                                                      setCurrentItemConfig(
                                                                        Cuisine.ConfigDetails
                                                                      );
                                                                      // setShowCopyConfig(
                                                                      //   true
                                                                      // );
                                                                      //handleShowDetails();
                                                                    }
                                                                  }
                                                                }
                                                              })
                                                          );
                                                          if (
                                                            Cuisine.property_count >
                                                            0 ||
                                                            Cuisine.addon_count >
                                                            0
                                                          ) {
                                                            if (
                                                              Cuisine.total_item_in_cart >
                                                              0
                                                            ) {
                                                              setCurrentItemConfig(
                                                                Cuisine.ConfigDetails
                                                              );
                                                              handleShowDetails();
                                                              setCustomizable(
                                                                Cuisine.customisable
                                                              );
                                                              setSelectedItemName(
                                                                Cuisine.FLD_Name
                                                              );
                                                              setitemSpiceLevel(
                                                                Cuisine.FLD_Spice_Level
                                                              );
                                                              getDetails(
                                                                Cuisine
                                                              );
                                                            } else {
                                                              handleShowDetails();
                                                              setCustomizable(
                                                                Cuisine.customisable
                                                              );
                                                              setSelectedItemName(
                                                                Cuisine.FLD_Name
                                                              );
                                                              setitemSpiceLevel(
                                                                Cuisine.FLD_Spice_Level
                                                              );
                                                              getDetails(
                                                                Cuisine
                                                              );
                                                            }
                                                          } else {
                                                            var login =
                                                              localStorage.getItem(
                                                                "LoginDetailsWeb"
                                                              );
                                                            var logindetails =
                                                              login
                                                                ? JSON.parse(
                                                                  login
                                                                )[0]
                                                                : null;
                                                            setAddToCartDisabled(
                                                              true
                                                            );
                                                            PostApiCall.postRequest(
                                                              {
                                                                orderdate:
                                                                  moment().format(
                                                                    "lll"
                                                                  ),
                                                                itemdetails: `[{"Item":${Cuisine.FLD_Item_ID
                                                                  },"Quantity":${1},"SpiceLevel":"","specialInstruction":"","addons":${null},"property":${null},"copyconfig":"No","cartlineid" :"${Cuisine.fld_cart_dtl_id
                                                                  }"}]`,
                                                                status:
                                                                  "INCART",
                                                                customerid:
                                                                  localStorage.getItem(
                                                                    "LoginDetailsWeb"
                                                                  ) == null
                                                                    ? null
                                                                    : logindetails.CustomerId,
                                                                createdon:
                                                                  moment().format(
                                                                    "lll"
                                                                  ),
                                                                updatedon:
                                                                  moment().format(
                                                                    "lll"
                                                                  ),
                                                                orderid:
                                                                  localStorage.getItem(
                                                                    "OrderData"
                                                                  ) == null
                                                                    ? 0
                                                                    : JSON.parse(
                                                                      localStorage.getItem(
                                                                        "OrderData"
                                                                      )
                                                                    )
                                                                      .order_id,
                                                                updatedby:
                                                                  localStorage.getItem(
                                                                    "LoginDetailsWeb"
                                                                  ) == null
                                                                    ? null
                                                                    : logindetails.CustomerId,

                                                                stateid: null,
                                                              },
                                                              "addToCart"
                                                            ).then((result) =>
                                                              result
                                                                ?.json()
                                                                .then((obj) => {
                                                                  if (
                                                                    result.status ===
                                                                    200 ||
                                                                    result.status ===
                                                                    201
                                                                  ) {
                                                                    localStorage.setItem(
                                                                      "OrderData",
                                                                      JSON.stringify(
                                                                        obj
                                                                          .message[0]
                                                                      )
                                                                    );
                                                                    setSelectedAddons(
                                                                      []
                                                                    );
                                                                    setSelectedProperties(
                                                                      []
                                                                    );
                                                                  } else {
                                                                    Notiflix.Notify.Failure(
                                                                      obj.message
                                                                    );
                                                                  }
                                                                  getMenuData(
                                                                    obj.message
                                                                  );
                                                                  getCart();
                                                                })
                                                            );
                                                          }
                                                        }

                                                        setQuantityAdd(1);
                                                      }}>
                                                      <FaPlus />
                                                    </button>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                ))
                            ) : (
                              <Row>
                                <Col
                                  xl={4}
                                  md={6}
                                  className="item-card-wrapper">
                                  <div className="item-card">
                                    <div className="recipe-img-wrapper recipe-img-wrapper-tab-view">
                                      <Skeleton.Image className="recipe-img order-lg-1 order-2 me-3 Item-image-Skeleton" />
                                    </div>
                                    <Skeleton />
                                  </div>
                                </Col>
                                <Col
                                  xl={4}
                                  md={6}
                                  className="item-card-wrapper">
                                  <div className="item-card">
                                    <div className="recipe-img-wrapper recipe-img-wrapper-tab-view">
                                      <Skeleton.Image className="recipe-img order-lg-1 order-2 me-3 Item-image-Skeleton" />
                                    </div>
                                    <Skeleton />
                                  </div>
                                </Col>
                                <Col
                                  xl={4}
                                  md={6}
                                  className="item-card-wrapper">
                                  <div className="item-card">
                                    <div className="recipe-img-wrapper recipe-img-wrapper-tab-view">
                                      <Skeleton.Image className="recipe-img order-lg-1 order-2 me-3 Item-image-Skeleton" />
                                    </div>
                                    <Skeleton />
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  )}
                </>
              );
            })}
          </Row>

          <CopyConfig
            show={showCopyConfig}
            close={() => setShowCopyConfig(false)}
            getMenuList={getMenuList}
            getCart={getCart}
            confidetails={itemConfigDetails}
          />
        </Container>
      </section>
      <Config />
      <Offcanvas
        show={showDetails}
        onHide={handleCloseDetails}
        placement="bottom"
        className="recipe-details">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <p className="spice-food-name-color fw-bold mb-1">
              {selectedItemName}
            </p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="overflow-lg-hidden">
          <FoodDetails />
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showCartItemsDetails}
        onHide={handleCloseCartItemsDetails}
        placement="bottom"
        className="recipe-details">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{selectedItemName}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CartItems />
        </Offcanvas.Body>
      </Offcanvas>
      <Modal show={showLoginModal} onHide={handleCloseLoginModal}>
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body className="pt-0 login-guest-modal">
          <div className="d-flex justify-content-center align-items-center">
            <img src={clientLogo} className="img-fluid w-50 mb-4" alt="logo" />
          </div>
          <h2>Hello,</h2>
          <h3>Welcome!</h3>
          <div className={showLoginScreen != true ? "d-none" : "d-block"}>
            <Form>
              <FloatingLabel
                controlId="floatingInput"
                label="User Name"
                className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingPassword"
                label="Password"
                className="mb-2">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FloatingLabel>
              <p className="mb-3">
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    setForgotPassword(true);
                    setShowLoginScreen(false);
                    setShowSignupScreen(true);
                  }}
                  className="text-capitalize text-danger">
                  forgot password?
                </a>
              </p>
              <Button
                variant="danger"
                size="lg"
                className="w-100 mb-3 "
                onClick={(e) => {
                  var Order = localStorage.getItem("OrderData");
                  var Orderdetails = JSON.parse(Order);
                  if (
                    customerEmail != "" &&
                    password != "" &&
                    customerEmail != null &&
                    password != null
                  ) {
                    Notiflix.Loading.Dots("....");
                    PostApiCall.postRequest(
                      {
                        email: customerEmail,
                        password: password,
                        action: "login",
                        actiondate: new Date(),
                        orderid:
                          localStorage.getItem("OrderData") == null
                            ? null
                            : Orderdetails.order_id,
                      },
                      "authenticateWebsiteUser"
                    ).then((result) =>
                      result.json().then((obj) => {
                        if (result.status === 200 || result.status === 201) {
                          localStorage.setItem(
                            "LoginDetailsWeb",
                            JSON.stringify(obj.message)
                          );
                          localStorage.setItem("access", obj.token);
                          setShowLoginScreen(false);
                          setLoginDisabled(false);
                          setShowLoginModal(false);
                          localStorage.removeItem("SessionExpired");
                          localStorage.setItem(
                            "OrderData",
                            JSON.stringify(obj.message[0])
                          );
                          getCart();
                          Notiflix.Loading.Remove();
                          Notiflix.Notify.Success("Welcome to the store.");
                        } else {
                          Notiflix.Loading.Remove();
                          Notiflix.Notify.Failure("Authentication Failed!");
                        }
                      })
                    );
                  } else {
                    Notiflix.Notify.Failure(
                      "Please Enter Username and Password!"
                    );
                  }
                }}>
                Login
              </Button>
              <div className="align-items-center">
                <p className="mb-2 text-center">
                  New User?{" "}
                  <a
                    href="javascript:void(0)"
                    className="text-capitalize text-danger"
                    onClick={() => {
                      HideLoginScreen();
                      setGuestLogin(false);
                    }}>
                    sign up
                  </a>
                </p>
              </div>
              <span className="divider">
                <p>Or you can</p>
              </span>
              <Button
                variant="danger"
                size="lg"
                className="w-100 mb-3 "
                onClick={() => {
                  setGuestLogin(true);
                  HideLoginScreen();
                }}>
                Continue as Guest
              </Button>

              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-2">
                  <a href="" className="text-capitalize text-danger">
                    {loginError}
                  </a>
                </p>
              </div>
            </Form>
          </div>
          <div className={showSignupScreen != true ? "d-none" : "d-block"}>
            <Form>
              {forgotPassword === false ? (
                <>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Name"
                    className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Name"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Mobile"
                    className="mb-3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Your Mobile"
                      value={customerContact}
                      onChange={(e) => setCustomerContact(e.target.value)}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email Address"
                    className="mb-3">
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      value={customerEmail}
                      onChange={(e) => setCustomerEmail(e.target.value)}
                    />
                  </FloatingLabel>
                  {guestLogin !== true ? (
                    <FloatingLabel
                      controlId="floatingPassword"
                      label="Enter Password"
                      className="mb-3">
                      <Form.Control
                        type="password"
                        value={password}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FloatingLabel>
                  ) : (
                    ""
                  )}
                  {/* <FloatingLabel
                controlId="floatingPassword"
                label="Confirm Password"
                className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                />
              </FloatingLabel> */}
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      className="form-check-input radioBgcolr me-2"
                      checked={consent}
                      onChange={() => {
                        setConsent(!consent);
                      }}></input>
                    <p>
                      I hereby certify that the above information provided by me
                      is accurate and true.
                    </p>
                  </div>

                  {guestLogin === true ? (
                    <Button
                      variant="primary"
                      className="w-100 btn btn-danger btn-lg mb-3"
                      onClick={() => {
                        var Order = localStorage.getItem("OrderData");
                        var Orderdetails = JSON.parse(Order);
                        if (
                          customerContact != "" &&
                          customerEmail != "" &&
                          customerName != "" &&
                          consent != false
                        ) {
                          PostApiCall.postRequest(
                            {
                              name: customerName,
                              email: customerEmail,
                              mobile: customerContact,
                              cartid:
                                localStorage.getItem("OrderData") == null
                                  ? 0
                                  : Orderdetails.order_id,
                              signupguest: "Continue",
                            },
                            "addcustomer"
                          ).then((result) =>
                            result?.json().then((obj3) => {
                              if (
                                result.status === 200 ||
                                result.status === 201
                              ) {
                                localStorage.setItem(
                                  "LoginDetailsWeb",
                                  JSON.stringify(obj3.message)
                                );
                                setShowLoginScreen(false);
                                setLoginDisabled(false);
                                setShowLoginModal(false);
                                setGuestLogin(false);
                                getCart();
                              } else {
                                Notiflix.Notify.Failure("Error Occurred!");
                              }
                            })
                          );
                        } else {
                          Notiflix.Notify.Failure(
                            "Please enter all mandatory fields!"
                          );
                        }
                      }}>
                      Continue
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className="w-100 btn btn-danger btn-lg mb-3"
                      onClick={() => {
                        var Order = localStorage.getItem("OrderData");
                        var Orderdetails = JSON.parse(Order);
                        if (
                          customerContact != "" &&
                          customerEmail != "" &&
                          customerName != "" &&
                          consent != false &&
                          password != ""
                        ) {
                          // if (password == confirmPassword) {
                          PostApiCall.postRequest(
                            {
                              name: customerName,
                              email: customerEmail,
                              mobile: customerContact,
                              cartid:
                                localStorage.getItem("OrderData") == null
                                  ? 0
                                  : Orderdetails.order_id,
                              consent: consent,
                              password: password,
                            },
                            "addcustomer"
                          ).then((result) =>
                            result?.json().then((obj3) => {
                              if (
                                result.status === 200 ||
                                result.status === 201
                              ) {
                                Cookies.set("salt", obj3.message[0].fld_salt);
                                if (
                                  obj3.message[0]?.existing_customer == "no"
                                ) {
                                  localStorage.setItem(
                                    "LoginDetailsWeb",
                                    JSON.stringify(obj3.message)
                                  );
                                  setShowLoginScreen(false);
                                  setLoginDisabled(false);
                                  setShowLoginModal(false);
                                  getCart();
                                  PostApiCall.postRequest(
                                    {
                                      customerid: obj3.message[0]?.CustomerId
                                        ? obj3.message[0]?.CustomerId
                                        : obj3.message[0]?.fld_customerid,
                                      password: sha512(
                                        obj3.message[0].fld_salt + password
                                      ),
                                    },
                                    "updatecustomermaster"
                                  ).then((result) =>
                                    result?.json().then((obj3) => {
                                      if (
                                        result.status === 200 ||
                                        result.status === 201
                                      ) {
                                        Notiflix.Notify.Success(
                                          "Welcome to the store."
                                        );
                                        setToggleOtp(false);
                                        // setShowLoginModal(false);
                                        setOtpVerified(false);
                                      } else {
                                        Notiflix.Notify.Failure(
                                          "Error Occurred!"
                                        );
                                      }
                                    })
                                  );
                                } else {
                                  Notiflix.Notify.Failure(
                                    "Sorry, a user with the same details already exists. Please log in to access your account."
                                  );
                                }
                              } else {
                                Notiflix.Notify.Failure("Error Occurred!");
                              }
                            })
                          );
                          // } else {
                          //   Notiflix.Notify.Failure(
                          //     "Password and confirm password are not matching."
                          //   );
                          // }
                        } else {
                          Notiflix.Notify.Failure(
                            "Please enter all mandatory fields!"
                          );
                        }
                      }}>
                      Sign Up
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}

              {forgotPassword == true ? (
                <>
                  {toggleOtp == false && (
                    <>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Name"
                        className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Enter Your Name"
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email Address"
                        className="mb-3">
                        <Form.Control
                          type="email"
                          placeholder="name@example.com"
                          value={customerEmail}
                          onChange={(e) => setCustomerEmail(e.target.value)}
                        />
                      </FloatingLabel>
                      <Button
                        variant="primary"
                        className="w-100 btn btn-danger btn-lg mb-3"
                        onClick={() => {
                          if (customerName != "" || customerEmail != "") {
                            // customerValidation == "email" ?
                            PostApiCall.postRequest(
                              {
                                customername: customerName,
                                customeremail: customerEmail,
                              },
                              "ForgotPasswordMailer"
                            ).then((result) =>
                              result?.json().then((obj3) => {
                                if (
                                  result.status === 200 ||
                                  result.status === 201
                                ) {
                                  Notiflix.Notify.Success(
                                    "OTP sent on your email successfully"
                                  );
                                  setToggleOtp(true);
                                  // Cookies.set("salt", obj3.message[0].fld_salt);
                                } else {
                                  Notiflix.Notify.Failure("Error Occurred!");
                                }
                              })
                            );
                          } else {
                            Notiflix.Notify.Failure(
                              "Please enter all mandatory fields!"
                            );
                          }
                        }}>
                        Send OTP on Email
                        {/* {customerValidation == "email"
                      ? "Send OTP on Email"
                      : "Send OTP on Mobile"} */}
                      </Button>
                    </>
                  )}
                  {toggleOtp == true && otpVerified == false && (
                    <>
                      <FloatingLabel
                        controlId="floatingPassword"
                        label="One Time Password"
                        className="mb-3">
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </FloatingLabel>
                      <Button
                        variant="primary"
                        className="w-100 btn btn-danger btn-lg mb-3"
                        onClick={() => {
                          PostApiCall.postRequest(
                            {
                              otp: otp,
                              email: customerEmail,
                            },
                            "Verifyotp"
                          ).then((result) =>
                            result?.json().then((obj3) => {
                              if (
                                result.status === 200 ||
                                result.status === 201
                              ) {
                                PostApiCall.postRequest(
                                  {
                                    name: customerName,
                                    email: customerEmail,
                                  },
                                  "getCustomerData"
                                ).then((result) =>
                                  result?.json().then((obj3) => {
                                    if (
                                      result.status === 200 ||
                                      result.status === 201
                                    ) {
                                      localStorage.setItem(
                                        "CustomerData",
                                        JSON.stringify(obj3.message)
                                      );
                                      localStorage.setItem(
                                        "LoginDetailsWeb",
                                        JSON.stringify(obj3.message)
                                      );
                                      setCustomerData(obj3.message);
                                      Notiflix.Notify.Success(
                                        "OTP validation successful"
                                      );
                                      setOtpVerified(true);
                                    }
                                  })
                                );
                              } else {
                                Notiflix.Notify.Failure(
                                  "OTP validation Failed"
                                );
                              }
                            })
                          );
                        }}>
                        Verify OTP
                      </Button>
                    </>
                  )}
                  {otpVerified == true && (
                    <>
                      <FloatingLabel
                        controlId="floatingPassword"
                        label="Enter Password"
                        className="mb-3">
                        <Form.Control
                          type="password"
                          value={password}
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingPassword"
                        label="Confirm Password "
                        className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Confirm Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                        />
                      </FloatingLabel>
                      <Button
                        variant="primary"
                        className="w-100 btn btn-danger btn-lg mb-3"
                        onClick={() => {
                          var login = localStorage.getItem("LoginDetailsWeb");
                          var logindetails = login
                            ? JSON.parse(login)[0]
                            : null;
                          var Order = localStorage.getItem("OrderData");
                          var Orderdetails = JSON.parse(Order);
                          // if (password != "" && customerContact != "" && customerEmail != "" && customerName != "" && consent != false) {
                          if (password == confirmPassword) {
                            PostApiCall.postRequest(
                              {
                                name: customerName,
                                email: customerEmail,
                                mobile: customerContact,
                                cartid:
                                  localStorage.getItem("OrderData") == null
                                    ? 0
                                    : Orderdetails.order_id,
                                consent: consent,
                                password: password,
                              },
                              "addcustomer"
                            ).then((result) =>
                              result?.json().then((obj3) => {
                                if (
                                  result.status === 200 ||
                                  result.status === 201
                                ) {
                                  Cookies.set("salt", obj3.message[0].fld_salt);
                                  localStorage.setItem(
                                    "LoginDetailsWeb",
                                    JSON.stringify(obj3.message)
                                  );
                                  // setShowLoginScreen(false);
                                  // setLoginDisabled(false);
                                  // setShowLoginModal(false);
                                  getCart();
                                  PostApiCall.postRequest(
                                    {
                                      customerid: obj3.message[0]?.CustomerId
                                        ? obj3.message[0]?.CustomerId
                                        : obj3.message[0]?.fld_customerid,
                                      password: sha512(
                                        obj3.message[0].fld_salt + password
                                      ),
                                    },
                                    "updatecustomermaster"
                                  ).then((result) =>
                                    result?.json().then((obj3) => {
                                      if (
                                        result.status == 200 ||
                                        result.status == 201
                                      ) {
                                        Notiflix.Notify.Success(
                                          "Welcome to the store."
                                        );
                                        setShowLoginScreen(false);
                                        setLoginDisabled(false);
                                        setToggleOtp(false);
                                        setShowLoginModal(false);
                                        setOtpVerified(false);
                                        setForgotPassword(false);
                                      } else {
                                        Notiflix.Notify.Failure(
                                          "Error Occurred!"
                                        );
                                      }
                                    })
                                  );
                                } else {
                                  Notiflix.Notify.Failure("Error Occurred!");
                                }
                              })
                            );
                            // } else {
                            //   Notiflix.Notify.Failure(
                            //     "Password and confirm password are not matching."
                            //   );
                            // }
                          } else {
                            Notiflix.Notify.Failure(
                              "Please enter all mandatory fields!"
                            );
                          }
                          // }
                        }}>
                        Save and Login
                      </Button>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
              <span className="divider">
                <p>Already have an account</p>
              </span>
              <Button
                variant="danger"
                size="lg"
                className="w-100 mb-3 fw-bold"
                onClick={() => {
                  setGuestLogin(false);
                  HideSignupScreen();
                }}>
                Login
              </Button>
              {/* <div className="d-flex justify-content-end align-items-center">
                <p>
                  Already have an account?{" "}
                  <a
                    href="javascript:void(0)"
                    className="text-capitalize text-danger"
                    onClick={HideSignupScreen}>
                    login
                  </a>
                </p>
              </div> */}
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {/* Spice level Modal */}

      <Modal
        size="lg"
        className="spice-level-modalposition2"
        backdrop="static"
        keyboard={false}
        show={showSpiceLevel}
        onHide={handleCloseSpiceLevel}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0 p-0" style={{ background: "white" }}>
          <div className="modal-sp-levl overflow-x-auto">
            {/* {itemSpiceLevel == "Yes" ? ( */}
            <div className="sp-lvl d-flex align-content-center px-lg-2">
              {spicelevelData.map((data, index) => {
                return (
                  <div
                    className="d-flex data-spl-lebel ps-1 align-content-center"
                    style={{ padding: "10px" }}>
                    <input
                      type="radio"
                      className="onlineShowItems form-check-input radioBgcolr radioSize-add-on fs-5"
                      checked={checkedData == data.value ? true : false}
                      value={data.value}
                      onChange={(e) => {
                        setCheckedData(data.value);
                        setSpiceLevel(e.target.value);
                      }}
                    />
                    <img
                      src={data.img}
                      className="spice-level-image-size"></img>
                    <label className="checkbox-spicelevel"> {data.label}</label>
                  </div>
                );
              })}
            </div>
            {/* ) : null} */}

            <Form className="add-instructions px-3">
              <Form.Group className="my-3" controlId="formBasicEmail">
                <label className="spice-food-name-color">Start Typing...</label>
                <input
                  type="text"
                  className="form-control spice-level-instruction-text mt-3 mb-2 px-2"
                  maxLength={100}
                  value={specialInstruction}
                  onChange={(e) => {
                    setSpecialInstruction(e.target.value);
                  }}
                  placeholder="Special Instruction"
                />
                <div className="text-end">
                  <label>
                    {specialInstruction == null ? 0 : specialInstruction.length}
                    /100
                  </label>
                </div>
              </Form.Group>
            </Form>
            {AddOnData.length != 0 ? (
              <>
                <p className="suggestions">Complete your meal with</p>
                <div>
                  {AddOnData.map((data, i) => {
                    return (
                      <div
                        className="d-flex justify-content-between"
                        style={{ padding: "15px" }}>
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            className="onlineShowItems form-check-input radioBgcolr mt-2 radioSize-add-on"
                            onChange={() => {
                              setitemData((curitem) => [
                                ...curitem,
                                data.FLD_Item_ID,
                              ]);
                            }}></input>
                          <div className="Add-On-ItemName">
                            <div className="d-flex">
                              <h6 className="pb-0 mb-0">
                                <span className="mx-1 variantnameorderonlinephnfont">
                                  {data.FLD_Food_Type == "NonVegetarian" ? (
                                    // <img
                                    //   src={nonveg}
                                    //   alt="nonveg"
                                    //   height="20px"
                                    //   width="20px"
                                    // ></img>
                                    <GiPlainCircle className="bg-danger" />
                                  ) : data.FLD_Food_Type == "Vegetarian" ? (
                                    // <img
                                    //   src={veg}
                                    //   alt="veg"
                                    //   height="20px"
                                    //   width="20px"
                                    // ></img>
                                    <GiPlainCircle className="bg-success" />
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <span className="itemNameOrderonline">
                                  {" "}
                                  {data.FLD_Name}
                                </span>
                              </h6>
                            </div>

                            <p
                              className="m-0 p-0 itemdetailcards"
                              style={{ color: "black" }}>
                              {data.FLD_Description}
                            </p>
                          </div>
                        </div>
                        <p className="itemmenucard-rupee m-0">
                          $ {data.FLD_Discount_Price}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              ""
            )}
            <hr />
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px" }}>
              <button
                type="button"
                className="px-3 floating-button d-flex align-items-center btn btn-danger"
                onClick={() => {
                  setselectSpiceModal(false);
                }}>
                CANCEL
              </button>
              <button
                type="button"
                className="px-3 floating-button d-flex align-items-center btn btn-danger px-3"
                onClick={() => {
                  // if (localStorage.getItem("LoginDetailsWeb") == null) {
                  //   // setloginModalItemId(itemId);
                  //   // setLoginModal(true);
                  //   // setshowFixedBottom(false);
                  //   setselectSpiceModal(false);
                  // } else {
                  setLoader(true);
                  var login = localStorage.getItem("LoginDetailsWeb");
                  var logindetails = JSON.parse(login);
                  var Order = localStorage.getItem("OrderData");
                  var Orderdetails = JSON.parse(Order);
                  PostApiCall.postRequest(
                    {
                      orderdate: moment().format("lll"),
                      itemdetails: `
                                                                       [
                                                                       {
                                                                           "Item":${itemId},
                                                                           "Quantity":${1},
                                                                           "SpiceLevel":"",
                                                                           "specialInstruction":${specialInstruction}
                                                                       }
                                                                       ]
                                                                       `,
                      status: "INCART",
                      customerid:
                        localStorage.getItem("LoginDetailsWeb") == null
                          ? null
                          : logindetails[0].CustomerId,
                      createdon: moment().format("lll"),
                      updatedon: moment().format("lll"),
                      orderid:
                        localStorage.getItem("OrderData") == null
                          ? 0
                          : Orderdetails.order_id,
                      updatedby:
                        localStorage.getItem("LoginDetailsWeb") == null
                          ? null
                          : logindetails[0].CustomerId,
                      stateid: null,
                    },
                    "addToCart"
                  ).then((result) =>
                    result.json().then((obj) => {
                      if (result.status == 200 || result.status == 201) {
                        // if (itemData.length != 0) {
                        //   addItemAndAddOnTogether();
                        // }
                        setupdateCart(!updateCart);
                        setLoader(false);
                        if (localStorage.getItem("OrderData") == null) {
                          localStorage.setItem(
                            "OrderData",
                            JSON.stringify(obj.data)
                          );
                        }
                      } else {
                        Notiflix.Notify.failure(obj.data);
                      }
                      // getmenulist(categoryId);
                      getCart();
                      getMenuData(obj.message);
                      setSpecialInstruction(null);
                    })
                  );
                  // }
                }}>
                {loader == true ? (
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ height: "20px", width: "20px" }}>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "ADD"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Drawer
        title="Filters and Sorting"
        open={filterOffcanvas}
        closable={false}
        key='bottom'
        onClose={() => { setFilterOffcanvas(false) }}
        placement="bottom"
        className="recipe-details filters-drawer"
        extra={
          <Space>
            <IoClose className="fs-3" onClick={() => { setFilterOffcanvas(false) }} />
          </Space>
        }>
        <p>
          Veg/Non-veg preference
        </p>
        <div className="d-flex gap-3 mb-4">
          {filterTypes?.map((data) => {
            return (
              <>
                <button
                  key={data.fld_id}
                  type="button"
                  className={`item-category-filter veg ${active == data.fld_filter_type ? 'active' : ''}`}
                  onClick={() => {
                    setActive(data.fld_filter_type);
                    const removeFav = allcuisines;
                    setcuisines(
                      removeFav.filter(
                        (product) => product.FLD_Food_Type == data.fld_filter_type ||
                          product.best_seller == data.fld_filter_type ||
                          product.contains_egg == data.fld_filter_type
                      )
                    );
                    setSearchText("");
                  }}>
                  <img src={data.fld_image} className="recipe-type-image" />
                  {/* <GiPlainCircle /> */}
                  {data.fld_label}
                </button>
              </>
            )
          })}
        </div>
        <p>
          Sort by
        </p>
        <div className="d-flex gap-3">
          <button
            onClick={() => {
              const sortedItems = allcuisines;
              sortedItems.sort(
                (a, b) =>
                  b.FLD_Pricing_new -
                  a.FLD_Pricing_new
              );
              setFilterOffcanvas(false)
            }}
            type="button"
            className="item-category-filter veg">Price, High to Low</button>
          <button
            onClick={() => {
              const sortedItems = allcuisines;
              sortedItems.sort(
                (a, b) =>
                  a.FLD_Pricing_new -
                  b.FLD_Pricing_new
              );
              setFilterOffcanvas(false)
            }}
            type="button"
            className="item-category-filter veg">Price, Low to High</button>
        </div>
      </Drawer>
      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
