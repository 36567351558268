import "./DesignTwoHomeBanner.css";
import DownArrow from "../img/home-banner/icon-down-arrow.svg"
export default function DesignTwoHomeBanner() {
  return (
    <div className="hero parallaxie">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-md-4">
            <div className="section-title">
              <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                Welcome to Delhi Wale Sardar Ji
              </h3>
              <h1 className="text-anime">
                Experience <br /> The Taste of Tradition
              </h1>
            </div>

            <div className="hero-content wow fadeInUp" data-wow-delay="0.75s">
              <p>
              North Indian | Mughlai | Rolls | Biryani | Chinese
              </p>
            </div>

            <div className="hero-footer">
              <a
                href="/order-online"
                className="btn-default btn-book-now wow fadeInUp"
                data-wow-delay="1s">
                Menu
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="down-arrow">
        <a href="#home-about">
          <img src={DownArrow} alt="" />
        </a>
      </div>
    </div>
  );
}
