import "./DesignTwoHome.css";
// import DesignTowContactUs from "./DesignTowContactUs/DesignTowContactUs";
import DesignTwoNavbar from "./NavBar/DesignTwoNavbar";
import Footer from "../../components/Footer/Footer";
// import DesignTowFranchise from "./DesignTowFranchise/DesignTowFranchise";
import { useContext } from "react";
import { store } from "../context/store";
import { Helmet } from "react-helmet";
export default function PrivacyPage() {
  const { keyStrings } = useContext(store);
  return (
<>
<Helmet>
        <title>
        Protecting Your Information: Delhi Wale Sardar Ji Privacy Policy
        </title>
        <meta
          name="description"
          content="Discover how our privacy policy ensures the protection of your personal information. Learn about our commitment to safeguarding your privacy today."
        />
        <meta
          property="og:title"
          content="Protecting Your Information: Delhi Wale Sardar Ji Privacy Policy"
        />
        <meta
          property="og:description"
          content="Discover how our privacy policy ensures the protection of your personal information. Learn about our commitment to safeguarding your privacy today."
        />
        <meta name="keywords" content="Privacy Policy, delivery, takeout, order food online, menu, food delivery, online ordering system, delivery service in Gurugram" />

        <meta
          property="og:url"
          content="https://delhiwalesardarji.com/privacy"
        />
        <link
          rel="canonical"
          href="https://delhiwalesardarji.com/privacy"
        />
        <meta property="og:image" content="https://delhiwalesardarji.com/static/media/page-header-bg.11cf1697dcba0a0e7061.jpg" />
        <meta
          name="twitter:title"
          content="Protecting Your Information: Delhi Wale Sardar Ji Privacy Policy"
        />
        <meta
          name="twitter:description"
          content="Discover how our privacy policy ensures the protection of your personal information. Learn about our commitment to safeguarding your privacy today.
"
        />
        <meta name="twitter:image" content="https://delhiwalesardarji.com/static/media/page-header-bg.11cf1697dcba0a0e7061.jpg" />
      </Helmet>
    <div className="design-two">
      <DesignTwoNavbar />
      <div className="page-header terms-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header-box">
                <h1 className="text-anime">Privacy Policy</h1>
                {/* <nav>
                  <ol className="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Privacy Policy</li>
                  </ol>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="section-title">
                <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                Terms and Conditions
                </h3>
                <h2 className="text-anime">Terms and Conditions</h2>
              </div> */}
              {/* <p
                dangerouslySetInnerHTML={{
                  __html:
                    keyStrings?.filter(
                      (item) => item.FLD_Key_Name == "PRIVACY_POLICY"
                    )[0] != null
                      ? keyStrings?.filter(
                        (item) =>
                          item.FLD_Key_Name == "PRIVACY_POLICY"
                      )[0].FLD_Key_Value
                      : "",
                }}></p> */}
              <b>Welcome to Delhi Wale SardarJi!</b>
              <p>
                We understand that privacy is important to you! This website
                belongs to Delhi Wale SardarJi. This Privacy Policy explains how
                we collect, use, and share information you give us on this site.
                Please read it carefully before sharing any information.
              </p>
              <b>Agreement:</b>
              <p>
                By using this site, you agree to this Privacy Policy. When you
                provide information through this site, you agree to how we
                handle it as explained here.
              </p>
              <b>What Information We Collect:</b>
              <p>
                You can visit our site without telling us who you are. When you
                do visit, our servers log some details, like which pages you
                looked at.
              </p>
              <p>
                We might ask for your name, address, phone number, email, or
                other info when you:
              </p>
              <ul>
                <li>Order online</li>
                <li>Enter a contest</li>
                <li>Take a survey</li>
                <li>Sign up for emails</li>
                <li>Apply for a job</li>
                <li>Ask about franchising</li>
                <li>Give us feedback</li>
              </ul>
              <b>We use your info to:</b>
              <ul>
                <li>Improve our website</li>
                <li>Personalize your experience</li>
                <li>Communicate with you</li>
                <li>Process orders</li>
                <li>Measure website traffic</li>
                <li>Give you prizes or rewards</li>
                <li>Respond to your requests</li>
                <li>Tell you about changes</li>
                <li>Do marketing research</li>
                <li>Send you offers or info we think you'll like</li>
              </ul>
              <b>Credit Card Details:</b>
              <p>We don't keep your credit card info.</p>
              <b>Sharing Information:</b>
              <p>We might share info with:</p>
              <ul>
                <li>Our partners who help us run things</li>
                <li>Other companies related to us</li>
                <li>If you say it's okay</li>
                <li>If we're required by law</li>
                <li>If it's part of a joint promotion</li>
                <li>
                  If we think you'd want to know about something from another
                  company
                </li>
              </ul>
              <b>Access, Accuracy, and Security:</b>
              <p>
                We try to keep your info accurate and safe. You can ask to see
                or fix your info anytime.
              </p>
              <b>Changes to This Policy:</b>
              <p>
                If we change this policy, we'll put the new one on our site.
                Check back now and then, especially before you give us any
                personal info.
              </p>
              {/* <p>
                Duis nec semper ligula. Nullam nec justo vel metus gravida consequat. Suspendisse potenti. Quisque fermentum, nisl vitae auctor commodo, justo metus tincidunt elit,
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
}
