import axios from "axios";
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { Component } from 'react';
// import { store } from "../../context/store"
import { store } from "../../../pages/context/store";
import PostApiCall from "../helper/PostAPI";

class Maps extends Component {
    static contextType = store;
    constructor(props) {
        super(props);
        this.state = {
            markers: [
                {
                    name: "Current position",
                    position: {
                        lat: '47.70923430939719',
                        lng: '-122.32561495683619'
                    }
                }
            ],
            FormattedAddress: null

        }
    }

    componentDidMount() {
        console.log(this)

        const store = this.context;
        this.getLocation()

        //console.log(this.state.markers[0].position.lat)

        // //console.log("Lat" + this.props.lat)
        // //console.log("Lng" + this.props.lang)
    }

    onMarkerDragEnd = (coord, index) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        this.context.setmapPostitionMarkerLat(lat)
        this.context.setmapPostitionMarkerLang(lng)


        this.setState(prevState => {
            const markers = [...this.state.markers];
            markers[index] = { ...markers[index], position: { lat, lng } };
            // //console.log({markers})
            return { markers };

        }, () => {
            this.getFormattedAddress()
            // this.context.setSelectedLocation(true)
        });
    }

    getFormattedAddress = () => {
        var moilat = 47.70923430939719;
        var moilng = -122.32561495683619;

        axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.state.markers[0].position.lat + ',' + this.state.markers[0].position.lng + `&key=${this.context.clientCredentials[0]?.fld_google_code}`)
            .then(res => {
                //console.log("map" + res.data)
                this.setState({
                    FormattedAddress: res.data.results[0].formatted_address,
                })
                this.context.setMapAddress(res.data.results.filter(obj => obj.types.includes("sublocality"))[0]?.formatted_address)
                this.context.setNoDeliveryZone(false)
                this.getDistance()
            }).catch(function (error) {
                //console.log(error);
            });
    }

    getDistance = () => {
        var moilat = "47.70923430939719";
        var moilng = "-122.32561495683619";
        var destinationLat = this.context.mapPostitionMarkerLat;
        var destinationLng = this.context.mapPostitionMarkerLang;


        PostApiCall.postRequest({
            originLat: moilat,
            originLng: moilng,
            destinationLat: destinationLat,
            destinationLng: destinationLng
        }, "GoogleDistanceMatrix").then((results) => {
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.context.setNoDeliveryZone(false)
                } else {
                    this.context.setNoDeliveryZone(true)
                }
            })
        })
    }

    getLocation = () => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {
                const coords = pos.coords;

                let newState = Object.assign({}, this.state);
                newState.markers[0].position.lat = coords.latitude;
                newState.markers[0].position.lng = coords.longitude;

                this.setState(newState);

                // //console.log("map", this.state.markers[0].position.lat, this.state.markers[0].position.lng)
            });
        }
    }





    render() {

        return (

            <div className='address-map-main'>
                <Map
                    google={this.props.google}
                    style={{
                        width: "100%",
                        height: "100%",

                    }}

                    zoom={15}
                    center={{ lat: this.context.mapPostitionMarkerLat, lng: this.context.mapPostitionMarkerLang }}
                    // initialCenter={{ lat: this.context.mapPostitionMarkerLat, lng: this.context.mapPostitionMarkerLang }}
                    formatted_address={this.state.FormattedAddress}
                >
                    {this.state.markers.map((marker, index) => (
                        <Marker
                            key={index}
                            position={{
                                lat: this.context.mapPostitionMarkerLat,
                                lng: this.context.mapPostitionMarkerLang,
                            }}
                            draggable={true}
                            onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, index)}
                            name={marker.name}
                        />
                    ))}
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBrfL1ePMke4epnPmndnupg5NEPpxhdzoA'
})(Maps);