import DesignTwoHomeBanner from "./DesignTwoHomeBanner/DesignTwoHomeBanner";
import DesignTwoNavbar from "./NavBar/DesignTwoNavbar.js";
import "./DesignTwoHome.css";
import "animate.css";
import DesignTwoOurStory from "./DesignTwoOurStory/DesignTwoOurStory";
import DesignTwoCatering from "./DesignTwoCatering/DesignTwoCatering.js";
import DesignTwoPopularDishes from "./DesignTwoPopularDishes/DesignTwoPopularDishes";
import DesignTwoOffers from "./DesignTwoOffers/DesignTwoOffers";
import DesignTwoTestimonial from "./DesignTwoTestimonial/DesignTwoTestimonial";
import GallaryDesignTwo from "./DesignTwoImageGallery/GallaryDesignTwo.js";
import DesignTwoDeliveryPartners from "./DesignTwoDeliveryPartners/DesignTwoDeliveryPartners.js";
import Footer from "../../components/Footer/Footer.js";
import { Helmet } from "react-helmet";

export default function DesignTwoHome() {
  return (
    <div className="design-two">
      <Helmet>
        <title>
        Welcome to Delhi Wale Sardar Ji: A Culinary Journey Through Punjabi Flavors
        </title>
        <meta
          name="description"
          content="Savor the flavors of Punjab with Delhi Wale Sardarji. From buttery parathas to rich gravies, experience the best of Indian cuisine with every bite."
        />
        <meta
          property="og:title"
          content="Welcome to Delhi Wale Sardar Ji: A Culinary Journey Through Punjabi Flavors"
        />
        <meta
          property="og:description"
          content="Savor the flavors of Punjab with Delhi Wale Sardarji. From buttery parathas to rich gravies, experience the best of Indian cuisine with every bite."
        />
        <meta property="og:url" content="https://delhiwalesardarji.com/" />
        <link rel="canonical" href="https://delhiwalesardarji.com/" />
        <meta property="og:image" content="https://delhiwalesardarji.com/static/media/delhi-wale-sardar-ji-logo.359e9418960628f05999.png" />
        <meta
          name="twitter:title"
          content="Welcome to Delhi Wale Sardar Ji: A Culinary Journey Through Punjabi Flavors"
        />
        <meta
          name="twitter:description"
          content="Savor the flavors of Punjab with Delhi Wale Sardarji. From buttery parathas to rich gravies, experience the best of Indian cuisine with every bite.
"
        />
          <meta name="twitter:image" content="https://delhiwalesardarji.com/static/media/delhi-wale-sardar-ji-logo.359e9418960628f05999.png" />
      </Helmet>
      <DesignTwoNavbar />
      <DesignTwoHomeBanner />
      <DesignTwoOurStory />
      <DesignTwoCatering />
      <DesignTwoPopularDishes />
      <GallaryDesignTwo />
      <DesignTwoTestimonial />
      <DesignTwoOffers />
      {/* <DesignTwoDeliveryPartners /> */}
      <Footer />
    </div>
  );
}
