import "animate.css";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PostApiCall from "../../../components/OnlineOrdering/helper/PostAPI";
import { store } from "../../context/store";
import "./GallaryDesignTwo.css";

const GallaryDesignTwo = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
  };
  const [gallaryImages, setGallaryImages] = useState([]);
  const [mainImg, setMainImg] = useState([]);
  const { clientDetails } = useContext(store);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        id: "",
      },
      "getClientData"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          PostApiCall.postRequest(
            {
              whereClause: `where fld_restaurant_id = ${obj1.message[0]?.fld_client_id}`,
              columns: "*",
            },
            "getRestaurantImages"
          ).then((results) =>
            results?.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                const shuffledImages = obj.message.sort(
                  () => Math.random() - 0.5
                );
                const randomMainImage =
                  shuffledImages[
                  Math.floor(Math.random() * shuffledImages.length)
                  ];
                setMainImg([randomMainImage]); // single image
                const randomImages = shuffledImages;
                setGallaryImages(randomImages);
                Notiflix.Loading.Remove();
              }
            })
          );
          Notiflix.Loading.Remove();
        }
      })
    );
  }, []);

  // const imageStyles = [
  //   { border: "5px solid red", borderRadius: "5px" },
  //   { border: "1px solid blue", borderRadius: "10px" },
  //   { border: "1px solid green", borderRadius: "15px" },
  //   { border: "1px solid yellow", borderRadius: "20px" },
  //   { border: "1px solid orange", borderRadius: "25px" },
  //   { border: "1px solid purple", borderRadius: "30px" },
  //   { border: "1px solid pink", borderRadius: "35px" },
  // ];
  const imageStyles = [
    "mb-4",
    "mb-4",
    "mb-4",
    "mb-4 mb-lg-0",
    "mb-4 mb-lg-0",
    "mb-4 mb-lg-0",
  ];

  return (
    <>
      <section className="my-5 image-gallery">
        <Container fluid>
          <Row className="justify-content-center">
            <div className="section-title mb-1">
              <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                Delicious Moments Captured Here
              </h3>
              <h2 className="text-anime">Flavors in Frames</h2>
            </div>
            {mainImg.map((data, i) => (
              <Col key={i} className="col-lg-5">
                <img
                  src={data.fld_image_url}
                  alt={clientDetails[0]?.fld_restaurantname}
                  className="img-fluid gallery-lrft-img gallery-img-border-radius"
                />
              </Col>
            ))}
            <div className="col-lg-7">
              <div className="row h-100">
                {gallaryImages
                  .filter((gallary) => {
                    if (gallary.fld_type == 'gallery images') {
                      return gallary
                    }
                  }).slice(0, 6).map((data, i) => (
                    <Col
                      key={i}
                      className={`col-6 col-lg-4 ${imageStyles[i % imageStyles.length]
                        }`}>
                      {/* // <Col key={i} className="col-6 col-lg-4 mb-4"> */}
                      <img
                        src={data.fld_image_url}
                        alt={clientDetails[0]?.fld_restaurantname}
                        className="img-fluid gallery-img-border-radius"
                      />
                    </Col>
                  ))}
              </div>
            </div>
            <div className="col-12 text-center mt-4">
              <a href="/gallery" className="btn-default wow fadeInUp" data-wow-delay="1.50s">View All</a>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default GallaryDesignTwo;
