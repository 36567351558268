import "./DesignTwoHome.css";
import DesignTowContactUs from "./DesignTowContactUs/DesignTowContactUs";
import DesignTwoNavbar from "./NavBar/DesignTwoNavbar";
import Footer from "../../components/Footer/Footer";
import DesignTowFranchise from "./DesignTowFranchise/DesignTowFranchise";
import { useContext } from "react";
import { store } from "../context/store";
import { Helmet } from "react-helmet";
export default function DisclaimerPage() {
  const { keyStrings } = useContext(store);
  return (
    <>
<Helmet>
        <title>
        Delhi Wale Sardar Ji: Disclaimer and Legal Information
        </title>
        <meta
          name="description"
          content="Disclaimer, delivery, takeout, order food online, menu, food delivery, online ordering system, delivery service in Gurugram"
        />
        <meta
          property="og:title"
          content="Delhi Wale Sardar Ji: Disclaimer and Legal Information"
        />
        <meta
          property="og:description"
          content="Disclaimer, delivery, takeout, order food online, menu, food delivery, online ordering system, delivery service in Gurugram"
        />
        <meta name="keywords" content="Privacy Policy, delivery, takeout, order food online, menu, food delivery, online ordering system, delivery service in Gurugram" />

        <meta
          property="og:url"
          content="https://delhiwalesardarji.com/disclaimer"
        />
        <link
          rel="canonical"
          href="https://delhiwalesardarji.com/disclaimer"
        />
        <meta property="og:image" content="https://delhiwalesardarji.com/static/media/page-header-bg.11cf1697dcba0a0e7061.jpg" />
        <meta
          name="twitter:title"
          content="Delhi Wale Sardar Ji: Disclaimer and Legal Information"
        />
        <meta
          name="twitter:description"
          content="Disclaimer, delivery, takeout, order food online, menu, food delivery, online ordering system, delivery service in Gurugram
"
        />
        <meta name="twitter:image" content="https://delhiwalesardarji.com/static/media/page-header-bg.11cf1697dcba0a0e7061.jpg" />
      </Helmet>
    <div className="design-two">
      <DesignTwoNavbar />
      <div className="page-header terms-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header-box">
                <h1 className="text-anime">Disclaimer</h1>
                {/* <nav>
                  <ol className="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Disclaimer</li>
                  </ol>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="section-title">
                <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                Terms and Conditions
                </h3>
                <h2 className="text-anime">Terms and Conditions</h2>
              </div> */}
              {/* <p
                dangerouslySetInnerHTML={{
                  __html:
                    keyStrings?.filter(
                      (item) => item.FLD_Key_Name == "HOME_PAGE_CATEGORY_TEXT"
                    )[0] != null
                      ? keyStrings?.filter(
                        (item) =>
                          item.FLD_Key_Name == "HOME_PAGE_CATEGORY_TEXT"
                      )[0].FLD_Key_Value
                      : "",
                }}></p> */}
              {/* <p>
              Duis nec semper ligula. Nullam nec justo vel metus gravida consequat. Suspendisse potenti. Quisque fermentum, nisl vitae auctor commodo, justo metus tincidunt elit,
              </p> */}
              <ul className="terms-content">
                <li>
                  <span>1.</span>
                  The food items displayed on the Delhi Wale SardarJi Restaurant
                  website are for illustrative purposes only. While we strive to
                  accurately represent our menu items and their descriptions,
                  actual dishes may vary in appearance and presentation.
                </li>
                <li>
                  <span>2.</span>
                  Allergen information provided on the website is based on
                  standard recipes and ingredient lists. However, please be
                  aware that our kitchen handles a wide variety of ingredients,
                  and cross-contamination may occur. We cannot guarantee that
                  any menu item will be completely free of allergens.
                </li>
                <li>
                  <span>3.</span>
                  Prices listed on the website are subject to change without
                  notice. While we make every effort to ensure that pricing
                  information is accurate, discrepancies may occasionally occur.
                  Please confirm prices with the restaurant before placing your
                  order.
                </li>
                <li>
                  <span>4.</span>
                  Promotional offers and discounts advertised on the website may
                  be subject to specific terms and conditions. Please review any
                  applicable terms before redeeming any offers.
                </li>
                <li>
                  <span>5.</span>
                  The information provided on our website, including but not
                  limited to menu items, prices, and promotions, is subject to
                  change at any time without prior notice. We recommend
                  contacting the restaurant directly for the most up-to-date
                  information.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
}
