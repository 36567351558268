import "./DesignTwoPopularDishes.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import PostApiCall from "../../../components/OnlineOrdering/helper/PostAPI";
import { Link } from "react-router-dom";
export default function DesignTwoPopularDishes() {
  const [foodMenuOrder, setFoodMenuOrder] = useState([]);

  useEffect(() => {
    PostApiCall.postRequest(
      {
        categoryid: null,
        orderid: 0,
      },
      "getFoodMenuOrder"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          const shuffledImages = obj1.message.sort(() => Math.random() - 0.5);
          const randomImages = shuffledImages.slice(0, 6);
          setFoodMenuOrder(randomImages);
        }
      })
    );
  }, [])
  var settings = {
    arrow: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        infinite: true,
      },
    ],
  };
  return (
    <div className="most-popular-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h3 className="wow fadeInUp" data-wow-delay="0.50s">
                Our Best Selling Dishes
              </h3>
              <h2 className="text-anime">Hot Dishes to Ignite Your Palate</h2>
            </div>
          </div>
        </div>
        <div className="row menu-item-boxes">
          <Slider {...settings}>
            {foodMenuOrder?.map((data) => {
              return (
                <Link to="/order-online" className="h-100">
                  <div className="px-3 h-100">
                    <div className="menu-item">
                      <div className="menu-item-img">
                        <figure className="image-anime">
                          <img src={data.FLD_Image} alt="" />
                        </figure>
                      </div>

                      <div className="menu-item-body">
                        <h3>{data.FLD_Name}</h3>

                      </div>
                    </div>
                  </div>
                </Link>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
