// var urlString = "http://localhost:8033/api-grub/";
var urlString = "https://api.grubdigest.com/api-grub/";
const GetApiCall = {
  getRequest(url) {
    return fetch(urlString + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        schema: "delhi_wale_sardar_ji",
        'x-auth-token': localStorage.getItem('access')
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("request failed", error);
        return error;
      });
  },
};

export default GetApiCall;
