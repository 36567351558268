import "./DesignTwoHome.css";
import DesignTowContactUs from "./DesignTowContactUs/DesignTowContactUs";
import DesignTwoNavbar from "./NavBar/DesignTwoNavbar";
import Footer from "../../components/Footer/Footer";
import DesignTowFranchise from "./DesignTowFranchise/DesignTowFranchise";
export default function DesignTowFranchisePage() {
  return (
    <div className="design-two">
      <DesignTwoNavbar />
      <DesignTowFranchise />
      <Footer />
    </div>
  );
}
