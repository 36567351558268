import "./DesignTwoHome.css";
import DesignTowContactUs from "./DesignTowContactUs/DesignTowContactUs";
import DesignTwoNavbar from "./NavBar/DesignTwoNavbar";
import Footer from "../../components/Footer/Footer";
import DesignTowFranchise from "./DesignTowFranchise/DesignTowFranchise";
import DesignTowCateringInner from "./DesignTowCateringInner/DesignTowCateringInner";
import ImageGalleryPage from "./ImageGalleryPage/ImageGalleryPage";
import { Helmet } from "react-helmet";
export default function DesignTowGalleryPage() {
  return (
    <div className="design-two">
       <Helmet>
        <title>Flavorful Frames: Where Taste Meets Memories</title>
        <meta
          name="description"
          content="Experience the magic of Flavorful Frames, where taste meets memories. Browse our website to find the perfect frames to add a touch of flavor to your life ."
        />
        <meta
          property="og:title"
          content="Flavorful Frames: Where Taste Meets Memories"
        />
        <meta
          property="og:description"
          content="Experience the magic of Flavorful Frames, where taste meets memories. Browse our website to find the perfect frames to add a touch of flavor to your life ."
        />
        <meta property="og:url" content="https://delhiwalesardarji.com/gallery" />
        <link rel="canonical" href="https://delhiwalesardarji.com/gallery" />
        <meta
          property="og:image"
          content=""
        />
        <meta
          name="twitter:title"
          content="Flavorful Frames: Where Taste Meets Memories"
        />
        <meta
          name="twitter:description"
          content="Experience the magic of Flavorful Frames, where taste meets memories. Browse our website to find the perfect frames to add a touch of flavor to your life .
"
        />
        <meta
          name="twitter:image"
          content=""
        />
      </Helmet>
      <DesignTwoNavbar />
      <ImageGalleryPage />
      <Footer />
    </div>
  );
}
